"use client";

import { useContext, useEffect, useState } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import Image from "next/image";
import { useModal } from "hooks/useModal";
import { ImageToUrl } from "services/imageToUrl";
import { Modal } from "./Utils";
import { ClipboardButton } from "../utils/ClipboardButton";
import { ElementsProps } from "./types";
import styles from "./PromoRibbon.module.scss";
import { PageCheck } from "services/pageCheck";
import { PathContext } from "state_management/Contexts";

export const Container = ({ page, data }: ElementsProps) => {
  const {
    code,
    description,
    modalHeader,
    disclaimer,
    icon,
    useModalCode,
    descriptionBackground,
    descriptionTextColor,
  } = data;

  const [textColor, setTextColor] = useState<string | null>(null);
  const [copyColor, setCopyColor] = useState<string | null>(null);

  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const TagManagerProps = {
    tagPage: tagPage,
    taggedComponent: "PromoRibbon",
    taggedElement: "PromoRibbon main container",
  };

  // Modal basic functions
  const { modalIsOpen, wrapperIsOpen, openModal, closeModal } = useModal();

  const iconUrl = icon && ImageToUrl(icon);

  // Props
  const modalProps = {
    closeModal,
    wrapperIsOpen,
    disclaimer,
    modalIsOpen,
    code,
    page,
    modalHeader,
    useModalCode,
    tagPage,
  };

  const { red, green, blue } = descriptionBackground || {};

  // Setting container background and text color
  const containerBackround = descriptionBackground
    ? `rgb(${red},${green},${blue})`
    : `rgb(242,242,242)`;

  useEffect(() => {
    if (descriptionTextColor !== null) {
      if (descriptionTextColor.selectionValues[0].key === "White") {
        setTextColor("rgb(255,255,255");
        setCopyColor(`/services/printing/static-pages/icons/copy-white.svg`);
      } else if (
        descriptionTextColor.selectionValues[0].key === "Default (Dark Gray)" ||
        descriptionTextColor === null
      ) {
        setTextColor("rgb(68,68,68");
        setCopyColor(`/services/printing/static-pages/icons/copy.svg`);
      }
    }
  }, [descriptionTextColor]);

  return (
    <>
      {textColor && copyColor && (
        <article
          className={styles.container}
          data-testid="ribbon-container"
          style={{ backgroundColor: containerBackround }}
        >
          <div className={styles.content}>
            <div className={styles.containerIcon}>
              <Image src={iconUrl ?? ""} alt="" width={20} height={20} />
            </div>
            <div className={styles.description} style={{ color: textColor }}>
              {description && (
                <BrRichTextContent
                  page={page}
                  content={{ html: description.value }}
                />
              )}

              {code && (
                <div className={styles.clipboardButtonWrapper}>
                  <ClipboardButton code={code} {...TagManagerProps}>
                    <span
                      className={styles.descriptionButtonText}
                      style={{ color: textColor }}
                    >
                      {code}
                    </span>
                    <div className={styles.copyIcon}>
                      <Image src={copyColor} alt="" width={10} height={10} />
                    </div>
                  </ClipboardButton>
                </div>
              )}
            </div>
          </div>
          {disclaimer && (
            <button
              className={styles.detailsButton}
              onClick={openModal}
              style={{ color: textColor }}
            >
              <p>Details</p>
            </button>
          )}
          <Modal {...modalProps} />
        </article>
      )}
    </>
  );
};
