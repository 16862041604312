import { CarouselContainer } from "../utils/NewCarouselContaner";
import { Tile } from "./Utils";
import { CarouselProps } from "./types";

export const Carousel = ({
  data,
  page,
  activeTab,
  parentTitle,
  tabSwitch,
  clsBtnStyle,
  contentTransition,
  setClsBtnStyle,
  setContentTransition,
  setTabSwitch,
}: CarouselProps) => {
  const list = data[activeTab].tilesArray;

  const tileProps = {
    page,
    parentTitle,
    listLength: list.length,
    activeTab,
    tabSwitch,
    clsBtnStyle,
    setClsBtnStyle,
    contentTransition,
    setContentTransition,
    setTabSwitch,
  };

  return (
    <CarouselContainer list={list}>
      <Tile data={null} {...tileProps} />
    </CarouselContainer>
  );
};
