"use client";

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  createRef,
  RefObject,
  UIEvent,
} from "react";
import { useDraggable } from "hooks/useDraggable";
import { useResize } from "hooks/useResize";
import animateScrollTo from "animated-scroll-to";
import styles from "./CarouselContainer.module.scss";
import clsx from "clsx";

type ScrollTilesFunction = (direction: string, e: React.MouseEvent) => void;

interface ControlsProps {
  paginationCount: number;
  scrollTiles: ScrollTilesFunction;
  scrollPosition: number;
  containerRef: RefObject<HTMLUListElement>;
}
export const Controls = ({
  paginationCount,
  scrollTiles,
  scrollPosition,
  containerRef,
}: ControlsProps) => {
  return (
    <>
      {paginationCount > 1 && (
        <ul className={styles.controls}>
          <li className={styles.controlsButton}>
            <button
              aria-hidden={true}
              onClick={(e) => scrollTiles("backward", e)}
              className={styles.controlsButtonBck}
              disabled={scrollPosition === 0}
            ></button>
          </li>
          <li className={styles.controlsButton}>
            <button
              aria-hidden={true}
              onClick={(e) => scrollTiles("forward", e)}
              className={styles.controlsButtonFwd}
              disabled={
                scrollPosition >=
                (containerRef.current?.scrollWidth ?? 0) -
                  (containerRef.current?.clientWidth ?? 0) -
                  5
              }
            ></button>
          </li>
        </ul>
      )}
    </>
  );
};

export interface ScrollContainerProps {
  list: any[];
  children: React.ReactNode;
  simpleScroll?: boolean;
}

export const CarouselContainer = ({
  list,
  children,
  simpleScroll,
}: ScrollContainerProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [paginationCount, setPaginationCount] = useState(0);

  const [tileRefs, setTileRefs] = useState(() =>
    list.map(() => createRef<HTMLLIElement>())
  );

  const containerRef = useRef<HTMLUListElement>(null);
  const listWrapperRef = useRef<HTMLDivElement>(null);

  const size = useResize();
  const dragHandler = useDraggable(containerRef);
  const dragMobile = size < 821 ? dragHandler : undefined;

  // Help functions to calculate correct scroll area sizes
  // Checks if the first item in the tileRefs array has a current property that is defined
  const calculateTileWidth = useCallback(() => {
    if (
      !containerRef.current ||
      !list ||
      list.length === 0 ||
      !tileRefs[0]?.current
    ) {
      return 0;
    }
    return tileRefs[0].current.offsetWidth;
  }, [list, tileRefs]);

  const calculateVisibleAreaWidth = useCallback(() => {
    return containerRef.current ? containerRef.current.clientWidth : 0;
  }, []);

  // Setting position to scroll to
  const scrollTiles = useCallback(
    (direction: string, e: React.MouseEvent) => {
      e.preventDefault();
      if (!containerRef.current) return;

      const tileWidth = calculateTileWidth();

      // alternate logic for advancing carousel by 1 tile -- start
      if (simpleScroll) {
        const gap = 12;
        const totalTileWidth = tileWidth + gap;
        const currentScrollPosition = containerRef.current.scrollLeft;
        const tilesScrolled = currentScrollPosition / totalTileWidth;
        const tilesScrolledIsInteger = Number.isInteger(tilesScrolled);
        let nextTile;

        if (direction === "forward") {
          nextTile = tilesScrolledIsInteger
            ? tilesScrolled + 1
            : Math.ceil(tilesScrolled);
        } else {
          nextTile = tilesScrolledIsInteger
            ? tilesScrolled - 1
            : Math.floor(tilesScrolled);
        }

        const nextTileXposition = nextTile * totalTileWidth;

        animateScrollTo([nextTileXposition, 0], {
          elementToScroll: containerRef.current,
        });

        return;
      }
      // end

      const visibleAreaWidth = calculateVisibleAreaWidth();
      let newScrollPosition =
        scrollPosition + (direction === "forward" ? tileWidth : -tileWidth);

      const maxScrollPosition =
        containerRef.current.scrollWidth - visibleAreaWidth;
      const minScrollPosition = 0;

      // Calculate the remaining scroll distance after the proposed scroll operation
      const remainingScrollForward = maxScrollPosition - newScrollPosition;
      const remainingScrollBackward = newScrollPosition - minScrollPosition;

      // Determine if the last page or first page is smaller than 5% of the visible area width
      const isLastPageSmall =
        direction === "forward" &&
        remainingScrollForward <= visibleAreaWidth * 0.05;
      const isFirstPageSmall =
        direction === "backward" &&
        remainingScrollBackward <= visibleAreaWidth * 0.05 &&
        newScrollPosition >= 0;

      if (isLastPageSmall) {
        // If moving forward and the last page is small, adjust to scroll it together with the previous page
        newScrollPosition += remainingScrollForward;
      } else if (isFirstPageSmall) {
        // If moving backward and the first page is small, adjust to include this small remaining portion
        newScrollPosition -= remainingScrollBackward;
      }

      // Ensure the new scroll position is within bounds
      newScrollPosition = Math.max(
        minScrollPosition,
        Math.min(newScrollPosition, maxScrollPosition)
      );

      animateScrollTo([newScrollPosition, 0], {
        elementToScroll: containerRef.current,
      });
      setScrollPosition(newScrollPosition);
    },
    [
      scrollPosition,
      calculateTileWidth,
      calculateVisibleAreaWidth,
      simpleScroll,
    ]
  );

  const handleScroll = useCallback((e: UIEvent<HTMLUListElement>) => {
    setScrollPosition(e.currentTarget.scrollLeft);
  }, []);

  const controlsProps = useMemo(
    () => ({
      paginationCount,
      scrollTiles,
      scrollPosition,
      containerRef,
    }),
    [paginationCount, scrollTiles, scrollPosition, containerRef]
  );

  // Initializes refs for each item in the list
  useEffect(() => {
    setTileRefs(list.map(() => createRef<HTMLLIElement>()));
  }, [list]);

  // Set Pagination
  useEffect(() => {
    if (!containerRef.current || !list) return;

    containerRef.current.scrollLeft = 0;
    setScrollPosition(0);

    const totalTileWidth = tileRefs.reduce(
      (acc, ref) => acc + (ref.current ? ref.current.offsetWidth : 0),
      0
    );
    const visibleAreaWidth = containerRef.current.clientWidth;
    const viewableTileCount = Math.floor(
      visibleAreaWidth / (totalTileWidth / list.length)
    );

    setPaginationCount(Math.ceil(list.length / viewableTileCount));
  }, [list, size, tileRefs]);

  return (
    <>
      <div
        className={styles.componentScrollAreaWrapper}
        ref={listWrapperRef}
        aria-labelledby="list-description"
      >
        <ul
          className={clsx(styles.componentScrollArea, {
            [styles.componentScrollAreaNoscroll]: paginationCount <= 1,
          })}
          ref={containerRef}
          onMouseDown={dragMobile}
          onScroll={handleScroll}
          data-testid="tiles-list"
        >
          {list?.map((tile, index) => (
            <li ref={tileRefs[index]} key={index}>
              {React.Children.map(children, (child) =>
                React.isValidElement(child)
                  ? React.cloneElement(child as React.ReactElement, {
                      data: tile,
                    })
                  : child
              )}
            </li>
          ))}
        </ul>
      </div>

      <Controls {...controlsProps} />
    </>
  );
};
