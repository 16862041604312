import { useContext, useMemo, useState } from "react";
import { useModal } from "hooks/useModal";
import { linkAttributes } from "services/linkAttributes";
import { Carousel } from "./Carousel";
import { Modal } from "./Utils";
import { ContainerProps, ModalDataProps } from "./types";
import styles from "./RelatedProducts.module.scss";
import { MainCtaTagManager } from "./RelatedProductsTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";

export const Container = ({ data, page }: ContainerProps) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [itemData, setItemData] = useState<ModalDataProps | null>(null);

  const { title, description, sectionCta, tilesArray } = data;
  const { modalIsOpen, wrapperIsOpen, openModal, closeModal } = useModal();

  const linkProps = useMemo(
    () => sectionCta && linkAttributes(sectionCta?.address, sectionCta.newTab),
    [sectionCta]
  );

  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  const tagManagerHandler = (label: string) => {
    MainCtaTagManager(label, tagPage, title ?? "");
  };

  const modalProps = {
    data: itemData,
    page,
    modalIsOpen,
    wrapperIsOpen,
    closeModal,
    currentItem,
    setCurrentItem,
  };

  const carouselProps = {
    data: tilesArray,
    page,
    openModal,
    setItemData,
    setCurrentItem,
  };

  return (
    <article className={styles.container}>
      {title && (
        <header className={styles.containerHeader}>
          {title && <h2>{title}</h2>}
          {description && <p>{description}</p>}

          {sectionCta?.title && (
            <a
              {...linkProps}
              onClick={() => tagManagerHandler(sectionCta?.title)}
            >
              {sectionCta?.title}
            </a>
          )}
        </header>
      )}
      <section>
        <Carousel {...carouselProps} />
      </section>
      {itemData && <Modal {...modalProps} />}
    </article>
  );
};
