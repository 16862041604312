import { useEffect, useRef, useState } from "react";
import { useResize } from "hooks/useResize";
import { useLockBodyScroll } from "hooks/useLockBodyScroll";
import Image from "next/image";
import styles from "./ModalContainer.module.scss";
import clsx from "clsx";

interface ModalContainerProps {
  wrapperIsOpen: boolean;
  modalIsOpen: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

export const ModalContainer = ({
  wrapperIsOpen,
  closeModal,
  modalIsOpen,
  children,
  wrapperRef,
}: ModalContainerProps) => {
  const [wrapperStyle, setWrapperStyle] = useState<React.CSSProperties>({});
  const [isScrolling, setIsScrolling] = useState(false);
  const [buttonTopPosition, setButtonTopPosition] = useState(0);
  const [hideScrollbar, setHideScrollbar] = useState(false);

  const dialogRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDialogElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const size = useResize();

  // Blocks body scroll when modal open
  useLockBodyScroll(modalIsOpen);

  const fixedButtonStyle =
    isScrolling && size < 821 ? { top: `${buttonTopPosition}px` } : {};

  const CloseButton = () => (
    <button
      onClick={closeModal}
      className={clsx(styles.closeButton, {
        [styles.closeButtonFixed]: isScrolling && size < 821,
        [styles.closeButtonFixedDisabled]: !wrapperIsOpen,
      })}
      data-testid="dialog-close-button"
      ref={closeButtonRef}
      style={fixedButtonStyle}
    >
      <Image
        src="/services/printing/static-pages/icons/x.svg"
        alt=""
        width={14}
        height={14}
      />
    </button>
  );

  // Sets modal dimensions
  useEffect(() => {
    if (wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.clientWidth;
      const wrapperHeight = wrapperRef.current.clientHeight;

      if (size < 821) {
        if (wrapperHeight < window.innerHeight * 0.8) {
          setWrapperStyle({
            height: `${wrapperHeight}px`,
          });
        } else {
          setWrapperStyle({
            height: "80vh",
          });
        }
      } else {
        setWrapperStyle({
          width: `${wrapperWidth}px`,
          height: `${wrapperHeight}px`,
        });
      }
    }
  }, [children, size, wrapperRef]);

  // Detecting scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (dialogRef.current && closeButtonRef.current) {
        const isAboveThreshold = dialogRef.current.scrollTop > 0;
        setIsScrolling(isAboveThreshold);
      }
    };

    const dialogElement = dialogRef.current;
    if (dialogElement) {
      dialogElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dialogElement) {
        dialogElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Setting close button to fixed position when scrolling
  useEffect(() => {
    const modalElement = dialogRef.current;
    const handleCloseButtonPosition = () => {
      if (!closeButtonRef.current) return null;
      const buttonPosition = closeButtonRef.current.getBoundingClientRect().top;
      setButtonTopPosition(buttonPosition);
    };

    if (modalIsOpen && modalElement && closeButtonRef.current) {
      modalElement.addEventListener("transitionend", handleCloseButtonPosition);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener(
          "transitionend",
          handleCloseButtonPosition
        );
      }
    };
  }, [modalIsOpen, closeButtonRef]);

  // Hide scrollbar if scroll height and dialog height difference is less then 5 px
  useEffect(() => {
    const dialogElement = scrollRef.current;
    if (dialogElement) {
      const dialogHeight = dialogElement.clientHeight ?? 0;
      const scrollHeight = dialogElement.scrollHeight;

      setHideScrollbar(scrollHeight - dialogHeight <= 5);
    }
  }, [children, modalIsOpen]);

  return (
    <>
      <div
        className={clsx(styles.backdrop, {
          [styles.backdropOpen]: wrapperIsOpen,
        })}
        data-testid="backdrop"
      />

      <div
        className={clsx(styles.wrapper, {
          [styles.wrapperOpen]: wrapperIsOpen,
          [styles.hideScrollbar]: hideScrollbar,
        })}
        data-testid="dialog-wrapper"
        style={wrapperStyle}
        ref={dialogRef}
      >
        <CloseButton />
        <dialog
          ref={scrollRef}
          open={modalIsOpen}
          className={clsx(styles.dialog, {
            [styles.dialogOpen]: modalIsOpen,
            [styles.dialogHideScrollbar]: hideScrollbar,
          })}
        >
          {children}
        </dialog>
      </div>
    </>
  );
};
