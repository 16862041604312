import { ReactElement } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, Document } from "@bloomreach/spa-sdk";
import { ComponentDocument, ComponentParameters } from "./types";
import { ElementsContainer } from "./ElementsContainer";

export function ProductInspiration({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const { content: documentRef } = component.getModels<ComponentParameters>();

  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? (
      <div>Select PM - Product Inspiration Document</div>
    ) : null;
  }

  const containerData = document?.getData<ComponentDocument>();

  return <ElementsContainer page={page} data={containerData} />;
}
