import React, { useEffect, useState } from "react";

export const useDevice = () => {
  const [device, setDevice] = useState("");
  useEffect(() => {
    if (
      !navigator.userAgent.match(
        /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
      )
    ) {
      setDevice("desktop");
    }
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i) !== null) {
      setDevice("iOS");
    }
    if (
      navigator.userAgent.match(/(webOS|BlackBerry|IEMobile|Opera Mini)/i) !==
      null
    ) {
      setDevice("mobilehybrid");
    }
    if (navigator.userAgent.match(/Android/i) !== null) {
      setDevice("android");
    }
  }, []);

  return device;
};
