import styles from "./PrimaryDropdown.module.scss";
import { useContext, useRef, MouseEvent, useEffect } from "react";
import clsx from "clsx";
import Image from "next/image";
import { BrPageContext } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import { imagePlaceholder } from "@/constants/index";
import { Chevron } from "../../utils";
import { PTLItemProps } from "../types";
import { linkAttributes } from "services/linkAttributes";
import { tagManager } from "../../tagManager";

export const PrimaryTopLevelItem = ({
  itemData: {
    boldTitle,
    caption,
    categoryIcon,
    categoryLink,
    id,
    displayName,
    newTab,
  },
  parentItem,
  setItemId,
  setLevel,
  itemId,
  isDesktop,
  isActive,
}: PTLItemProps) => {
  const page = useContext(BrPageContext);
  const linkRef = useRef(null);
  const iconUrl =
    categoryIcon &&
    page?.getContent<ImageSet>(categoryIcon)?.getOriginal()?.getUrl();

  // Activating content level
  const levelSetup = (e: MouseEvent<HTMLDivElement>) => {
    if (e?.target !== linkRef.current && setLevel) setLevel("content");
    setItemId && setItemId(id);
  };

  // Deactivating content level on the dropdown close
  useEffect(() => {
    if (!isActive && setLevel && setItemId) {
      const timeoutId = setTimeout(() => {
        setLevel("top");
        setItemId("");
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [isActive, setLevel, setItemId]);

  let hoverTimeout: any = null;

  // Staying on the same item when mouse moved
  const handleMouseEnter = () => {
    if (isDesktop) {
      hoverTimeout = setTimeout(levelSetup, 180);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
  };

  const titleProps = linkAttributes(categoryLink, newTab);
  return (
    <div
      className={clsx(styles.item, { [styles.itemActive]: itemId === id })}
      onClick={levelSetup}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label="Select Product"
    >
      <span className={styles.itemAssets}>
        <span className={styles.itemIcon}>
          <Image
            src={iconUrl || imagePlaceholder}
            alt=""
            className={styles.imagePic}
            sizes="(min-width: 375px) 100vw"
            fill
          />
        </span>

        <span className={styles.itemText}>
          {categoryLink && isDesktop ? (
            <a
              {...titleProps}
              className={clsx(styles.title, { [styles.titleBold]: boldTitle })}
              onClick={() => tagManager(`${parentItem} >  ${displayName}`)}
            >
              {displayName}
            </a>
          ) : (
            <span
              className={clsx(styles.title, { [styles.titleBold]: boldTitle })}
            >
              {displayName}
            </span>
          )}
          {caption && <span className={styles.itemTextCaption}>{caption}</span>}
        </span>
      </span>
      <span className={styles.chevron}>
        <Chevron angle={-90} size={16} />
      </span>
    </div>
  );
};
