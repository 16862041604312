import styles from "./Divider.module.scss";

interface DividerProps {
  marginVar: number;
  color?: string;
}
export const Divider = ({ marginVar, color }: DividerProps) => {
  const dividerStyle = {
    margin: `${marginVar}px 0`,
    backgroundColor: color ? color : undefined,
  };
  return <div className={styles.divider} style={dividerStyle} />;
};
