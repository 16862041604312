import { Tile } from "./Tile";
import styles from "./IndustryTiles.module.scss";
import { v4 as uuidv4 } from "uuid";
import { IndustryTilesFCProps } from "./types";

export const Container = ({ title, tiles }: IndustryTilesFCProps) => {
  return (
    <div className={styles.layout} id="industry-layout">
      <ul className={styles.container}>
        {title && (
          <h2 className={styles.containerTitle} data-testid="container-title">
            {title}
          </h2>
        )}
        {tiles?.map((tile: any) => {
          return (
            <li key={uuidv4()} className={styles.wrapper}>
              <Tile data={tile} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
