"use client";

import { useRef } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { ModalContainer } from "../utils/ModalContainer";
import { ModalProps } from "./types";
import { ClipboardButton } from "../utils/ClipboardButton";
import Image from "next/image";
import styles from "./PromoRibbon.module.scss";

export const Modal = ({
  closeModal,
  wrapperIsOpen,
  modalIsOpen,
  code,
  modalHeader,
  page,
  useModalCode,
  disclaimer,
  tagPage,
}: ModalProps) => {
  const document = page.getContent(disclaimer ?? "");
  const wrapperRef = useRef<HTMLDivElement>(null);

  if (!document) return null;
  const disclaimerData = document.getData();

  const disclaimerText = disclaimerData.disclaimer.value;

  const containerProps = {
    closeModal,
    wrapperIsOpen,
    modalIsOpen,
    code,
    wrapperRef,
  };
  const TagManagerProps = {
    tagPage: tagPage,
    taggedComponent: "PromoRibbon",
    taggedElement: "PromoRibbon modal",
  };
  return (
    <ModalContainer {...containerProps}>
      <article className={styles.modal} ref={wrapperRef}>
        {modalHeader && (
          <section className={styles.modalHeader} data-testid="modal-header">
            {modalHeader && (
              <BrRichTextContent
                page={page!}
                content={{ html: modalHeader.value }}
              />
            )}

            {useModalCode && (
              <div className={styles.clipboardButtonWrapper}>
                <ClipboardButton
                  style={{ fontSize: "18px" }}
                  code={code}
                  {...TagManagerProps}
                >
                  <span> {code}</span>
                </ClipboardButton>
                {code && (
                  <div className={styles.modalCopy}>
                    <Image
                      src="/services/printing/static-pages/icons/copy-mirrored.svg"
                      alt=""
                      width={18}
                      height={18}
                    />
                  </div>
                )}
              </div>
            )}
          </section>
        )}
        <section className={styles.modalDisclaimer}>
          {disclaimer && (
            <BrRichTextContent
              page={page!}
              content={{ html: disclaimerText }}
            />
          )}
        </section>
      </article>
    </ModalContainer>
  );
};
