import styles from "./MarketingLandingBanner.module.scss";
import { useState, useEffect, useRef, useContext } from "react";
import Image from "next/image";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { BrRichTextContent } from "../BrRichTextContent";
import { HeroTagManager } from "../MarketingHeroBanner/HeroBannerTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { Document } from "@bloomreach/spa-sdk";
import { BrComponentContext } from "@bloomreach/react-sdk";
import { imagePlaceholder } from "../../constants";
import { MarketingLandingBannerProps } from "./types";

// Use if bredcrumbs are needed
// import { Index as Breadcrumbs } from "../Breadcrumbs";

interface DescriptionParams {
  contentType: string;
  value: string;
}

export const MarketingLandingBanner = ({
  title,
  buttons,
  image,
  page,
  breadcrumbs,
  manualBc,
}: MarketingLandingBannerProps) => {
  const [headlineText, setHeadlineText] = useState<string | null>(null);
  const [shortDescription, setShortDescription] =
    useState<DescriptionParams | null>(null);
  const [longDescription, setlongDescription] =
    useState<DescriptionParams | null>(null);
  const [disclaimerContent, setDisclaimerContent] =
    useState<DescriptionParams | null>(null);
  const [hasShortDescription, setHasShortDescription] = useState(false);
  const [hasMoreButton, setMoreButton] = useState(false);
  const [hasAppearClass, setAppearClass] = useState(false);

  // Use if bredcrumbs are needed
  // const [bcActive, setBcActive] = useState(false);

  const component = useContext(BrComponentContext);

  const { promoContent, disclaimer } = component?.getModels<any>();
  const document = promoContent && page.getContent<Document>(promoContent);
  const disclaimerDoc = disclaimer && page.getContent<Document>(disclaimer);

  useEffect(() => {
    if (document !== null) {
      const { headline, showMore, description } = document?.getData();
      if (showMore && description) {
        headline && setHeadlineText(headline);
        setShortDescription(showMore);
        setlongDescription(description);
      } else {
        setHeadlineText(
          "Wrong file format. Please choose a file with the type of PM - Promo Content (pmDocDisclaimer)"
        );
        setShortDescription({ contentType: "", value: "" });
        setlongDescription({ contentType: "", value: "" });
      }
    } else {
      setShortDescription(null);
      setlongDescription(null);
    }
    if (disclaimerDoc !== null) {
      const { disclaimer } = disclaimerDoc?.getData();
      disclaimer
        ? setDisclaimerContent(disclaimer)
        : setDisclaimerContent({
            contentType: "",
            value:
              "Wrong file format. Please choose a file with the type of PM - Disclaimer Document",
          });
    } else {
      setDisclaimerContent(null);
    }
  }, [document, disclaimerDoc]);

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  // End GA tagging

  // Use if breadcrumbs are needed
  // useEffect(() => {
  //   breadcrumbs ? setBcActive(true) : setBcActive(false);
  // }, [breadcrumbs]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shortDescription !== null && shortDescription?.value !== "") {
      setHasShortDescription(true);
      setMoreButton(true);
    } else {
      setHasShortDescription(false);
      setMoreButton(false);
    }
  }, [shortDescription]);

  useEffect(() => {
    const containerContent = ref.current as HTMLElement;

    containerContent
      .querySelectorAll('a[href^="#disclaimers"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", (e) => {
          e.preventDefault();

          const container = document.getElementById("disclaimers")
            ?.offsetTop as number;

          setTimeout(() => {
            window.scrollTo({
              top: container - 200,
              left: 0,
              behavior: "smooth",
            });
          }, 100);
        });
      });
  }, [document]);

  const handleShowMoreButton = () => {
    setHasShortDescription(!hasShortDescription);
    setAppearClass(true);

    if (window.scrollY > 200) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.container}>
      <picture className={styles.containerImage}>
        <Image
          src={image ? image : imagePlaceholder}
          style={{ objectFit: "cover", objectPosition: "right" }}
          priority
          fill
          alt={title ?? ""}
          sizes="(max-width: 1440px) 100vw, 1440px"
        ></Image>
      </picture>

      {/* Use if bredcrumbs are needed */}
      {/* <div
        className={styles.contentBreadcrumb}
        data-testid="Mkt-hero-banner-breadcrumbs"
      >
        {bcActive && <Breadcrumbs banner={true} manualBreadcrumbs={manualBc} />}
      </div> */}

      <div className={styles.content}>
        <h1 className={styles.contentTitle}>{title}</h1>
        <div
          ref={ref}
          className={clsx(styles.contentText, {
            [styles.contentShortDescriptionAppear]: hasAppearClass,
          })}
        >
          {hasShortDescription && shortDescription !== null && (
            <div className={(styles.shortDescription, styles.formating)}>
              <div className={styles.headline}>
                <p>{headlineText}</p>
              </div>
              <BrRichTextContent
                page={page!}
                key={uuidv4()}
                content={{ html: shortDescription?.value }}
              />
            </div>
          )}

          {longDescription !== null && !hasShortDescription && (
            <div className={styles.longDescription}>
              <div className={styles.headline}>
                <p>{headlineText}</p>
              </div>
              <BrRichTextContent
                page={page!}
                content={{ html: longDescription.value }}
              />
              <div className={styles.contentCta}>
                {buttons?.map((simpleCta) => {
                  const { ctaText, ctaLink } = simpleCta;

                  return (
                    <a
                      key={uuidv4()}
                      href={ctaLink}
                      className={styles.contentButton}
                      onClick={() => HeroTagManager(ctaText, tagPage)}
                    >
                      {ctaText}
                    </a>
                  );
                })}
              </div>
              {disclaimerContent !== null && (
                <div className={styles.disclaimer}>
                  <BrRichTextContent
                    page={page!}
                    content={{ html: disclaimerContent.value }}
                  />
                </div>
              )}
            </div>
          )}

          {hasMoreButton && (
            <button
              className={styles.showMoreButton}
              onClick={handleShowMoreButton}
            >
              {hasShortDescription ? "..more" : "less"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
