import styles from "./Announcements.module.scss";
import { ListItemProps, SwitchProps, ExpandableProps } from "./types";
import Image from "next/image";
import clsx from "clsx";
import { tagManager } from "./tagManager";
import sanitizeHtml from "sanitize-html";
import { WrapperProps } from "@/types/utils";

export function Aside({ children, ariaLabel }: WrapperProps) {
  return (
    <aside aria-label={ariaLabel} className={styles.container}>
      {children}
    </aside>
  );
}

export function Switch({ isActive, setIsActive, count }: SwitchProps) {
  return (
    <button className={styles.switch} onClick={() => setIsActive(!isActive)}>
      {isActive ? "Show less" : `Show all (${count})`}
    </button>
  );
}

export function List({ children }: WrapperProps) {
  return (
    <div role="list" className={styles.list}>
      {children}
    </div>
  );
}

export function ListItem({ section }: ListItemProps) {
  const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    href: section.link,
    className: styles.itemCta,
  };

  if (section.newTab) {
    linkProps.target = "_blank";
    linkProps.rel = "noopener noreferrer";
  }

  let gaText = sanitizeHtml(section.content.value, {
    allowedTags: [],
    allowedAttributes: {},
  });

  gaText = `${gaText}-${section.linkTitle}`;

  return (
    <div role="listitem" className={styles.item}>
      {section.icon && (
        <div className={styles.itemIconWrapper}>
          <Image
            src={section.icon}
            alt=""
            className={styles.itemIcon}
            width={25}
            height={18}
          />
        </div>
      )}
      <div className={styles.itemCopy}>
        <div dangerouslySetInnerHTML={{ __html: section.content.value }} />
        {section.link && (
          <a
            {...linkProps}
            onClick={() => tagManager(gaText, section.location)}
          >
            {section.linkTitle}
          </a>
        )}
      </div>
    </div>
  );
}

export function Expandable({
  children,
  isExpanded,
  flexGrow,
}: ExpandableProps) {
  return (
    <div
      className={clsx(styles.expandable, styles[`expandableGrow${flexGrow}`], {
        [styles.expandableOpen]: isExpanded,
      })}
      data-testid="expandable-container"
    >
      <div className={styles.expandableContent}>{children}</div>
    </div>
  );
}
