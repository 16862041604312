import { useState, useCallback, useEffect } from "react";

export const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [wrapperIsOpen, setWrapperIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
    setWrapperIsOpen(true);
    document.body.classList.add("modalOpen");
  }, []);

  const closeModal = useCallback(() => {
    setWrapperIsOpen(false);
    setTimeout(() => {
      setModalIsOpen(false);
      document.body.classList.remove("modalOpen");
    }, 300);
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("modalOpen");
    };
  }, []);

  return { modalIsOpen, wrapperIsOpen, openModal, closeModal };
};
