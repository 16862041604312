import { useFocusableElements } from "./useFocusableElements";

export const useFocusTrap = (externalRef: React.RefObject<HTMLElement>) => {
  const { firsFocusabletEl, lastFocusabletEl } =
    useFocusableElements(externalRef);

  function handleFocus(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === "Tab") {
      if (!event.shiftKey && event.target === lastFocusabletEl) {
        event.preventDefault();
        firsFocusabletEl?.focus();
      }

      if (event.shiftKey && event.target === firsFocusabletEl) {
        event.preventDefault();
        lastFocusabletEl?.focus();
      }
    }
  }

  return handleFocus;
};
