import { useRef, useContext, useState, useEffect } from "react";
import { useResize } from "hooks/useResize";
import { ImageSet } from "@bloomreach/spa-sdk";
import { BrRichTextContent } from "../BrRichTextContent";
import { CtaTile } from "../utils/CtaTile";
import styles from "./VideoSpotlight.module.scss";
import ctaStyles from "../utils/CtaTile/CtaTile.module.scss";
import { Section } from "../utils/Section";
import { Headline } from "../utils/Headline";
import { VideoSpotlightTagManager } from "./VideoSpotlightTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { MediaSection } from "./MediaSection";
import { VideoSpotlightFcProps } from "./types";

export const VideoSpotlight = ({ data, page }: VideoSpotlightFcProps) => {
  const [wideScreen, setWideScreen] = useState(false);

  // Destructured data
  const { headline, caption, videoLink, image, wideImage, tiles } = data || {};

  const imageSrc =
    image && page.getContent<ImageSet>(image)?.getOriginal()?.getUrl();
  const wideImageSrc =
    wideImage && page.getContent<ImageSet>(wideImage)?.getOriginal()?.getUrl();

  // Hooks
  const size = useResize();

  // Refs
  const tilesRef = useRef<HTMLDivElement | null>(null);

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  useEffect(() => {
    setWideScreen(size >= 1440);
  }, [size]);

  return (
    <Section>
      <section className={styles.headingWrapper}>
        <div className={styles.heading}>
          <Headline level="h2" style={["h2"]}>
            {headline ? headline : "Component's headline"}
          </Headline>
          {caption?.value ? (
            <BrRichTextContent
              page={page!}
              content={{ html: caption?.value }}
              className={styles.headingTextCapture}
              data-testid="BrRichTextContent"
            />
          ) : (
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          )}
        </div>
      </section>
      <section className={styles.tilesWrapper} ref={tilesRef}>
        <ul className={styles.tilesList} aria-label={headline}>
          {!wideScreen && (
            <li aria-label="Video">
              <MediaSection
                videoLink={videoLink}
                image={imageSrc ?? ""}
                wideImage={wideImageSrc ?? ""}
                tilesRef={tilesRef}
              />
            </li>
          )}
          {tiles?.map((tile, index) => {
            const props = {
              ...tile,
              tileImage:
                page
                  .getContent<ImageSet>(tile.tileImage)
                  ?.getOriginal()
                  ?.getUrl() ?? "",
            };
            return (
              <li key={index}>
                <CtaTile
                  data={props}
                  className={ctaStyles.videoCta}
                  handleClick={() =>
                    VideoSpotlightTagManager(
                      props.tileTitle,
                      props.ctaButton?.ctaText,
                      tagPage
                    )
                  }
                />
              </li>
            );
          })}
          {wideScreen && (
            <li>
              <MediaSection
                videoLink={videoLink}
                image={imageSrc ?? ""}
                wideImage={wideImageSrc ?? ""}
                tilesRef={tilesRef}
              />
            </li>
          )}
        </ul>
      </section>
    </Section>
  );
};
