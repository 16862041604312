import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement, useEffect, useState, useRef } from "react";
import { ImageSet } from "@bloomreach/spa-sdk";
import Image from "next/legacy/image";
import clsx from "clsx";
import styles from "./AuthIframe.module.scss";
import { AuthIframeParams } from "./types";
import { handleMessage, getRandomImage } from "./AuthIframeUtils";
import { LoadingSpinner } from "../utils/LoadingSpinner";

export function AuthIframe({ component, page }: BrProps): ReactElement | null {
  const parameters = component
    ? component.getParameters<AuthIframeParams>()
    : undefined;
  const dataObj: AuthIframeParams = parameters ?? {};

  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState("");

  // Listens for iframe message
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const returnUrl =
      urlParams.get("returnUrl") ??
      urlParams.get("ReturnUrl") ??
      "/services/printing/";

    const wrappedHandleMessage = (event: MessageEvent) => {
      handleMessage(returnUrl, event);
    };

    window.addEventListener("message", wrappedHandleMessage);

    return () => {
      window.removeEventListener("message", wrappedHandleMessage);
    };
  }, []);

  // Set iframe src
  useEffect(() => {
    const iframeSrcMap = {
      "Sign In": "/idm/com/ssologin?fromWhere=PNI&noHF=true&flow=new",
      Register: "/idm/com/v2/ssocreateaccount?fromWhere=PNI&noHF=true&flow=new",
    };

    let iframeSrc = iframeSrcMap["Sign In"];

    if (dataObj.iframe) {
      iframeSrc = iframeSrcMap[dataObj.iframe] || iframeSrcMap["Sign In"];
    }

    setIframeSrc(iframeSrc);
  }, [dataObj.iframe]);

  // Selects a random image to set as page background
  const dataValues = Object.values(dataObj);
  const imageUrls = dataValues.map(
    (value) =>
      value && page?.getContent<ImageSet>(value)?.getOriginal()?.getUrl()
  );
  const imgRef = useRef(getRandomImage(imageUrls.filter(Boolean)));

  if (!component || !page) {
    return null;
  }

  return (
    <div className={styles.bgWrapper}>
      <Image
        alt=""
        src={imgRef.current}
        layout="fill"
        objectFit="cover"
        style={{ opacity: 0.5 }}
      />
      <div className={styles.frame}>
        {loading && (
          <div className={styles.frameLoading}>
            <LoadingSpinner />
          </div>
        )}
        <iframe
          src={iframeSrc}
          className={clsx(styles.frameSrc, {
            [styles.frameSrcLoaded]: !loading,
          })}
          onLoad={() => setLoading(false)}
        />
      </div>
    </div>
  );
}
