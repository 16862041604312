import Script from "next/script";
import { ScriptProps } from "./types";

export const AccordionScript = ({ data }: ScriptProps) => {
  return (
    <Script
      id="faq-schema"
      type="application/ld+json"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};
