import React, { useEffect, useState, useContext } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import Image from "next/legacy/image";
import { v4 as uuidv4 } from "uuid";
import styles from "./MarketingFeaturesTile.module.scss";
import clsx from "clsx";
import { FeaturesTileTagManager } from "./FeaturesTileTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { imagePlaceholder } from "../../constants";
import { MarketingFeaturesTileFCProps } from "./types";

export const MarketingFeaturesTile = ({
  title,
  content,
  image,
  ctaButtons,
  page,
  imagePosition,
}: MarketingFeaturesTileFCProps) => {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  // End GA tagging

  const [position, setPosition] = useState("Right");

  useEffect(() => {
    if (imagePosition) {
      setPosition(imagePosition);
    }
  }, [imagePosition]);

  return (
    <article
      className={clsx(styles.container, {
        [styles.containerRight]: position === "Right",
        [styles.containerLeft]: position === "Left",
      })}
      data-testid="mft-container"
    >
      <section
        className={clsx(styles.content, {
          [styles.contentRight]: position === "Right",
          [styles.contentLeft]: position === "Left",
        })}
      >
        <div className={styles.contentText} data-testid="Mft-title">
          <div className={styles.contentTitle}>
            <p>{title ? title : "Marketing Feature Tile"}</p>
          </div>
          <div className={styles.contentCaption}>
            {content && (
              <BrRichTextContent
                page={page!}
                content={{ html: content?.value }}
                data-testid="BrRichTextContent"
              />
            )}
          </div>
        </div>
        <div className={styles.contentButtons}>
          {ctaButtons?.map((simpleCta) => {
            const { ctaText, ctaLink } = simpleCta;
            return (
              <a
                key={uuidv4()}
                href={ctaLink}
                className={styles.button}
                onClick={() => FeaturesTileTagManager(ctaText, title, tagPage)}
              >
                {ctaText ? ctaText : "CTA"}
              </a>
            );
          })}
        </div>
      </section>
      <figure className={styles.imgContainer}>
        <Image
          src={image ? image : imagePlaceholder}
          alt={title}
          width={720}
          height={460}
          layout="responsive"
          objectFit="cover"
          className={styles.image}
        />
      </figure>
    </article>
  );
};
