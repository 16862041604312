export const ImageGalleryTagManager = (
  label: string,
  page: string,
  categoryTitle?: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: label,
    element_location: categoryTitle
      ? categoryTitle
      : "Scrollable Tiles Component without title",
    cms_component: "Images Gallery Component",
  });
};
