import Image from "next/legacy/image";
import styles from "./Footer.module.scss";
import { MenuSection } from "./MenuSection";
import { v4 as uuidv4 } from "uuid";
import { mediaData } from "./MediaData";
import { MenuItem } from "@bloomreach/spa-sdk";
import { rikSelect } from "services/rikSelect";
import { useContext } from "react";
import { RikContext } from "state_management/Contexts";
import clsx from "clsx";
import { FooterTagManager } from "./FooterTagManager";
import { FooterProps } from "./types";

// Functional Component
export const Footer = ({ data }: FooterProps) => {
  const year = new Date().getFullYear();
  const rik = useContext(RikContext);
  const itemList = rikSelect(data, rik);

  const CreditLinks = () => {
    return (
      <>
        <p className={`${styles.creditsPolicy}`}>
          <a
            href="https://www.staples.com/hc#10004_14"
            data-pniautomationid="FooterDelivery"
          >
            See our delivery policy for full details
          </a>
        </p>
        <p className={`${styles.creditsPolicy} ${styles.creditsBorder}`}>
          <a
            href="https://www.staples.com/hc?id=52e40651-0852-4ad7-a532-45017c287d50"
            data-pniautomationid="FooterYear"
          >
            © 1998-{year}
          </a>
        </p>
      </>
    );
  };
  return (
    <article className={styles.container}>
      <section className={styles.content}>
        <div className={styles.logo}>
          <figure className={styles.logoImage} data-testid="logo">
            <Image
              src="/services/printing/static-pages/images/logo-staples-footer-grey.svg"
              alt="Staples Logo"
              layout="fill"
              objectFit="cover"
            />
          </figure>
        </div>
        <div className={styles.menu}>
          {itemList?.map((item: MenuItem) => {
            return <MenuSection key={uuidv4()} data={item} />;
          })}
        </div>
        <div className={styles.social}>
          <div className={styles.socialTitle}>
            <h6>Join Us On</h6>
          </div>
          <div className={styles.socialIcons}>
            {mediaData.map((icon) => {
              const { link, className, component, id } = icon;
              return (
                <a
                  href={link}
                  className={clsx("scTrack scNavLink", styles[className])}
                  target="_blank"
                  rel="noreferrer"
                  key={id}
                  data-sctype="scNavLink"
                  data-scsticky="false"
                  data-scnavtype="footer"
                  data-scvalue={component}
                  data-scparentvalue="JoinUsOn"
                  data-pniautomationid={"Footer" + component}
                  onClick={() => FooterTagManager("Join Us On", component)}
                >
                  <Image
                    src={`/services/printing/static-pages/icons/social/${component}.svg`}
                    alt={component}
                    layout="fill"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </section>
      <hr className={styles.divider} />
      <section className={styles.credits}>
        <div>
          <p>
            This website is intended for use by US residents only. Staples,
            Inc., All Rights Reserved.
          </p>
        </div>
        <div>
          <CreditLinks />
        </div>
        <div className={styles.creditsPrivacy}>
          <div
            id="teconsent"
            className={styles.creditsCookies}
            title="Cookie Preferences"
            data-pniautomationid="FooterCookiePref"
          ></div>
          <div
            id="irmlink"
            className={styles.creditsCookies}
            title="Do Not Sell My Personal Information"
            data-pniautomationid="FooterPersonalInfo"
          ></div>
          <div
            id="cali-link"
            title="California Notice"
            data-pniautomationid="FooterCaliforniaNotice"
          >
            <a href="https://www.staples.com/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188">
              California Notice
            </a>
          </div>
        </div>
      </section>
    </article>
  );
};
