import { Container } from "./Container";
import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { LongProductTileProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }

  const { sectionTitle, tiles } =
    getContainerItemContent<LongProductTileProps>(component, page) ?? {};

  const tilesArray: any = [];

  tiles?.forEach((tile) => {
    const { title, link, image } = tile;
    const imageUrl =
      image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();

    tilesArray.push({
      title: title,
      link: link,
      image: imageUrl,
    });
  });

  return <Container tiles={tilesArray} sectionTitle={sectionTitle} />;
};
