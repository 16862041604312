import styles from "./PrintingSpecialsUtils.module.scss";
import { getClassNames } from "services/getClassNames";
import { ToggleViewProps, PromoProps, CtaProps } from "./types";
import { PrintingSpecialsTagManager } from "./PrintingSpecialsTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { useContext } from "react";
import { linkAttributes } from "services/linkAttributes";
import clsx from "clsx";
import { WrapperProps } from "@/types/utils";

export function HeadlineWrapper({ children }: WrapperProps) {
  return <div className={styles.headlineWrapper}>{children}</div>;
}

export function ToggleView({ isActive, setIsActive }: ToggleViewProps) {
  return (
    <button
      className={styles.viewToggle}
      onClick={() => setIsActive(!isActive)}
    >
      {isActive ? "View less" : "View more"}
    </button>
  );
}

export function List({ children, role, style }: WrapperProps) {
  const classNames = style ? getClassNames(style, styles) : undefined;

  return (
    <div role={role ?? undefined} className={classNames}>
      {children}
    </div>
  );
}

export function Promo({ ...props }: PromoProps) {
  const delimiter = /\r\n|\r|\n/;
  const supportingTextWithDelimiter = props.supportingText.split(delimiter);

  return (
    <div className={styles.promo}>
      <p
        className={clsx(styles.promoDescription, {
          [styles.promoLoyaltyColor]: props.loyaltyColorDescription,
        })}
      >
        {props.description}
      </p>
      {props.offer && (
        <p
          className={clsx(styles.promoOffer, {
            [styles.promoLoyaltyColor]: props.loyaltyColorOffer,
          })}
          data-testid="promo-offer"
        >
          {props.offer}
        </p>
      )}
      {props.supportingText && (
        <div data-testid="promo-supporting-text">
          {supportingTextWithDelimiter.map((line, index) => (
            <p
              className={clsx(styles.promoSupportingText, {
                [styles.promoLoyaltyColor]: props.loyaltyColorSupportingText,
              })}
              key={index}
            >
              {line}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export function Cta({ ...props }: CtaProps) {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const attributes = linkAttributes(props.link, props.newTab);

  return (
    <a
      className={clsx(styles.cta, {
        [styles.ctaPrimary]: !props.loyaltyColorCta,
        [styles.ctaLoyalty]: props.loyaltyColorCta,
      })}
      onClick={() =>
        PrintingSpecialsTagManager(
          props.gaTitle ?? "",
          props.gaHeadline ?? "",
          tagPage
        )
      }
      {...attributes}
    >
      {props.label}
    </a>
  );
}
