import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem } from "@bloomreach/spa-sdk";
import { ReactElement, useEffect, useState } from "react";
import styles from "./Padding.module.scss";
import { useResize } from "hooks/useResize";

export function Padding({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  const [value, setValue] = useState(0);
  const size = useResize();

  const { mobile, tablet, smallDesktop, desktop } =
    component?.getParameters<ParamsProps>() ?? {};

  useEffect(() => {
    if (size < 601) {
      setValue(mobile ?? 0);
    } else if (size < 821) {
      setValue(tablet ?? 0);
    } else if (size < 1025) {
      setValue(smallDesktop ?? 0);
    } else {
      setValue(desktop ?? 0);
    }
  }, [size, mobile, tablet, smallDesktop, desktop]);

  if (!component || !page) {
    return null;
  }

  const style = {
    paddingTop: `${value}px`,
  };

  interface ParamsProps {
    mobile: number;
    tablet: number;
    smallDesktop: number;
    desktop: number;
  }

  return <div style={style} className={styles.container} />;
}
