export function handleMessage(returnUrl: string, event: MessageEvent) {
  if (event.origin !== process.env.NEXT_PUBLIC_SPLUS_BASE_URL) return;

  let iframeData = null;

  try {
    iframeData = JSON.parse(event.data);
  } catch {
    console.log("handle Message error:", event.data);
  }

  if (iframeData && (iframeData.login || iframeData.register)) {
    const ssoLandingParam = `/services/printing/legacy/ValidateRewardInfo?ReturnUrl=${returnUrl}`;
    const escapedReturnUrl = encodeURIComponent(ssoLandingParam);
    const fullPath = `/services/printing/account/ssoLanding?returnUrl=${escapedReturnUrl}`;
    window.location.href = fullPath;
  }
}

export function getRandomImage(imageOptions: string[]): string {
  const randomIndex = Math.floor(Math.random() * imageOptions.length);
  return imageOptions[randomIndex];
}
