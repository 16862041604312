import { UtilityMenuDropdown } from "../UtilityMenuDropdown";
import { UtilityMenuCartBtn } from "../UtilityMenuCartBtn";
import { useClickOutside } from "hooks/useClickOutside";
import { useDialogObserver } from "hooks/useDialogObserver";
import { useResize } from "hooks/useResize";
import { useState, useCallback, useEffect } from "react";
import styles from "./UtilityMenu.module.scss";
import { MENU_DATA } from "../utilityMenuHelpers/links";
import clsx from "clsx";
import { getIsSignedInFromCookie } from "../utilityMenuHelpers/cookies";
import { Menu } from "../utilityMenuHelpers/types";
import { utilityMenuTagManager } from "../utilityMenuHelpers/tagManager";

export function UtilityMenu() {
  const helpRef = useDialogObserver(onHelpOpen, onHelpClose);
  const accountRef = useDialogObserver(onAccountOpen, onAccountClose);
  const windowSize = useResize();

  const [accountOpen, setAccountOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const handleOutsideSignInClick = useCallback(() => {
    setAccountOpen(false);
    accountRef.current?.close();
  }, [accountRef]);

  const handleOutsideHelpClick = useCallback(() => {
    setHelpOpen(false);
    helpRef.current?.close();
  }, [helpRef]);

  const accountContainerRef = useClickOutside<HTMLLIElement>(
    handleOutsideSignInClick
  );
  const helpContainerRef = useClickOutside<HTMLLIElement>(
    handleOutsideHelpClick
  );

  useEffect(() => {
    const signedIn = getIsSignedInFromCookie();
    if (signedIn) setIsSignedIn(true);
  }, []);

  useEffect(() => {
    const desktopBreakPoint = 821;
    if (windowSize < desktopBreakPoint) {
      accountRef.current?.close();
      helpRef.current?.close();
    }
  }, [windowSize, accountRef, helpRef]);

  function onAccountOpen() {
    setAccountOpen(true);
  }

  function onAccountClose() {
    setAccountOpen(false);
  }

  function onHelpOpen() {
    setHelpOpen(true);
  }

  function onHelpClose() {
    setHelpOpen(false);
  }

  function toggleDialog(dropdown: Menu) {
    if (dropdown === "account") {
      accountRef.current?.open
        ? accountRef.current?.close()
        : accountRef.current?.show();
    } else {
      helpRef.current?.open
        ? helpRef.current?.close()
        : helpRef.current?.show();
    }
  }

  const OrdersIcon = MENU_DATA.orders.icon;
  const SignInIcon = MENU_DATA.signIn.icon;
  const SignInActiveIcon = MENU_DATA.signIn.iconActive;
  const HelpIcon = MENU_DATA.helpCenter.icon;
  const HelpActiveIcon = MENU_DATA.helpCenter.iconActive;

  return (
    <ul className={styles.utilityMenu}>
      <li className={styles.utilityMenuItem}>
        <a
          href={MENU_DATA.orders.link}
          className={styles.utilityMenuLink}
          onClick={() => utilityMenuTagManager(MENU_DATA.orders.label)}
          data-pniautomationid="HeaderUtilityMenu"
        >
          <OrdersIcon />
          {MENU_DATA.orders.label}
        </a>
      </li>
      <li
        className={clsx(styles.utilityMenuItem, styles.utilityMenuDropdown, {
          [styles.utilityMenuOpen]: helpOpen,
        })}
        ref={helpContainerRef}
      >
        <button
          onClick={() => toggleDialog("help")}
          data-pniautomationid="HeaderHelpBtn"
          className={clsx(styles.utilityMenuDropdownBtn, {
            [styles.utilityMenuDropdownBtnActive]: helpOpen,
          })}
        >
          {helpOpen ? (
            <HelpActiveIcon style={{ pointerEvents: "none" }} />
          ) : (
            <HelpIcon style={{ pointerEvents: "none" }} />
          )}
          {MENU_DATA.helpCenter.labelShort}
        </button>
        <UtilityMenuDropdown menu="help" ref={helpRef} />
      </li>
      <li
        className={clsx(styles.utilityMenuItem, styles.utilityMenuDropdown, {
          [styles.utilityMenuOpen]: accountOpen,
        })}
        ref={accountContainerRef}
      >
        <button
          onClick={() => toggleDialog("account")}
          data-pniautomationid="HeaderSignInBtn"
          className={clsx(styles.utilityMenuDropdownBtn, {
            [styles.utilityMenuDropdownBtnActive]: accountOpen,
          })}
        >
          {accountOpen ? (
            <SignInActiveIcon style={{ pointerEvents: "none" }} />
          ) : (
            <SignInIcon style={{ pointerEvents: "none" }} />
          )}
          {isSignedIn ? MENU_DATA.account.labelShort : MENU_DATA.signIn.label}
        </button>
        <UtilityMenuDropdown
          menu="account"
          ref={accountRef}
          isSignedIn={isSignedIn}
        />
      </li>
      <li className={styles.utilityMenuItem}>
        <UtilityMenuCartBtn />
      </li>
    </ul>
  );
}
