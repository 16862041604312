export const WaysToDesignTagManager = (
  clickText: string | undefined,
  page: string | undefined
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: clickText,
    cms_component: "Ways to Design CTA",
  });
};
