export const RefHeightCount = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) return null;
  const children = Array.from(ref.current.children);
  const totalHeight = children.reduce((total, child: any) => {
    const styles = window.getComputedStyle(child);
    const marginTop = parseInt(styles.marginTop, 10) || 0;
    const marginBottom = parseInt(styles.marginBottom, 10) || 0;
    return total + child.offsetHeight + marginTop + marginBottom;
  }, 0);

  const parentStyles = window.getComputedStyle(ref.current);
  const paddingTop = parseInt(parentStyles.paddingTop, 10) || 0;
  const paddingBottom = parseInt(parentStyles.paddingBottom, 10) || 0;
  const marginTop = parseInt(parentStyles.marginTop, 10) || 0;
  const marginBottom = parseInt(parentStyles.marginBottom, 10) || 0;

  const finalHeight =
    totalHeight + paddingTop + paddingBottom + marginTop + marginBottom;
  return finalHeight;
};
