import { Container } from "./Layout";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement, useContext, useEffect, useState } from "react";
import { AdobeCategoriesContext } from "state_management/Contexts";
import { useAdobeData } from "hooks/useAdobeData";
import { IndustryTilesProps, RenderData, TileData } from "./types";

// Define data structure for tiles

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  const adobeContext = useContext(AdobeCategoriesContext);
  const [tilesArray, setTilesArray] = useState<RenderData[]>([]);

  const { dispatch } = adobeContext ?? { dispatch: null };

  let industryTiles: any, categoryId: string | undefined;

  if (component && page) {
    const content = getContainerItemContent<IndustryTilesProps>(
      component,
      page
    );
    industryTiles = content?.industryTiles;
    categoryId = content?.categoryId;
  }

  // Use custom hook to handle adobeData
  useAdobeData(industryTiles, categoryId, dispatch);

  useEffect(() => {
    if (!industryTiles || !page) {
      return;
    }

    const newTilesArray: RenderData[] = [];

    industryTiles.forEach((card: TileData) => {
      const { title, link, foil, image } = card;
      const imageUrl =
        image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();

      newTilesArray.push({ title, link, foil, image: imageUrl });
    });

    setTilesArray(newTilesArray);
  }, [industryTiles, page]);

  if (!component || !page) return null;

  const { title } =
    getContainerItemContent<IndustryTilesProps>(component, page) ?? {};

  return <Container tiles={tilesArray} title={title} />;
};
