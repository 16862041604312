import styles from "./TopNumbers.module.scss";
import { Steps } from "./Steps";
import { v4 as uuidv4 } from "uuid";
import { NumbersProps } from "./types";

export const TopNumbers = ({ data }: NumbersProps) => {
  const { sectionTitle, numberedSection } = data;

  return (
    <section className={styles.numbersContainer}>
      <div className={styles.numbersTitle} data-testid="numbers-title">
        <p>{sectionTitle ? sectionTitle : "Section Title"}</p>
      </div>
      <div className={styles.numbersSteps}>
        {numberedSection?.map((section, index) => {
          const { title, caption } = section;
          return (
            <Steps
              key={uuidv4()}
              title={title}
              caption={caption}
              index={index}
            />
          );
        })}
      </div>
    </section>
  );
};
