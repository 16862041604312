export const MarketingHolidayBannerTagManager = (
  clickText: string,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    element_location: "Landing Page Hero Banner",
    click_text: clickText,
    cms_component: "Landing Page Hero Banner",
  });
};
