import { useState, useEffect, useRef, MouseEvent } from "react";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";
import { ArrowNext, ArrowPrev } from "./TableMobileColumnHeader";
import clsx from "clsx";
import styles from "./Table.module.scss";
import { TableFCProps } from "./types";

export const Table = ({
  pmcolumnheader,
  pmtableQtyPrice,
  currentSelection,
  windowSize,
  className,
  className2,
}: TableFCProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [divHeight, setDivHeight] = useState({ height: 0 });
  const [mobileSelect, setMobileSelect] = useState<number>(1);
  const [isActive, setActive] = useState<number | null>(0);
  const [isThreeSlides, setThreeSlides] = useState<boolean>(false);

  // Get height of table
  const refHeight = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refHeight.current) {
      setDivHeight({ height: refHeight.current.clientHeight });
    }
  }, []);

  // Toggle "Show More/Less" table
  const showMoreHandler = () => {
    setShowMore(!showMore);
  };

  // Toggle Price column - mobile
  const columnHandler = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    index: number
  ) => {
    let liveBtnText = e.currentTarget.textContent;
    if (sliderLiveMsgRef.current !== null) {
      sliderLiveMsgRef.current.innerHTML = liveBtnText + `  pricing shown`;
    }
    setMobileSelect(+e.currentTarget.value);
    setActive(index);
  };
  const priceCap = mobileSelect - 1;
  const sliderLiveMsgRef = useRef<HTMLDivElement>(null);
  const sliderInit = () => {
    if (windowSize !== undefined && windowSize < 769) {
      const slickSlider = document.querySelector(".slick-slider");
      function setAttributes(
        el: Element | null,
        attributes: { [x: string]: any; role?: string; "aria-label"?: string }
      ) {
        Object.keys(attributes).forEach((attr) => {
          el?.setAttribute(attr, attributes[attr]);
        });
      }
      setAttributes(slickSlider, {
        role: "navigation",
        "aria-label": "Product Sizes",
      });
      const slides: NodeListOf<Element> =
        document.querySelectorAll("div.slick-slide");
      slides.forEach((slide) => {
        slide.setAttribute("aria-hidden", "false");
      });
    }
  };

  const mobileBtnTray = pmcolumnheader?.columnHeader.map(
    (colHead: string, index: number) => (
      <div key={index}>
        <button
          value={index + 1}
          className={clsx(styles.slide, {
            [styles.mobileBtnActive]: isActive === index,
            ["active"]: isActive === index,
          })}
          aria-pressed={isActive === index ? true : undefined}
          onClick={(e) => columnHandler(e, index)}
          data-testid="Table-header-mobile"
        >
          {colHead}
        </button>
      </div>
    )
  );

  useEffect(() => {
    if (
      pmcolumnheader?.columnHeader.length !== undefined &&
      pmcolumnheader?.columnHeader.length < 4
    ) {
      setThreeSlides(true);
    }
  }, [pmcolumnheader?.columnHeader.length]);

  // Carousel settings - 4 or more buttons
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    focusOnSelect: true,
    accessibility: true,
    nextArrow: <ArrowNext className={""} onClick={undefined} />,
    prevArrow: <ArrowPrev className={""} onClick={undefined} />,
    onInit: () => {
      sliderInit;
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          accessibility: true,
          arrows: false,
          focusOnSelect: false,
          swipe: false,
        },
      },
      {
        breakpoint: 539,
        settings: {
          accessibility: true,
          arrows: true,
          focusOnSelect: false,
          swipe: true,
          afterChange: () => {
            if (windowSize !== undefined && windowSize < 769) {
              const slides: NodeListOf<Element> =
                document.querySelectorAll("div.slick-slide");
              slides.forEach((slide) => {
                slide.setAttribute("aria-hidden", "false");
              });
            }
          },
        },
      },
    ],
  };

  //Carousel settings - 3 buttons
  const settings3 = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    onInit: () => {
      sliderInit;
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          accessibility: true,
          arrows: false,
          focusOnSelect: false,
          swipe: false,
        },
      },
      {
        breakpoint: 539,
        settings: {
          accessibility: true,
          arrows: false,
          focusOnSelect: false,
          slidesToShow: 3,
          swipeToSlide: false,
          swipe: false,
        },
      },
    ],
  };

  return (
    <>
      {windowSize !== undefined && windowSize < 769 ? (
        <>
          {pmcolumnheader?.columnHeader.length !== 1 ? (
            <>
              <Slider
                className={clsx(styles.slider, {
                  [styles.threeSlides]: isThreeSlides,
                })}
                {...(isThreeSlides ? { ...settings3 } : { ...settings })}
              >
                {mobileBtnTray}
              </Slider>
              <div
                className="visuallyhidden"
                aria-live="polite"
                ref={sliderLiveMsgRef}
              ></div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Slider className={styles.slider} />
        </>
      )}
      <section className={styles.container}>
        <div
          className={clsx(
            styles.wrapper,
            { [styles.shortTable]: divHeight.height < 320 },
            { [styles.showMoreContainer]: showMore },
            className
          )}
          ref={refHeight}
        >
          <div
            className={clsx(styles.table, {
              [styles.twoColumns]: pmcolumnheader?.columnHeader.length === 1,
            })}
            role="table"
          >
            <div
              className={clsx(styles.tableCaption, "visuallyhidden")}
              data-testid="Table-caption-SR-text"
            >
              {currentSelection} Pricing Table
            </div>
            <div className={styles.headGroup} role="rowgroup">
              <div className={styles.headers} role="row">
                <div
                  className={clsx(styles.tableHeader, styles.quantity)}
                  role="columnheader"
                >
                  <p data-testid="Table-column1-header">
                    {pmcolumnheader?.colheader1}
                  </p>
                </div>
                {windowSize !== undefined && windowSize < 769 ? (
                  <>
                    <div className={styles.tableHeader} role="columnheader">
                      <p>PRICE</p>
                    </div>
                  </>
                ) : (
                  <>
                    {pmcolumnheader?.columnHeader.map((colHead: string) => (
                      <div
                        className={styles.tableHeader}
                        role="columnheader"
                        key={uuidv4()}
                        data-testid="Table-header"
                      >
                        <p>{colHead}</p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className={styles.rowGroup} role="rowgroup">
              {pmtableQtyPrice?.map(
                (col: { col1: string; prcell: string[] }) => (
                  <div
                    className={clsx(styles.row, className2)}
                    key={uuidv4()}
                    role="row"
                  >
                    <div
                      className={clsx(styles.tableItem, styles.quantity)}
                      role="cell"
                      data-testid="Table-qty-cell"
                    >
                      <p>{col.col1}</p>
                    </div>
                    {windowSize !== undefined && windowSize < 769 ? (
                      <>
                        {col.prcell
                          ? col.prcell
                              .slice(priceCap, mobileSelect) // filter to show single column
                              .map((price: string) => (
                                <div
                                  key={uuidv4()}
                                  className={styles.tableItem}
                                  role="cell"
                                  data-testid="Table-price-cell-mobile"
                                >
                                  <p>{price}</p>
                                </div>
                              ))
                          : null}
                      </>
                    ) : (
                      <>
                        {col.prcell
                          ? col.prcell
                              .slice(0, pmcolumnheader?.columnHeader.length)
                              .map((price: string) => (
                                <div
                                  key={uuidv4()}
                                  className={styles.tableItem}
                                  role="cell"
                                  data-testid="Table-price-cell"
                                >
                                  <p>{price}</p>
                                </div>
                              ))
                          : null}
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div
          className={clsx(styles.showMoreBtn, {
            [styles.shortTable]: divHeight.height < 320,
          })}
        >
          <button
            aria-expanded={showMore ? true : false}
            onClick={() => showMoreHandler()}
            data-testid="Table-showmore-btn"
          >
            Show {showMore ? "Less" : "More"}
            <span
              className="visuallyhidden"
              data-testid="Table-showmore-SR-text"
            >
              &nbsp;prices for {currentSelection}
            </span>
          </button>
        </div>
      </section>
    </>
  );
};
