import { tagManager } from "../Navbar/tagManager";
import { QuickLinksProps } from "../Navbar/types";
import Image from "next/image";
import { linkAttributes } from "services/linkAttributes";
import styles from "./QuickLinks.module.scss";
import clsx from "clsx";

export function QuickLinks({ quickLinks }: QuickLinksProps) {
  if (!quickLinks) return null;

  return (
    <ul className={styles.quickLinks}>
      {quickLinks.map((data) => {
        const attributes = linkAttributes(data.link, data.newTab);
        return (
          <li key={data.key}>
            <a
              {...attributes}
              onClick={() => tagManager(`1st Level > ${data.title}`)}
              data-pniautomationid={data.title}
              className={clsx(styles.quickLinksLink, {
                [styles.quickLinksLinkBold]: data.bold,
              })}
            >
              {data.icon && (
                <Image src={data.icon} alt="" width={20} height={20} />
              )}
              {data.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
