import { Document } from "@bloomreach/spa-sdk";
import {
  HHLayout,
  VVHLayout,
  HVVLayout,
  VVVVLayout,
  MixedLayout,
} from "./Layouts";
import styles from "./BentoBox.module.scss";
import { ContainerProps } from "./types";

// Render the proper layout
const getLayoutComponent = (layoutSelection: string) => {
  switch (layoutSelection) {
    case "H H":
      return HHLayout;
    case "V V H":
      return VVHLayout;
    case "H V V":
      return HVVLayout;
    case "V V V V":
      return VVVVLayout;
    case "Mixed":
      return MixedLayout;
    default:
      return null;
  }
};

export const Container = ({ page, data }: ContainerProps) => {
  return (
    <article className={styles.container}>
      {data.map((item) => {
        const document = item && page.getContent<Document>(item);

        if (!document) return null;

        const rowData = document.getData();
        const layoutSelection = rowData.layout.selectionValues[0].key;

        const LayoutComponent = getLayoutComponent(layoutSelection);
        const layoutData = {
          tiles: rowData.tilesRow,
          xlImage: rowData.xlDesktopImage,
          layout: layoutSelection,
        };

        return LayoutComponent ? (
          <LayoutComponent
            key={layoutSelection}
            data={layoutData}
            page={page}
          />
        ) : null;
      })}
    </article>
  );
};
