import { useState } from "react";
import { Carousel } from "./Carousel";
import { Tab } from "./Utils";
import { ContainerProps } from "./types";
import styles from "./Carousel.module.scss";

export const Container = ({ data, page }: ContainerProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabSwitch, setTabSwitch] = useState(false);
  const [clsBtnStyle, setClsBtnStyle] = useState({
    transition: "transform 0.3s ease",
  });
  const [contentTransition, setContentTransition] =
    useState("opacity 0.38s ease");

  const { title, description, tabs } = data;

  const carouselProps = {
    data: tabs,
    activeTab: activeTabIndex,
    page,
    parentTitle: title,
    clsBtnStyle,
    contentTransition,
    tabSwitch,
    setClsBtnStyle,
    setContentTransition,
    setTabSwitch,
  };

  return (
    <article className={styles.container}>
      <header className={styles.containerHeader}>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
      </header>
      {tabs.length > 1 ? (
        <section className={styles.tabsSection}>
          {tabs.map((tab, index) => {
            const tabProps = {
              tabIndex: index,
              activeTabIndex: activeTabIndex,
              label: tab.tabLabel,
              setActiveTabIndex: setActiveTabIndex,
              setClsBtnStyle: setClsBtnStyle,
              setContentTransition: setContentTransition,
              setTabSwitch: setTabSwitch,
            };
            return <Tab key={index} {...tabProps} />;
          })}
        </section>
      ) : null}
      <section className={styles.carouselWrapper}>
        <Carousel {...carouselProps} />
      </section>
    </article>
  );
};
