import { useEffect, useState } from "react";

// Hook for checking the current page
export const PageCheck = (path: any | undefined) => {
  const [page, setPage] = useState("");

  useEffect(() => {
    const home = process.env.NEXT_PUBLIC_BASEPATH + "/";
    if (path.path === home) {
      setPage("homepage");
    } else if (
      path.path.includes("/offers/") ||
      path.path.includes("/print-deals/")
    ) {
      setPage("marketing_landing_page");
    } else if (
      !path.path.includes("/offers/") &&
      path.path.length > home.length
    ) {
      setPage("product_category_page");
    }
  }, [path]);
  return page;
};
