import Image from "next/image";
import { useContext, useEffect, useRef, useState } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { useResize } from "hooks/useResize";
import { linkAttributes } from "services/linkAttributes";
import { ImageToUrl } from "services/imageToUrl";
import { ClipboardButton } from "../utils/ClipboardButton";
import { BottomLabel, FoilLabel, Header } from "./Utils";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";
import clsx from "clsx";
import styles from "./BentoBox.module.scss";
import { TileContainerProps, TileProps } from "./types";
import { BentoBoxTagManager } from "./BentoBoxTagManager";

export const TileContainer = ({ link, size, children }: TileContainerProps) => {
  const url = linkAttributes(link.link ?? "", link.newTab);
  return (
    <a
      className={clsx(styles.tile, {
        [styles.tileHorizontal]: size === "L",
        [styles.tileVertical]: size === "S",
        [styles.tileOne]: size === "XL",
      })}
      {...url}
    >
      {children}
    </a>
  );
};

export const SimpleCard = ({ data }: TileProps) => {
  const { background, backgroundImage, textColor, content } = data.data;
  const { red, green, blue, alpha } = background;
  const { description, descriptionMobile, header } = content;
  const imageUrl = backgroundImage ? ImageToUrl(backgroundImage) : null;
  const xlImageUrl = data.xlImage ? ImageToUrl(data.xlImage) : null;
  const textColorChoice = textColor.selectionValues[0].key;
  const tileSize = data.size;
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const backgroundStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    color: textColorChoice,
  };

  const coverStyles = {
    backgroundImage: `url(${tileSize === "XL" ? xlImageUrl : imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: textColorChoice,
  };

  return (
    <div
      data-testid="simple-container"
      onClick={() => BentoBoxTagManager(header, tagPage)}
      style={backgroundImage ? coverStyles : backgroundStyles}
      className={clsx(styles.simpleContainer, {
        [styles.simpleContainerHorizontal]: tileSize === "L",
        [styles.simpleContainerVertical]: tileSize === "S",
        [styles.simpleContainerOne]: tileSize === "XL",
      })}
    >
      <div
        className={clsx(styles.simpleWrapper, {
          [styles.simpleContainerWrapperHorizontal]: tileSize === "L",
          [styles.simpleContainerWrapperVertical]: tileSize === "S",
          [styles.simpleContainerWrapperXL]: tileSize === "XL",
        })}
      >
        <h3>{header}</h3>
        <div className={styles.simpleTextFull}>
          {description && (
            <BrRichTextContent
              page={data.page}
              content={{ html: description.value }}
            />
          )}
        </div>
        <div className={styles.simpleTextMobile}>
          <p>{descriptionMobile}</p>
        </div>
      </div>
    </div>
  );
};

export const FancyCard = ({ data }: TileProps) => {
  const { background, backgroundImage, textColor, content } = data.data;
  const { red, green, blue, alpha } = background;
  const { foil } = content;
  const imageUrl = backgroundImage ? ImageToUrl(backgroundImage) : null;
  const xlImageUrl = data.xlImage ? ImageToUrl(data.xlImage) : null;
  const textColorChoice = textColor.selectionValues[0].key;
  const tileSize = data.size;
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const backgroundStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    color: textColorChoice,
  };

  const coverStyles = {
    backgroundImage: `url(${tileSize === "XL" ? xlImageUrl : imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: textColorChoice,
  };

  return (
    <div
      data-testid="fancy-card"
      onClick={() => BentoBoxTagManager(content.header, tagPage)}
      style={backgroundImage ? coverStyles : backgroundStyles}
      className={clsx(styles.fancyContainer, {
        [styles.fancyContainerHorizontal]: tileSize === "L",
        [styles.fancyContainerVertical]: tileSize === "S",
        [styles.fancyContainerOne]: tileSize === "XL",
      })}
    >
      <Header {...content} />
      {foil && <FoilLabel />}
    </div>
  );
};

export const FlipCard = ({ data }: TileProps) => {
  const [isActiveContent, setIsActiveContent] = useState(false);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  const size = useResize();

  const { background, backgroundImage, textColor, content } = data.data;
  const { red, green, blue, alpha } = background;
  const {
    bottomLabel,
    flipBackground,
    flipCode,
    flipContent,
    flipHeader,
    flipTextColor,
  } = content;

  const {
    red: flipRed,
    green: flipGreen,
    blue: flipBlue,
    alpha: flipAlpha,
  } = flipBackground || {};

  const imageUrl = backgroundImage ? ImageToUrl(backgroundImage) : null;
  const xlImageUrl = data.xlImage ? ImageToUrl(data.xlImage) : null;
  const textColorChoice = textColor.selectionValues[0].key;
  const flipTextColorChoice = flipTextColor?.selectionValues[0].key;
  const tileSize = data.size;

  const backgroundStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    color: textColorChoice,
  };

  const coverStyles = {
    backgroundImage: `url(${tileSize === "XL" ? xlImageUrl : imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: textColorChoice,
  };
  const contentStyles = {
    width: `${containerDimensions.width}px`,
    height: `${containerDimensions.height}px`,
    backgroundColor: `rgba(${flipRed}, ${flipGreen}, ${flipBlue}, ${flipAlpha})`,
    color: flipTextColorChoice,
  };

  const TagManagerProps = {
    tagPage: tagPage,
    taggedComponent: "Bento Box",
    taggedElement: "Bento Box Flip Card",
  };

  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerDimensions({ width: width, height: height });
    }
  }, [size]);

  return (
    <div
      ref={containerRef}
      onClick={() => BentoBoxTagManager(content.header, tagPage)}
      className={clsx(styles.flipContainer, {
        [styles.flipContainerHorizontal]: tileSize === "L",
        [styles.flipContainerVertical]: tileSize === "S",
        [styles.flipContainerOne]: tileSize === "XL",
      })}
    >
      <section
        style={backgroundImage ? coverStyles : backgroundStyles}
        className={styles.flipCover}
      >
        <Header {...content} />

        <div className={styles.flipBottom} data-testid={"plus-button"}>
          <BottomLabel
            data={bottomLabel}
            setIsActiveContent={setIsActiveContent}
            isActiveContent={isActiveContent}
          />
        </div>
      </section>
      <section
        data-testid={"flip-content"}
        className={clsx(styles.flipContent, {
          [styles.flipContentXL]: tileSize === "XL",
          [styles.flipContentActive]: isActiveContent,
        })}
        style={contentStyles}
      >
        <div
          className={clsx(styles.flipContentHeader, {
            [styles.flipContentXLHeader]: tileSize === "XL",
          })}
        >
          <p>{flipHeader}</p>
        </div>
        {flipCode ? (
          <div
            className={clsx(styles.flipContentCode, {
              [styles.flipContentLCode]: tileSize === "L",
              [styles.flipContentXLCode]: tileSize === "XL",
            })}
          >
            <p>Coupon code: </p>
            <div className={styles.copyButtonWrapper}>
              <ClipboardButton code={flipCode} {...TagManagerProps}>
                <div
                  className={styles.codeText}
                  style={{
                    color: `${flipTextColorChoice}`,
                    textDecoration: `underline`,
                  }}
                >
                  <p>{flipCode}</p>
                  {flipCode && (
                    <div className={styles.copyButtonIcon}>
                      <Image
                        src={
                          flipTextColorChoice === "White"
                            ? "/services/printing/static-pages/icons/copy-mirrored-white.svg"
                            : "/services/printing/static-pages/icons/copy-mirrored.svg"
                        }
                        alt=""
                        width={10}
                        height={10}
                      />
                    </div>
                  )}
                </div>
              </ClipboardButton>
            </div>
          </div>
        ) : null}
        <div className={styles.flipContentText}>
          {flipContent && (
            <BrRichTextContent
              page={data.page}
              content={{ html: flipContent.value }}
            />
          )}
        </div>
      </section>
    </div>
  );
};
