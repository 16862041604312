import styles from "./HeadingBlog.module.scss";
import { ContainerProps, DynamicHeadingProps, HeadingTag } from "./types";
import { BrRichTextContent } from "../BrRichTextContent";
import clsx from "clsx";
import { linkAttributes } from "services/linkAttributes";

const DynamicHeadingTag = ({
  tagSize,
  text,
  className,
  style,
  ...props
}: DynamicHeadingProps) => {
  if (isNaN(tagSize) || tagSize < 1 || tagSize > 6) {
    return (
      <div className={className} style={style} {...props}>
        {text}
      </div>
    );
  }
  const Tag: HeadingTag = `h${tagSize}` as HeadingTag;
  return (
    <Tag className={className} style={style} {...props}>
      {text}
    </Tag>
  );
};

export const Container = ({ page, data }: ContainerProps) => {
  const {
    header,
    headerSize,
    textBlock,
    fontcolor,
    backgroundColor,
    ctaButton,
    paragraphStyle,
    subheadingSection,
  } = data;
  const font_color = fontcolor.selectionValues[0]["label"];
  const paragraph_style = paragraphStyle.selectionValues[0]["label"];
  const no_bg_color =
    backgroundColor.red === 255 &&
    backgroundColor.green === 255 &&
    backgroundColor.blue === 255;

  const subheadingMap: Record<string, number> = {
    "X-Large": 1,
    Large: 2,
    Medium: 3,
    "Small/Bold": 4,
    "Small/Medium": 5,
    "X-Small": 6,
  };
  const headerMap: Record<string, number> = {
    H1: 1,
    H2: 2,
    H3: 3,
    H4: 4,
  };
  const header_size = headerMap[headerSize.selectionValues[0]["label"]];

  return (
    <article className={styles.container}>
      <section
        className={clsx(styles.section, {
          [styles.sectionPadding]: !no_bg_color,
        })}
        style={{
          backgroundColor: `rgba(${backgroundColor.red}, ${backgroundColor.green}, ${backgroundColor.blue}, ${backgroundColor.alpha})`,
        }}
      >
        <div className={styles.sectionHeader}>
          <DynamicHeadingTag
            className={clsx({
              [styles[`hH${header_size}`]]:
                header_size >= 1 && header_size <= 4,
            })}
            text={header}
            tagSize={header_size}
          />

          {textBlock && (
            <div
              className={clsx({
                [styles.whiteFont]: font_color === "White",
                [styles.blackFont]: font_color === "Black",
                [styles.pDefaultStyle]: paragraph_style === "Default",
                [styles.pSmallStyle]: paragraph_style === "Small",
                [styles.pLargeStyle]: paragraph_style === "Large",
              })}
            >
              <BrRichTextContent
                page={page!}
                content={{ html: textBlock.value }}
              />
            </div>
          )}

          {subheadingSection.map((el, index) => {
            const subheading_size =
              el.subheadingSize.selectionValues[0]["label"];
            const trimmedSize = subheading_size.trim();
            const headingTagNumber = subheadingMap[trimmedSize];
            return (
              <>
                {el.subheading !== "" ? (
                  <div
                    key={index}
                    className={clsx({
                      [styles.whiteFont]: font_color === "White",
                      [styles.blackFont]: font_color === "Black",
                    })}
                  >
                    <DynamicHeadingTag
                      className={clsx({
                        [styles[`subheadingSize${headingTagNumber}`]]:
                          headingTagNumber >= 1 && headingTagNumber <= 6,
                      })}
                      text={el.subheading}
                      tagSize={headingTagNumber}
                    />

                    {el.subheadingTextBlock.value !== "" && (
                      <div
                        className={clsx({
                          [styles.pDefaultStyle]: paragraph_style === "Default",
                          [styles.pSmallStyle]: paragraph_style === "Small",
                          [styles.pLargeStyle]: paragraph_style === "Large",
                        })}
                      >
                        <BrRichTextContent
                          page={page!}
                          content={{ html: el.subheadingTextBlock.value }}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            );
          })}
        </div>

        {ctaButton.length > 0 ? (
          <div className={styles.ctaButton}>
            {ctaButton.map((el, index) => {
              const address = linkAttributes(el.address, el.newTab);
              return (
                <a key={index} {...address}>
                  <div
                    className={
                      index === 0
                        ? styles.buttonPrimary
                        : styles.buttonSecondary
                    }
                  >
                    {el.title ? el.title : "CTA"}
                  </div>
                </a>
              );
            })}
          </div>
        ) : null}
      </section>
    </article>
  );
};
