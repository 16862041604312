import Image from "next/image";
import { useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import clsx from "clsx";
import styles from "./MarketingSection.module.scss";
import { LabelProps } from "./types";
import { imagePlaceholder } from "@/constants/index";

export const Label = ({ data }: LabelProps) => {
  const { labelColor, labelIcon, labelText } = data;

  const page = useContext(BrPageContext);

  let icon = labelIcon && page?.getContent<ImageSet>(labelIcon)?.getOriginal();

  const color = labelColor?.selectionValues[0].key;

  return (
    <div
      className={clsx(styles.label, {
        [styles.labelBlack]: labelColor && color === "Black",
        [styles.labelRed]: labelColor && color === "Red",
        [styles.labelBlue]: labelColor && color === "Blue",
      })}
    >
      {labelIcon && (
        <div className={styles.labelIcon}>
          <Image
            src={icon?.getUrl() || imagePlaceholder}
            alt=""
            fill
            sizes="(min-width: 375px) 100vw"
          />
        </div>
      )}
      <p>{labelText}</p>
    </div>
  );
};
