/* eslint-disable @next/next/no-html-link-for-pages */
import StaplesLogo from "@/public/static-pages/icons/navbar/staples-logo.svg";
import StaplesIcon from "@/public/static-pages/icons/navbar/staples-icon.svg";
import styles from "./Logo.module.scss";

import { tagManager } from "./tagManager";

export function Logo({ mini }: { mini?: boolean }) {
  return (
    <a
      href="/services/printing"
      aria-label="Go to Staples Print & Marketing home"
      className={styles.logoLink}
      onClick={() => tagManager()}
      data-pniautomationid="HeaderLogo"
    >
      {mini ? (
        <StaplesIcon
          role="image"
          aria-label="Staples logo"
          data-testid="staples-icon"
        />
      ) : (
        <StaplesLogo
          role="image"
          aria-label="Staples logo"
          data-testid="staples-logo"
        />
      )}
    </a>
  );
}
