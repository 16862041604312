import Image from "next/image";
import { useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import styles from "./SubMenu.module.scss";
import clsx from "clsx";
import { ItemProps } from "./types";
import { imagePlaceholder } from "@/constants/index";
import { linkAttributes } from "services/linkAttributes";
import { tagManager } from "../tagManager";

export const Item = ({ parentItem, hideIcons, data, category }: ItemProps) => {
  const { itemDescription, itemHeader, itemImage, itemLink, newTab } = data;

  const page = useContext(BrPageContext);

  let icon = itemImage && page?.getContent<ImageSet>(itemImage)?.getOriginal();

  const linkProps = linkAttributes(itemLink, newTab);

  return (
    <div
      className={clsx(styles.item, {
        [styles.itemProduct]: category === "product",
      })}
      data-testid="item-component"
    >
      {!hideIcons && icon && (
        <div className={styles.itemImage}>
          <Image
            src={icon?.getUrl() || imagePlaceholder}
            alt=""
            width={48}
            height={58}
            className={styles.itemImagePic}
            priority
          />
        </div>
      )}
      <div
        className={clsx(styles.itemText, {
          [styles.itemNoIcon]: hideIcons || !icon,
          [styles.itemTextProduct]: category === "product" && !itemDescription,
        })}
      >
        <a
          {...linkProps}
          className={styles.link}
          onClick={() => tagManager(`${parentItem} > ${itemHeader}`)}
        >
          {itemHeader}
        </a>
        <p>{itemDescription}</p>
      </div>
    </div>
  );
};
