import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Dropdowns.module.scss";
import { useResize } from "hooks/useResize";
import { DropdownItem } from "./DropdownItem";
import { CategoriesTuple, DropdownsProps } from "./types";
import { useLockBodyScroll } from "hooks/useLockBodyScroll";

export const Dropdowns = ({
  primaryData,
  secondaryData,
  levelSetup,
  height,
}: DropdownsProps) => {
  const [open, setOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [categories, setCategories] = useState<CategoriesTuple | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(null);

  const windowSize = useResize();
  useLockBodyScroll(open);

  useEffect(() => {
    setIsDesktop(windowSize >= 821);
  }, [windowSize]);

  useEffect(() => {
    primaryData &&
      secondaryData &&
      setCategories({ primary: primaryData, secondary: secondaryData });
  }, [primaryData, secondaryData]);

  const backdropClose: () => void = () => {
    setActiveItemIndex(null);
    setOpen(false);
  };

  const categoriesArray = [categories?.primary, categories?.secondary];

  return (
    <>
      <div
        className={clsx(styles.backdrop, {
          [styles.backdropOpen]: open,
        })}
        onClick={backdropClose}
        data-testid="backdrop"
      />
      {categories && (
        <section className={styles.dropdownsSection}>
          {categoriesArray?.map((section, index: number) => {
            const dropdownItemProps = {
              height: height,
              data:
                section?.position === "primary" ? primaryData : secondaryData,
              levelSetup: levelSetup,
              isDesktop: isDesktop,
              isActive: index === activeItemIndex,
              onClick: () => {
                if (index === activeItemIndex) {
                  setActiveItemIndex(null);
                  setOpen(false);
                  document.body.classList.remove("modalOpen");
                } else {
                  setActiveItemIndex(index);
                  setOpen(true);
                  document.body.classList.add("modalOpen");
                }
              },
            };
            return <DropdownItem key={index} {...dropdownItemProps} />;
          })}
        </section>
      )}
    </>
  );
};
