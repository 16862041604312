import Image from "next/image";
import { useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { BrRichTextContent } from "@/components/BrRichTextContent";
import { ImageSet } from "@bloomreach/spa-sdk";
import { Label } from "./Label";
import { BlueCta } from "../utils";
import styles from "./MarketingSection.module.scss";
import { MarketingSectionProps } from "./types";
import { imagePlaceholder } from "@/constants/index";

export const MarketingSection = ({
  ctaLink,
  header,
  marketingImage,
  marketingLabel,
  marketingText,
  parentItem,
}: MarketingSectionProps) => {
  const page = useContext(BrPageContext);

  const image =
    marketingImage && page?.getContent<ImageSet>(marketingImage)?.getOriginal();

  const isLabel = marketingLabel.labelText;
  const isCta = ctaLink.title && ctaLink.address;
  const promoLinkData = {
    ...ctaLink,
    parentItem: parentItem,
  };

  return (
    <section className={styles.container}>
      <div className={styles.containerContent}>
        <div className={styles.image}>
          <Image
            src={image?.getUrl() || imagePlaceholder}
            alt=""
            fill
            className={styles.imagePic}
            sizes="(max-width: 820px) 100vw, 15vw"
            priority
          />

          {isLabel && <Label data={marketingLabel} />}
        </div>

        <div className={styles.text}>
          <p className={styles.textHeader}>{header}</p>
          <div className={styles.textContent}>
            {marketingText && (
              <BrRichTextContent
                page={page!}
                content={{ html: marketingText?.value }}
                isNavigation
                parentItem={parentItem}
              />
            )}
          </div>
        </div>
      </div>

      {isCta && <BlueCta ctaLink={promoLinkData} />}
    </section>
  );
};
