import { BrProps } from "@bloomreach/react-sdk";
import { Document, ImageSet } from "@bloomreach/spa-sdk";
import { ReactElement } from "react";
import Image from "next/legacy/image";
import { imagePlaceholder } from "../../constants";
import { Section } from "@/components/utils/Section";
import { HeroGalleryParameters, HeroGalleryDocument } from "./types";
import ArrowRightBlack from "../../public/static-pages/icons/arrow-right-black.svg";
import ArrowRightWhite from "../../public/static-pages/icons/arrow-right-white.svg";
import { Headline } from "../utils/Headline";
import {
  Grid,
  GridItem,
  BackgroundImages,
  ContentWrapper,
  Badge,
  Body,
  BlockText,
  CalloutWrapper,
  Callout,
  CtaWrapper,
  Cta,
  IconWrapper,
} from "./HeroGallery.helpers";

export function HeroGallery({ component, page }: BrProps): ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const { document: documentRef } =
    component.getModels<HeroGalleryParameters>();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? <div>Hero Gallery Document</div> : null;
  }

  const { headline, pmHeroGalleryFancyCard, pmHeroGalleryBasicCard } =
    document.getData<HeroGalleryDocument>();

  if (!pmHeroGalleryFancyCard || !pmHeroGalleryBasicCard) {
    return page.isPreview() ? (
      <div>Please select a Hero Gallery Document</div>
    ) : null;
  }

  // Large cards with background image, badge, callout, etc.
  const fancyCardsLimit = Math.min(pmHeroGalleryFancyCard.length, 3);

  // Small cards with less content
  const basicCardsLimit = Math.min(pmHeroGalleryBasicCard.length, 2);

  // Three or four cells grid layout
  const gridCells =
    fancyCardsLimit >= 3 || fancyCardsLimit + basicCardsLimit <= 3
      ? "gridThree"
      : "gridFour";

  // Delimiter for multi line text
  const delimiter = /\r\n|\r|\n/;

  return (
    <Section ariaLabel={headline}>
      <Headline level="h1" style={["h1"]}>
        {headline}
      </Headline>
      <Grid style={[gridCells]}>
        {
          // Render a set of large cards generated from the 'pmHeroGalleryFancyCard' array, limited to 'fancyCardsLimit' items
          pmHeroGalleryFancyCard
            .slice(0, fancyCardsLimit)
            .map((content, index) => {
              const data = {
                ...content,
                gaHeadline: content.headline,
                gaCtaText: content.ctaLabel,

                body: content.body.split(delimiter),
                headline: content.headline.split(delimiter),
                backgroundImageMobile:
                  page
                    .getContent<ImageSet>(content.backgroundImageMobile)
                    ?.getOriginal()
                    ?.getUrl() ?? imagePlaceholder,
                backgroundImageAltMobile:
                  page
                    .getContent<ImageSet>(content.backgroundImageMobile)
                    ?.getDescription() ?? "",
                backgroundImageTablet:
                  page
                    .getContent<ImageSet>(content.backgroundImageTablet)
                    ?.getOriginal()
                    ?.getUrl() ?? imagePlaceholder,
                backgroundImageAltTablet:
                  page
                    .getContent<ImageSet>(content.backgroundImageTablet)
                    ?.getDescription() ?? "",
                backgroundImageDesktop:
                  page
                    .getContent<ImageSet>(content.backgroundImageDesktop)
                    ?.getOriginal()
                    ?.getUrl() ?? imagePlaceholder,
                backgroundImageAltDesktop:
                  page
                    .getContent<ImageSet>(content.backgroundImageDesktop)
                    ?.getDescription() ?? "",
                backgroundImageWide:
                  page
                    .getContent<ImageSet>(content.backgroundImageWide)
                    ?.getOriginal()
                    ?.getUrl() ?? imagePlaceholder,
                backgroundImageAltWide:
                  page
                    .getContent<ImageSet>(content.backgroundImageWide)
                    ?.getDescription() ?? "",
              };

              const imageAttributes = {
                mobile: {
                  src: data.backgroundImageMobile,
                  alt: data.backgroundImageAltMobile,
                },
                tablet: {
                  src: data.backgroundImageTablet,
                  alt: data.backgroundImageAltTablet,
                },
                desktop: {
                  src: data.backgroundImageDesktop,
                  alt: data.backgroundImageAltDesktop,
                },
                wide: {
                  src: data.backgroundImageWide,
                  alt: data.backgroundImageAltWide,
                },
              };

              const inlineStyles = {
                color: data.textColor.selectionValues[0].key,
              };

              return (
                <GridItem
                  key={index}
                  style={["gridItemFancy"]}
                  inlineStyle={inlineStyles}
                >
                  <BackgroundImages imageAttributes={imageAttributes} />
                  <ContentWrapper>
                    {data.badge && (
                      <Badge
                        color={data.badgeColor.selectionValues[0].key}
                        label={data.badge}
                      />
                    )}
                    <Headline level="h2" style={[index === 0 ? "h2" : "h3"]}>
                      {data.headline.map((line, index) => (
                        <BlockText key={index} line={line} />
                      ))}
                    </Headline>
                    {data.body && (
                      <Body>
                        {data.body.map((line, index) => (
                          <BlockText key={index} line={line} />
                        ))}
                      </Body>
                    )}
                    {(data.calloutSmall || data.calloutLarge) && (
                      <CalloutWrapper>
                        {data.calloutSmall && (
                          <Callout
                            style="calloutSmall"
                            text={data.calloutSmall}
                          />
                        )}
                        {data.calloutLarge && (
                          <Callout
                            style="calloutLarge"
                            text={data.calloutLarge}
                          />
                        )}
                      </CalloutWrapper>
                    )}
                    <Cta
                      gaHeadline={data.gaHeadline}
                      gaCtaText={data.gaCtaText}
                      ctaLink={data.ctaLink}
                      color={data.ctaColor.selectionValues[0].key}
                      newTab={data.newTab}
                    >
                      {data.ctaLabel}
                    </Cta>
                  </ContentWrapper>
                </GridItem>
              );
            })
        }
        {
          // Render a set of small cards if the 'fancyCardsLimit' is less than 3
          // The cards are generated from the 'pmHeroGalleryBasicCard' array, limited to 'basicCardsLimit' items
          fancyCardsLimit < 3 &&
            pmHeroGalleryBasicCard
              .slice(0, basicCardsLimit)
              .map((content, index) => {
                const data = {
                  ...content,
                  gaHeadline: content.headline,
                  gaCtaText: content.ctaLabel,
                  body: content.body?.split(delimiter),
                  icon:
                    content.icon &&
                    page
                      .getContent<ImageSet>(content.icon)
                      ?.getOriginal()
                      ?.getUrl(),
                };
                const inlineStyles = {
                  backgroundColor: data.backgroundColor,
                  color: data.textColor.selectionValues[0].key,
                };
                return (
                  <GridItem
                    key={index}
                    style={["gridItemBasic"]}
                    inlineStyle={inlineStyles}
                  >
                    {data.icon && (
                      <IconWrapper>
                        <Image src={data.icon} layout="fill" alt="" />
                      </IconWrapper>
                    )}
                    <Headline level="h2" style={["h4"]}>
                      {data.headline}
                    </Headline>
                    <Body>
                      {data.body.map((line, index) => (
                        <BlockText key={index} line={line} />
                      ))}
                    </Body>
                    <CtaWrapper>
                      <Cta
                        ctaLink={data.ctaLink}
                        gaHeadline={data.gaHeadline}
                        gaCtaText={data.gaCtaText}
                        newTab={data.newTab}
                      >
                        {data.ctaLabel}
                      </Cta>
                      <IconWrapper>
                        {data.textColor.selectionValues[0].key === "Black" ? (
                          <ArrowRightBlack role="presentation" />
                        ) : (
                          <ArrowRightWhite role="presentation" />
                        )}
                      </IconWrapper>
                    </CtaWrapper>
                  </GridItem>
                );
              })
        }
      </Grid>
    </Section>
  );
}
