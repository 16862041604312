import Image from "next/image";
import { ImageToUrl } from "services/imageToUrl";
import styles from "./DesignCategories.module.scss";
import { TileProps } from "./types";
import { useContext } from "react";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";
import { DesignCategoriesTagManager } from "./DesignCategoriesTagManager";

export const Tile = ({ data, categoryTitle }: TileProps) => {
  const { link, image, label } = data;
  const img = ImageToUrl(image);

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const tagManagerHandler = (categoryTitle: string, label: string) => {
    DesignCategoriesTagManager(categoryTitle, label, tagPage);
  };

  return (
    <a
      href={link ? link : "#"}
      onClick={() =>
        tagManagerHandler(
          categoryTitle
            ? categoryTitle
            : "Design Categories component without title",
          label
        )
      }
    >
      <div className={styles.imageContainer}>
        <Image
          src={
            img ? img : "/static-pages/images/placeholders/img-placeholder.png"
          }
          alt=""
          sizes="100vw"
          fill
          className={styles.imageContainerPic}
        />
      </div>
      <div className={styles.tileLabel}>
        <p>{label ? label : "Label"}</p>
      </div>
    </a>
  );
};
