import styles from "./CtaTile.module.scss";
import Image from "next/legacy/image";
import { imagePlaceholder } from "../../../constants";
import { linkAttributes } from "services/linkAttributes";
import { TileDataProps } from "./types";

export const CtaTile = ({
  data,
  className,
  handleClick,
}: TileDataProps & { className?: string }) => {
  const { tileImage, tileTitle, tilePrice, ctaButton, priceTitle } = data;

  const ctaLinkProps = linkAttributes(ctaButton?.ctaLink, ctaButton?.newTab);

  return (
    <section className={`${styles.tileContainer} ${className}`}>
      <figure className={styles.tileImage}>
        <Image
          src={tileImage ? tileImage : imagePlaceholder}
          alt=""
          layout="fill"
          objectFit="cover"
          className={styles.tileImagePic}
        />
      </figure>
      <div className={styles.tileText}>
        <h3>{tileTitle ? tileTitle : "Title headline"}</h3>
        <div className={styles.price}>
          <p className={styles.tileTextStarting}>
            {priceTitle ? priceTitle : "Starting at"}
          </p>
          <p className={styles.tileTextPrice}>
            {tilePrice ? tilePrice : "$XXX.XX"}
          </p>
        </div>
      </div>
      <div className={styles.ctaButtonWrapper}>
        <a className={styles.ctaButton} {...ctaLinkProps} onClick={handleClick}>
          {ctaButton?.ctaText ? ctaButton?.ctaText : "CTA Text"}
        </a>
      </div>
    </section>
  );
};
