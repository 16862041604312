import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement } from "react";
import { Container } from "./Container";
import { ContainerItem, Document } from "@bloomreach/spa-sdk";
import { ComponentParameters, ComponentDocument } from "./types";

export function BentoBox({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const { content: documentRef } = component.getModels<ComponentParameters>();

  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? (
      <div>Select PM - Paths Stack document</div>
    ) : null;
  }

  const containerData = document?.getData<ComponentDocument>();

  return <Container page={page} data={containerData.items} />;
}
