import { useEffect, useRef } from "react";

type DialogObserverCallback = () => void;

export const useDialogObserver = (
  onOpen: DialogObserverCallback,
  onClose: DialogObserverCallback
) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;

    if (dialog) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "open"
          ) {
            if (dialog.hasAttribute("open")) {
              onOpen();
            } else {
              onClose();
            }
          }
        });
      });

      observer.observe(dialog, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [onOpen, onClose]);

  return dialogRef;
};
