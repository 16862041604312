import clsx from "clsx";
import styles from "./BentoBox.module.scss";
import { TileContainer, FancyCard, FlipCard, SimpleCard } from "./BentoCards";
import { LayoutProps, LayoutTemplateProps } from "./types";

const Layout = ({
  data,
  page,
  layoutConfig,
  className,
}: LayoutTemplateProps) => {
  return (
    <section className={clsx(styles.tileWrapper, className)}>
      {data.tiles
        .filter((_, index) => index <= layoutConfig.maxTiles - 1)
        .map((item, index) => {
          const size = layoutConfig.getSize(index);

          const itemData = {
            data: item,
            xlImage: data.xlImage,
            page: page,
            size: size,
            mixedLayout: layoutConfig.mixedLayout,
          };

          return (
            <TileContainer key={index} link={item.link} size={size}>
              {item.content.contentType &&
                item.content.contentType === "brxsaas:pmBentoCardSimple" && (
                  <SimpleCard data={itemData} />
                )}
              {item.content.contentType &&
                item.content.contentType === "brxsaas:pmBentoCardFancy" && (
                  <FancyCard data={itemData} />
                )}
              {item.content.contentType &&
                item.content.contentType === "brxsaas:pmBentoCardFlipped" && (
                  <FlipCard data={itemData} />
                )}
            </TileContainer>
          );
        })}
    </section>
  );
};

export const HHLayout = ({ data, page }: LayoutProps) => (
  <Layout
    data={data}
    page={page}
    layoutConfig={{
      maxTiles: 2,
      mixedLayout: false,
      getSize: () => "L",
    }}
  />
);

export const HVVLayout = ({ data, page }: LayoutProps) => (
  <Layout
    data={data}
    page={page}
    layoutConfig={{
      maxTiles: 3,
      mixedLayout: false,
      getSize: (index: number) => (index === 0 ? "L" : "S"),
    }}
  />
);

export const VVHLayout = ({ data, page }: LayoutProps) => (
  <Layout
    data={data}
    page={page}
    layoutConfig={{
      maxTiles: 3,
      mixedLayout: false,
      getSize: (index: number) => (index === 2 ? "L" : "S"),
    }}
  />
);

export const VVVVLayout = ({ data, page }: LayoutProps) => (
  <Layout
    data={data}
    page={page}
    layoutConfig={{
      maxTiles: 4,
      mixedLayout: false,
      getSize: () => "S",
    }}
  />
);

export const MixedLayout = ({ data, page }: LayoutProps) => (
  <>
    <Layout
      data={data}
      page={page}
      layoutConfig={{
        maxTiles: 4,
        mixedLayout: true,
        getSize: (index: number) => (index <= 1 ? "L" : "S"),
      }}
      className={styles.mixedMobile}
    />
    <Layout
      data={data}
      page={page}
      layoutConfig={{
        maxTiles: 4,
        mixedLayout: true,
        getSize: (index: number) =>
          index === 0 ? "XL" : index === 1 ? "L" : "S",
      }}
      className={styles.mixedDesktop}
    />
  </>
);
