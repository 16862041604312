import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { BrRichTextContent } from "../../BrRichTextContent";
import { useResize } from "hooks/useResize";
import { Table } from "../../Table";
import { DropDown } from "../../DropDown";
import styles from "./PricingGrid.module.scss";
import clsx from "clsx";
import { DropDownFeaturesProps } from "@/types/utils";
import { PricingGridFCProps } from "./types";

export const PricingGrid = ({
  componentTitle,
  pmPricingTable,
  caption,
  page,
}: PricingGridFCProps) => {
  // Convert to generic prop for use with global dropdown component
  const dropDownList: DropDownFeaturesProps[] = pmPricingTable;
  const [currentSelection, setSelection] = useState<string>("");
  const dropDownSelectBtnToggle: boolean = true; // true: BUTTON, false: AHREF

  let windowSize = useResize();

  // Set default product selection
  useEffect(() => {
    if (dropDownList?.length) {
      setSelection(dropDownList[0].title);
    }
  }, [dropDownList]);

  return (
    <article
      id="pricing-grid"
      className={clsx(styles.container, {
        [styles.singleTable]: dropDownList?.length === 1,
        [styles.multiTable]: dropDownList?.length >= 2,
      })}
    >
      <h2
        className={styles.containerTitle}
        data-testid="PricingGrid-comp-title"
      >
        {componentTitle}
        <span className="visuallyhidden">: {currentSelection}</span>
      </h2>
      {dropDownList
        ?.filter((tab: { title: string }) => tab.title === currentSelection)
        .map((tab: { caption?: any }) => {
          const { caption } = tab;
          const content = (
            <BrRichTextContent page={page!} content={{ html: caption.value }} />
          );
          return (
            <div className={styles.pricingInfoSummary} key={uuidv4()}>
              <div
                className={styles.pricingText}
                data-testid="PricingGrid-content-text"
              >
                {content}
              </div>
            </div>
          );
        })}
      <div className={styles.content}>
        {dropDownList?.length !== 1 ? (
          <div className={styles.dropdownContainer}>
            <DropDown
              componentTitle={componentTitle}
              dropDownList={dropDownList}
              currentSelection={currentSelection}
              setSelection={setSelection}
              dropDownSelectBtnToggle={dropDownSelectBtnToggle}
              windowSize={windowSize}
            />
            {caption ? (
              <p>{caption}</p>
            ) : (
              <p>Select a product to view pricing detail</p>
            )}
          </div>
        ) : null}
        {dropDownList
          ?.filter((tab: { title: string }) => tab.title === currentSelection)
          .map(
            (tab: {
              caption?: any;
              pmtableQtyPrice?: any;
              pmcolumnheader?: any;
            }) => {
              const { caption, pmtableQtyPrice, pmcolumnheader } = tab;
              const content = (
                <BrRichTextContent
                  page={page!}
                  content={{ html: caption.value }}
                />
              );

              return (
                <div
                  key={uuidv4()}
                  className={clsx(styles.table, {
                    [styles.singleTable]: dropDownList?.length === 1,
                  })}
                >
                  <Table
                    pmcolumnheader={pmcolumnheader}
                    pmtableQtyPrice={pmtableQtyPrice}
                    currentSelection={currentSelection}
                    windowSize={windowSize}
                  />
                </div>
              );
            }
          )}
      </div>
    </article>
  );
};
