import React from "react";
import { Parallax } from "react-scroll-parallax";
import Image from "next/legacy/image";
import styles from "./HeroBanner.module.scss";
import { HeroTile } from "./HeroTile";
import { v4 as uuidv4 } from "uuid";
import { imagePlaceholder } from "../../constants";
import { HeroBannerFcProps } from "./types";

export const HeroBanner = ({
  imgMobile,
  title,
  imgDesktop,
  caption,
  tileArray,
}: HeroBannerFcProps) => {
  return (
    <article className={styles.container}>
      <Parallax speed={-20}>
        <figure className={styles.bannerImageMobile}>
          <Image
            src={imgMobile ? imgMobile : imagePlaceholder}
            width={770}
            height={300}
            layout="fixed"
            alt={title}
            priority
          ></Image>
        </figure>
      </Parallax>
      <figure className={styles.bannerImageDesktop}>
        <div className={styles.bannerImageDesktopBlur} />
        <Parallax speed={-21}>
          <div className={styles.bannerImageDesktopImg}>
            <Image
              src={imgDesktop ? imgDesktop : imagePlaceholder}
              width={1300}
              height={550}
              layout="fixed"
              alt={title}
              max-width="none"
              className={styles.bannerImagePic}
              priority
            ></Image>
          </div>
        </Parallax>
      </figure>
      <div className={styles.content}>
        <section className={styles.headerText}>
          <h1 className={styles.headerTextTitle} data-testid="Mkt-banner-title">
            {title ? title : "Banner Title"}
          </h1>

          <span className={styles.headerTextCaption}>
            {caption ? caption : "Banner Caption"}
          </span>
        </section>
        <section className={styles.tiles}>
          {tileArray?.map((card) => {
            const { title, caption, ctaTitle, ctaLink, imageUrl } = card;
            return (
              <HeroTile
                key={uuidv4()}
                title={title}
                caption={caption}
                ctaTitle={ctaTitle}
                ctaLink={ctaLink}
                imageUrl={imageUrl}
              />
            );
          })}
        </section>
      </div>
    </article>
  );
};
