import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { ClipboardButtonProps, TooltipProps } from "./types";
import { CopyToClipboard } from "services/copyToClipboard";
import { useResize } from "hooks/useResize";
import styles from "./ClipboardButton.module.scss";

export const ClipboardButtonTagManager = (
  code: string,
  page: string,
  taggedComponent: string,
  taggedElement: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: code,
    element_location: taggedComponent,
    cms_component: taggedElement,
  });
};

const DynamicTooltip = dynamic<TooltipProps>(
  () => import("./Tooltip").then((mod) => mod.Tooltip),
  { ssr: false }
);

export const ClipboardButton = ({
  children,
  code,
  style,
  tagPage,
  taggedComponent,
  taggedElement,
  textSize,
}: ClipboardButtonProps) => {
  const [isHover, setIsHover] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipLabel, setTooltipLabel] = useState("Copy to clipboard");
  const [isMobile, setIsMobile] = useState(false);
  const [initialClipButtonPosition, setInitialClipButtonPosition] = useState(0);

  const tooltipTextMobile = `Copied: ${code}`;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const clipRef = useRef<HTMLButtonElement>(null);
  const size = useResize();

  // Tooltip positioning on desktop
  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (size >= 821) {
      setTooltipPosition({ x: e.clientX + 10, y: e.clientY + 10 });
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setTooltipLabel("Copy to clipboard");
  };

  // Tooltip positioning on mobile
  const TOOLTIP_OFFSET_X = textSize ? textSize.x : 10;
  const TOOLTIP_OFFSET_Y = textSize ? textSize.y : 5;

  const tooltipPositionCalculation = (
    ref: React.RefObject<HTMLButtonElement>
  ) => {
    if (ref.current && tooltipRef.current && window.innerWidth < 821) {
      const { right, top } = ref.current.getBoundingClientRect();
      const { width, height } = tooltipRef.current.getBoundingClientRect();

      const xPosition = right - width / 2 + TOOLTIP_OFFSET_X;
      const yPosition = top - height - TOOLTIP_OFFSET_Y;

      setTooltipPosition({ x: xPosition, y: yPosition });
    }
  };

  // Code copy
  const handleCodeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    tooltipPositionCalculation(clipRef);
    setTooltipLabel(`Copied: ${code}`);
    if (!isHover) setIsHover(true);
    if (code) CopyToClipboard(code);
    ClipboardButtonTagManager(
      code ?? "",
      tagPage,
      taggedComponent,
      taggedElement
    );

    setTimeout(() => {
      setIsHover(false);
      setTooltipPosition({ x: 0, y: 0 });
    }, 2000);
  };

  useEffect(() => {
    if (size < 821) setIsMobile(true);
    setTooltipPosition({ x: 0, y: 0 });
    setIsHover(false);
  }, [size]);

  // Hide tooltip when page scrolled on mobile
  useEffect(() => {
    if (clipRef.current && size < 821) {
      setInitialClipButtonPosition(clipRef.current.getBoundingClientRect().top);
    }

    const handleScroll = () => {
      if (clipRef.current && size < 821) {
        const currentClipPosition = clipRef.current.getBoundingClientRect().top;
        if (currentClipPosition !== initialClipButtonPosition) {
          handleMouseLeave();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [initialClipButtonPosition, size]);

  const tooltipProps = {
    isHover,
    tooltipPosition,
    tooltipText: isMobile ? tooltipTextMobile : tooltipLabel,
    tooltipRef,
  };

  return (
    <>
      <DynamicTooltip {...tooltipProps} />
      <button
        onClick={handleCodeClick}
        type="button"
        className={styles.clipboardButton}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={clipRef}
        style={style}
      >
        {children}
      </button>
    </>
  );
};
ClipboardButton.displayName = "ClipboardButton";
