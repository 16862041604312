import Image from "next/legacy/image";
import styles from "./MarketingBlockAnimated.module.scss";

export function Heading({ title }: { title: string }) {
  return <h2 className={styles.articleHeading}>{title}</h2>;
}

export function Body({ description }: { description: string }) {
  return (
    <div
      className={styles.articleHtml}
      dangerouslySetInnerHTML={{ __html: description }}
      data-testid="br-html"
    />
  );
}

export function MarketingImage({
  src,
  alt,
}: {
  src: string;
  alt: string | undefined;
}) {
  return (
    <Image
      alt={alt}
      className={styles.articleImage}
      height={100}
      layout="responsive"
      objectFit="cover"
      src={src}
      width={100}
    />
  );
}
