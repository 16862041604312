export const HeaderCtaTagManager = (label: string, page: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: label,
    element_location: "Header",
    cms_component: "Product Inspiration",
  });
};
export const ModalCtaTagManager = (label: string, page: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: label,
    element_location: "Tile Modal",
    cms_component: "Product Inspiration",
  });
};
