import styles from "./HamburgerDialog.module.scss";
import clsx from "clsx";
import { DialogProps } from "./types";

export const HamburgerDialog = ({
  dialogOpen,
  open,
  children,
}: DialogProps) => {
  return (
    <>
      <div
        className={clsx(styles.backdrop, {
          [styles.backdropOpen]: open,
        })}
        onClick={dialogOpen}
        data-testid="backdrop"
        data-pniautomationid="HeaderHamburger"
      />

      <aside
        className={clsx(styles.dialogWrapper, {
          [styles.dialogWrapperOpen]: open,
        })}
        data-testid="wrapper"
      >
        <div className={styles.container}>
          <div className={styles.main}>{children}</div>
        </div>
      </aside>
    </>
  );
};
