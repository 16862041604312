import { BrRichTextContent } from "../BrRichTextContent";
import styles from "../MarketingLandingBanner/MarketingLandingBanner.module.scss";
import localStyles from "./MarketingHolidayBanner.module.scss";
import { v4 as uuidv4 } from "uuid";

import {
  CtaBlockProps,
  TextComponentProps,
  TitleComponentProps,
} from "./types";
import clsx from "clsx";
import { MarketingHolidayBannerTagManager } from "./MarketingHolidayBannerTagManager";
import { PageCheck } from "services/pageCheck";
import { useContext } from "react";
import { PathContext } from "state_management/Contexts";

export const TitleComponent = ({ title, isTablet }: TitleComponentProps) => {
  return (
    <h1 className={styles.contentTitle}>
      {title.map((titleChunk, index: number) => {
        const { text, textColorMobile, textColorDesktop } = titleChunk;
        return (
          <span
            key={index}
            style={{
              color: isTablet ? textColorDesktop : textColorMobile,
            }}
          >
            {text}
          </span>
        );
      })}
    </h1>
  );
};

export const TextComponent = ({
  textColor,
  isTablet,
  page,
  description,
}: TextComponentProps) => {
  return (
    <div
      className={localStyles.text}
      style={{
        color: `${textColor && isTablet ? textColor : "rgba(68, 68, 68,1)"}`,
      }}
    >
      <BrRichTextContent
        page={page!}
        key={uuidv4()}
        content={{ html: description?.value }}
      />
    </div>
  );
};

export const CtaBlockComponent = ({
  ctaBlock,
  isTablet,
  ctaColorSchema,
}: CtaBlockProps) => {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  // End GA tagging
  return (
    <div className={styles.contentCta}>
      {ctaBlock.map((ctaButton) => {
        const { ctaText, ctaLink } = ctaButton;
        return (
          <a
            key={uuidv4()}
            href={ctaLink}
            className={clsx(localStyles.contentButton, {
              [localStyles.contentButtonWhite]:
                isTablet && ctaColorSchema === "White",
              [localStyles.contentButtonBlack]:
                isTablet && ctaColorSchema === "Black",
            })}
            onClick={() => MarketingHolidayBannerTagManager(ctaText, tagPage)}
          >
            {ctaText}
          </a>
        );
      })}
    </div>
  );
};
