import { ReactElement } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, Document } from "@bloomreach/spa-sdk";
import { ScrollableTiles } from "./ScrollContainer";
import { PropsContainerDocument, PropsContainerProps } from "./types";

export function ScrollableValueTiles({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const { content: documentRef } = component.getModels<PropsContainerProps>();

  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? <div>Select Document</div> : null;
  }

  const containerData = document?.getData<PropsContainerDocument>();

  return <ScrollableTiles containerData={containerData} page={page} />;
}
