import { RotateProps } from "./types";

export const ArrowIcon = ({ size, rotateAngle, strokeWidth }: RotateProps) => {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`,
        transform: `rotate(${rotateAngle}deg)`,
      }}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="arrow-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0156 6.86719L8.01563 10.8672L4.01562 6.86719"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={strokeWidth || 1}
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
