import { MarketingFeaturesTile } from "./MarketingFeaturesTile";
import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { MarketingFeaturesTileProps } from "@/types/utils";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, caption, image, ctaButtons, position } =
    getContainerItemContent<MarketingFeaturesTileProps>(component, page) ?? {};
  const imgPosition = position?.selectionValues[0].key;

  let imageCleared =
    image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();
  return (
    <MarketingFeaturesTile
      title={title}
      content={caption}
      ctaButtons={ctaButtons}
      image={imageCleared}
      page={page}
      imagePosition={imgPosition}
    />
  );
};
