interface Color {
  red: number;
  green: number;
  blue: number;
  alpha: number;
}

interface GradientProps {
  color: Color;
  stop: number;
}

export const GradientSetter = (colorsList: GradientProps[]) => {
  let gradientColors: string = "";
  colorsList.forEach((color, index) => {
    const stop =
      index === 0
        ? "0%"
        : index === colorsList.length - 1
        ? "100%"
        : color.stop !== 0
        ? `${color.stop}%`
        : "";
    const gradientRgba = color.color;
    const R = gradientRgba.red;
    const G = gradientRgba.green;
    const B = gradientRgba.blue;
    const A = gradientRgba.alpha;

    index !== colorsList.length - 1
      ? (gradientColors =
          gradientColors + `rgba(${R},${G},${B},${A}) ${stop}, `)
      : (gradientColors = gradientColors + `rgba(${R},${G},${B},${A}) ${stop}`);
  });

  return gradientColors;
};
