import Image from "next/image";
import { useState } from "react";
import clsx from "clsx";
import styles from "./BentoBox.module.scss";
import {
  BottomLabelComponentProps,
  ContentProps,
  TopLabelComponentProps,
} from "./types";

export const FoilLabel = () => {
  return (
    <div className={styles.foilLabel} data-testid="tile-foil">
      <p>Foil Available</p>
    </div>
  );
};

export const TopLabel = ({ data }: TopLabelComponentProps) => {
  const { labelBackgroundColor, labelText, labelTextColor } = data;
  const { red, green, blue, alpha } = labelBackgroundColor;
  const {
    red: redText,
    green: greenText,
    blue: blueText,
    alpha: alphaText,
  } = labelTextColor;

  const backgroundStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    color: `rgba(${redText}, ${greenText}, ${blueText}, ${alphaText})`,
  };

  return (
    <div
      style={backgroundStyles}
      className={styles.topLabel}
      data-testid={"top-label"}
    >
      <p>{labelText}</p>
    </div>
  );
};

export const BottomLabel = ({
  data,
  setIsActiveContent,
  isActiveContent,
}: BottomLabelComponentProps) => {
  const [isActiveButton, setIsActiveButton] = useState(false);
  const handleButtonClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setIsActiveButton(!isActiveButton);
    setIsActiveContent(!isActiveContent);
  };

  return (
    <div className={styles.bottomLabel}>
      <div className={styles.bottomLabelLine}>
        <p className={styles.bottomLabelLinePrimary}>{data?.mobileLineOne}</p>
        <p className={styles.bottomLabelLineSecondary}>{data?.mobileLineTwo}</p>
        <p className={styles.bottomLabelLineDesktop}>{data?.desktopLine}</p>
      </div>

      <button
        onClick={handleButtonClick}
        className={clsx(styles.open, {
          [styles.openActive]: isActiveButton,
        })}
      >
        <div
          className={clsx(styles.openWhite, {
            [styles.openWhiteActive]: isActiveButton,
          })}
        >
          <Image
            src="/services/printing/static-pages/icons/modal-plus.svg"
            alt=""
            width={18}
            height={18}
            className={styles.openWhiteImg}
          />
        </div>
      </button>
    </div>
  );
};

export const Header = ({ ...props }: ContentProps) => {
  const { caption, header, topLabel, oldPrice } = props;
  return (
    <div className={styles.flipTop}>
      <h3>{header}</h3>
      {caption?.showLabel ? (
        <div
          data-testid="caption"
          className={clsx(styles.flipTopCaption, {
            [styles.flipTopCaptionRed]: oldPrice,
          })}
        >
          <p>{`${caption?.label ? caption.label : "Starting at: "} ${
            caption?.labelText
          }`}</p>
          {oldPrice ? <span>{oldPrice}</span> : null}
        </div>
      ) : null}
      {topLabel?.labelText && <TopLabel data={topLabel} />}
    </div>
  );
};
