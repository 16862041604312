import {
  useState,
  useRef,
  useEffect,
  MouseEvent,
  RefObject,
  ChangeEvent,
} from "react";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import styles from "./DropDown.module.scss";
import { DropDownFeaturesProps } from "@/types/utils";
import { DropDownFCProps } from "./types";

//  *
//  * DropDown
//  * - values and states set in parent component and passed back up for use with other child components
//  * @param {array} dropDownList - array to generate dropdown list items
//  * @param {string} currentSelection - selected state (current category, etc.)
//  * @param {function} setSelection - function used to update current selection (selected category, etc.)
//  * @param {boolean} dropDownSelectBtnToggle - switches default dropdown selection from BUTTONS to AHREF (default - BUTTONS)
//  *

export const DropDown = ({
  componentTitle,
  dropDownList,
  currentSelection,
  setSelection,
  dropDownSelectBtnToggle,
  windowSize,
}: DropDownFCProps) => {
  const [isActive, setActive] = useState<boolean>(false);
  const [isMobile, setMobile] = useState<boolean>(false);

  const toggleDropDownDHandler = () => {
    setActive(!isActive);
  };
  const selectHandler = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    setSelection(e.currentTarget.value);
    setActive(false);
  };

  // Create a ref that we add to the element for which we want to detect outside clicks
  const refOutside = useRef<HTMLDivElement>(null);
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(refOutside, () => setActive(false));
  // Hook
  function useOnClickOutside(
    ref: RefObject<HTMLDivElement>,
    handler: { (): void; (arg0: { target: any }): void }
  ) {
    useEffect(
      () => {
        const listener = (event: { target: any }) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  const changeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelection(e.currentTarget.value);
    setActive(false);
  };

  useEffect(() => {
    if (windowSize !== undefined && windowSize < 768) {
      return setMobile(true);
    } else {
      return setMobile(false);
    }
  }, [windowSize]);

  return (
    <nav
      aria-label={componentTitle}
      className={clsx(styles.selectDropdown, {
        [styles.dropDownOpen]: isActive,
      })}
      ref={refOutside}
      data-testid="Dropdown-aria-label"
    >
      {isMobile && dropDownSelectBtnToggle ? (
        <select
          value={currentSelection}
          onChange={(e) => {
            changeHandler(e);
          }}
          data-testid="Dropdown-mobile-select"
        >
          {dropDownList?.map((dropDownListItem: DropDownFeaturesProps) => {
            return (
              <option
                key={uuidv4()}
                value={dropDownListItem.title}
                data-testid="Dropdown-mobile-selectoption"
              >
                {dropDownListItem.title}
              </option>
            );
          })}
        </select>
      ) : (
        <>
          <button
            onClick={toggleDropDownDHandler}
            value={currentSelection}
            type="button"
            aria-haspopup="true"
            aria-expanded={isActive ? true : false}
            data-testid="Dropdown-toggle-btn"
          >
            {currentSelection}
            <span className="visuallyhidden" data-testid="Dropdown-SR-text">
              : selected. Show other {componentTitle}
            </span>
          </button>
          <ul role="menu" aria-hidden="false">
            {dropDownList?.map((dropDownListItem: DropDownFeaturesProps) => {
              return (
                <li key={uuidv4()}>
                  {dropDownSelectBtnToggle ? (
                    <button
                      role="menuitem"
                      value={dropDownListItem.title}
                      type="button"
                      onClick={(e) => {
                        selectHandler(e);
                      }} // Contains setSelection state based on currentTarget.value
                      data-testid="Dropdown-select-btn"
                    >
                      {dropDownListItem.title}
                    </button>
                  ) : (
                    <>
                      <a
                        href={dropDownListItem.link}
                        data-testid="Dropdown-select-link"
                      >
                        {dropDownListItem.title}
                      </a>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
      <div
        className="visuallyhidden"
        aria-live="polite"
        data-testid="Dropdown-aria-live"
      >
        {currentSelection} {componentTitle} selected
      </div>
    </nav>
  );
};
