import Image from "next/image";
import { BrRichTextContent } from "../BrRichTextContent";
import { ImageToUrl } from "services/imageToUrl";
import styles from "./Accordion.module.scss";
import { ProductItem } from "./types";

export const ItemProduct = ({ list, imageUrl, page }: ProductItem) => {
  return (
    <div className={styles.product}>
      <div className={styles.productImage}>
        <Image
          src={
            imageUrl
              ? imageUrl
              : "/static-pages/images/placeholders/img-placeholder.png"
          }
          alt=""
          fill
          className={styles.productImagePic}
          data-testid="main-image"
        />
      </div>
      <div className={styles.subheadingWrapper}>
        {list?.map((item, index: number) => {
          const { caption, header, icon } = item;
          const iconUrl = ImageToUrl(icon);
          return (
            <div key={index} className={styles.subheadingItem}>
              <div
                className={styles.subheadingHeader}
                data-testid="section-header"
              >
                {iconUrl && (
                  <div className={styles.subheadingImage}>
                    <Image
                      src={
                        iconUrl
                          ? iconUrl
                          : "/static-pages/images/placeholders/img-placeholder.png"
                      }
                      alt=""
                      fill
                    />
                  </div>
                )}
                <p>{header}</p>
              </div>
              <div className={styles.subheadingCaption}>
                {caption && (
                  <BrRichTextContent
                    page={page!}
                    content={{ html: caption.value }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
