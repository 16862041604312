import { Tile } from "./Utils";
import { ScrollContainerProps } from "./types";
import { CarouselContainer } from "../utils/NewCarouselContaner";
import clsx from "clsx";
import styles from "./ScrollableTiles.module.scss";

export const ScrollableTiles = ({
  containerData,
  page,
}: ScrollContainerProps) => {
  const { title, tilesList } = containerData;

  return (
    <article className={styles.component}>
      <h2
        className={clsx(styles.componentTitle, {
          [styles.componentTitlePlaceholder]: !title,
        })}
      >
        {title || "Add Component Title"}
      </h2>

      <CarouselContainer list={tilesList}>
        <Tile page={page} parentTitle={title} data={null} />
      </CarouselContainer>
    </article>
  );
};
