"use client";

import { ReactElement, useRef, useState, useEffect, Suspense } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { Document, ImageSet } from "@bloomreach/spa-sdk";
import styles from "./Navbar.module.scss";
import { MobileMenu } from "./Mobile";
import { Header } from "@/components/NewNavigation/Header";
import { SearchBar } from "@/components/SearchBar";
import { UtilityMenu } from "@/components/NewNavigation/UtilityMenu";
import { Dropdowns } from "./Dropdowns";
import {
  PrimaryDataRaw,
  PrimaryDropProps,
  SecondaryDataRaw,
  SecondaryDropProps,
} from "./Dropdowns/types";
import { QuickLinksProps, AnnouncementsProps, NavbarParameters } from "./types";
import { Announcements } from "../Announcements";
import sanitizeHtml from "sanitize-html";
import { useBoundingClientRect } from "hooks/useBoundingClientRect";
import { QuickLinks } from "../QuickLinks";
import { SeoTemplateSearch } from "@/types/utils";

export function Navbar({ component, page }: BrProps): ReactElement | null {
  const [navOpen, setNavOpen] = useState(false);
  const [level, setLevel] = useState("initial");

  const [domElement, setDomElement] = useState<HTMLElement | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const boundingRect = useBoundingClientRect(domElement);

  useEffect(() => {
    if (headerRef.current) setDomElement(headerRef.current);
  }, []);

  const dialogOpen: () => void = () => {
    setNavOpen(!navOpen);
    setTimeout(() => {
      setLevel("initial");
    }, 500);
  };

  if (!component || !page) {
    return null;
  }

  const isTemplatePage =
    page.getDocument<Document>()?.getData().contentType ===
    "brxsaas:pmSeoTemplateSelection";
  const pageDocument = isTemplatePage
    ? page.getDocument<Document>()?.getData<SeoTemplateSearch>()
    : undefined;

  const {
    quickLinks: quickLinksRef,
    primaryDrop: primaryDropRef,
    secondaryDrop: secondaryDropRef,
    announcementBar: announcementBarRef,
  } = component.getModels<NavbarParameters>();

  const announcementBarDoc =
    announcementBarRef && page.getContent<Document>(announcementBarRef);

  const quickLinksDoc =
    quickLinksRef && page.getContent<Document>(quickLinksRef);

  const { quickLinks } = quickLinksDoc?.getData<QuickLinksProps>() ?? {};

  // Retrieving Primary Dropdown Data
  const primaryDoc =
    primaryDropRef && page?.getContent<Document>(primaryDropRef);

  const {
    displayName: primaryDisplayName,
    id,
    staplesCaption,
    hideRik,
    dropdownItem,
  } = primaryDoc?.getData<PrimaryDataRaw>() ?? {};

  const { section } = announcementBarDoc?.getData<AnnouncementsProps>() ?? {};

  const announcementsData = section?.map((data, index) => {
    return {
      ...data,
      icon: page.getContent<ImageSet>(data.icon)?.getOriginal()?.getUrl() ?? "",
      content: {
        ...data.content,
        value: page.rewriteLinks(sanitizeHtml(data.content.value)),
      },
      location: index + 1,
      key: index,
    };
  });

  const quickLinksData = quickLinks?.map((data, index) => {
    return {
      ...data,
      icon: page.getContent<ImageSet>(data.icon)?.getOriginal()?.getUrl() ?? "",
      key: index,
    };
  });

  const primaryDropData: PrimaryDropProps = {
    displayName: primaryDisplayName ? primaryDisplayName : "",
    id: id ? id : "",
    staplesCaption: staplesCaption ? staplesCaption : "",
    hideStaplesRik: hideRik ? hideRik : false,
    dropdownItem: dropdownItem ? dropdownItem : null,
  };

  // Retrieving secondary Dropdown Data
  const secondaryDoc =
    secondaryDropRef && page?.getContent<Document>(secondaryDropRef);

  const { displayName, subMenus, marketingSections } =
    secondaryDoc?.getData<SecondaryDataRaw>() ?? {};

  const secondaryDropData: SecondaryDropProps = {
    displayName: displayName ? displayName : "",
    subMenus: subMenus ? subMenus : null,
    marketingSections: marketingSections ? marketingSections : null,
    id: id ? id : "",
  };

  const primaryData = {
    data: primaryDropData,
    position: "primary",
  };
  const secondaryData = {
    data: secondaryDropData,
    position: "secondary",
  };

  return (
    <div id="global-cms-header" ref={headerRef}>
      <Announcements section={announcementsData} />
      <div>
        <Header dialogOpen={dialogOpen} setOpen={setNavOpen}>
          <Suspense>
            <SearchBar badge={pageDocument?.heading} />
          </Suspense>
          <UtilityMenu />
        </Header>
      </div>

      <MobileMenu
        primaryData={primaryData}
        secondaryData={secondaryData}
        dialogOpen={dialogOpen}
        navOpen={navOpen}
        setLevel={setLevel}
        level={level}
      />
      <nav className={styles.container}>
        <ul className={styles.dropdowns}>
          <Dropdowns
            primaryData={primaryData}
            secondaryData={secondaryData}
            height={boundingRect?.height}
          />
        </ul>
        <QuickLinks quickLinks={quickLinksData} />
      </nav>
    </div>
  );
}
