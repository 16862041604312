import { useContext } from "react";
import styles from "./ValueProps.module.scss";
import Image from "next/legacy/image";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { ValuePropsTagManager } from "./ValuePropsTagManager";
import { MetaFlagsContext, PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { Headline } from "../utils/Headline";
import { ValueFCProps } from "./types";

export const ValueProps = ({
  title,
  cards,
  animated,
  lifted,
  shape,
  iconDimensions,
  id,
}: ValueFCProps) => {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const page = PageCheck(path);
  // End GA tagging

  const sectionTitle = title;
  const flags = useContext(MetaFlagsContext);

  return (
    <article
      className={clsx(styles.container, {
        [styles.containerNew]: flags?.flags && flags.flags.includes("new"),
      })}
      data-testid="value-props-container"
      id={id ? id : undefined}
    >
      {flags?.flags?.includes("new") ? (
        <Headline level="h2" style={["h2"]}>
          {title}
        </Headline>
      ) : (
        <h2
          className={clsx(styles.title, {
            [styles.titleUnderline]: title,
          })}
          data-testid="value-props-title"
        >
          {title}
        </h2>
      )}

      {!cards?.length && (
        <p className={styles.placeholder}>
          Value props banner. Press this container to add/edit content
        </p>
      )}
      <section
        data-testid="value-props-content"
        className={clsx(styles.content, {
          [styles.contentLifted]: lifted && shape !== "No Shape",
        })}
      >
        {cards?.map((card) => {
          const { icon, title, caption, ctaText, ctaLink, newTab } = card;

          return (
            <article
              key={uuidv4()}
              className={styles.card}
              onClick={() =>
                ValuePropsTagManager(
                  sectionTitle,
                  title + " - " + ctaText,
                  page
                )
              }
            >
              <figure
                data-testid="value-props-icon"
                // using inline style for dynamic dimensions
                style={{
                  width: ` ${iconDimensions}px`,
                  height: `${iconDimensions}px`,
                }}
                className={clsx(styles.cardImg, {
                  [styles.cardImgAnimated]: animated,
                  [styles.cardImgLifted]: lifted && shape !== "No Shape",
                  [styles.cardImgSquare]: shape === "Square",
                  [styles.cardImgRound]: shape === "Round",
                })}
              >
                <Image
                  src={icon}
                  width={iconDimensions}
                  height={iconDimensions}
                  alt={title}
                  layout="responsive"
                  className={styles.icon}
                ></Image>
              </figure>
              <section className={styles.cardText}>
                <h3 className={styles.cardTextTitle}>{title}</h3>
                <p className={styles.cardTextCaption}>{caption}</p>
                {ctaLink && (
                  <a
                    href={ctaLink}
                    className={styles.cardTextLink}
                    data-testid="value-props-cta"
                    target={newTab ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                  >
                    {ctaText}
                  </a>
                )}
              </section>
            </article>
          );
        })}
      </section>
    </article>
  );
};
