import { ProductTypeTile } from "./ProductTypeTile";
import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { ProductTypeTileProp, ProductTypeTilesProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, tiles } =
    getContainerItemContent<ProductTypeTilesProps>(component, page) ?? {};

  const tileArray: ProductTypeTileProp[] = [];

  tiles?.forEach((card) => {
    const { title, description, image, price, link } = card;

    const imageUrl =
      image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();

    tileArray.push({
      title: title,
      content: description,
      image: imageUrl,
      price: price,
      link: link,
    });
  });

  return <ProductTypeTile title={title} tiles={tileArray} page={page} />;
};
