export const HeroGalleryTagManager = (
  headline: string,
  ctaText: string,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: ctaText,
    element_location: headline.replace(/\r\n/g, ""),
    cms_component: "hero gallery",
  });
};
