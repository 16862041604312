import { useEffect, MutableRefObject } from "react";

export const useDialogPolyfill = (
  dialogRef: MutableRefObject<HTMLDialogElement | null>
) => {
  useEffect(() => {
    const dialog = dialogRef?.current;

    if (!dialog) return;

    // If the browser supports <dialog>, use the native behavior
    if (typeof dialog.showModal === "function") {
      return;
    }

    // Apply polyfill for browsers without <dialog> support
    if (!dialog.show) {
      const polyfillDialog = {
        show: function () {
          dialog.setAttribute("open", ""); // Mimic `open` attribute
          dialog.style.display = "block";
          dialog.style.position = "absolute";
          dialog.style.backgroundColor = "#fff";
          document.addEventListener("keydown", handleKeydown);
        },
        close: function () {
          dialog.removeAttribute("open");
          dialog.style.display = "none";
          document.removeEventListener("keydown", handleKeydown);
        },
      };

      function handleKeydown(e: KeyboardEvent) {
        if (e.key === "Escape") {
          polyfillDialog.close();
        }
      }

      dialog.show = polyfillDialog.show;
      dialog.close = polyfillDialog.close;

      return () => {
        document.removeEventListener("keydown", handleKeydown);
      };
    }
  }, [dialogRef]);
};
