/**
 * Maps the array of CSS module class keys to a space-separated string of compiled class names.
 *
 * @param {string[]} classNames - Keys to be located in the CSS module.
 * @param {Record<string, string>} styles - CSS Module with mapping of keys to compiled class names.
 *
 * @returns {string} Space-separated string of compiled class names.
 *
 * @example
 * getClassNames(['class1', 'class2'], styles); // Returns compiled class names: 'module_class1 module_class2'
 */
export function getClassNames(
  classNames: string[],
  styles: Record<string, string>
): string {
  return classNames
    .map((className) => styles[className])
    .filter(Boolean)
    .join(" ");
}
