import { ImageToUrl } from "services/imageToUrl";
import { ItemProduct } from "./ItemProduct";
import { ItemCategory } from "./ItemCategory";
import { Divider } from "../utils/Divider";
import { AccordionItem } from "./types";
import { ArrowIcon } from "../utils/misc";
import styles from "./Accordion.module.scss";
import clsx from "clsx";

export const ItemContainer = ({
  data,
  page,
  index,
  listLength,
  openHandler,
  open,
}: AccordionItem) => {
  const { id, header, capture, image, list, contentType } = data;
  const imageUrl = image && ImageToUrl(image);

  const categoryProps = {
    id: id,
    caption: capture,
    page: page,
  };
  const productProps = {
    id: id,
    imageUrl: imageUrl,
    list: list,
    page: page,
  };

  const itemStyle = {
    maxHeight: open ? "2000px" : "0",
    transition: open
      ? "max-height 0.5s ease, opacity 0.3s ease"
      : "max-height 0.1s ease, opacity 0.1s ease",
  };

  return (
    <li className={styles.item}>
      <div className={styles.itemContainer}>
        <button
          className={clsx(styles.itemHeader, {
            [styles.itemHeaderActive]: open,
          })}
          onClick={() => openHandler(index)}
        >
          <p className={styles.itemHeaderText}>{header}</p>
          <div className={styles.itemExpander}>
            <ArrowIcon
              size={30}
              rotateAngle={open ? -180 : 0}
              strokeWidth={1}
            />
          </div>
        </button>
        <div className={styles.itemContent}>
          <div
            className={clsx(styles.itemContentTile, {
              [styles.itemActive]: open,
            })}
            style={itemStyle}
            data-testid="item-content"
          >
            {contentType === "brxsaas:pmAccordionProduct" ? (
              <ItemProduct {...productProps} />
            ) : (
              <ItemCategory {...categoryProps} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.itemContentDivider}>
        {index !== listLength && (
          <Divider marginVar={0} color="rgb(231 231 231)" />
        )}
      </div>
    </li>
  );
};
