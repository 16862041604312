import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement, useState } from "react";
import { Document } from "@bloomreach/spa-sdk";
import { Section } from "@/components/utils/Section";
import { Headline } from "../utils/Headline";
import { PrintingSpecialsParameters, PrintingSpecialsDocument } from "./types";
import {
  HeadlineWrapper,
  ToggleView,
  List,
  Promo,
  Cta,
} from "./PrintingSpecials.helpers";

export function PrintingSpecials({
  component,
  page,
}: BrProps): ReactElement | null {
  const [isActive, setIsActive] = useState(false);

  if (!component || !page) {
    return null;
  }

  const { document: documentRef } =
    component.getModels<PrintingSpecialsParameters>();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? <div>Printing Specials Document</div> : null;
  }

  const { headline, pmPrintingSpecialsCard } =
    document.getData<PrintingSpecialsDocument>();

  if (!pmPrintingSpecialsCard) {
    return page.isPreview() ? (
      <div>Please select a Printing Specials Document</div>
    ) : null;
  }

  return (
    <Section ariaLabel={headline}>
      <HeadlineWrapper>
        <Headline level="h2" style={["h2"]}>
          {headline}
        </Headline>
        {pmPrintingSpecialsCard.length > 2 && (
          <ToggleView isActive={isActive} setIsActive={setIsActive} />
        )}
      </HeadlineWrapper>
      <List
        role="list"
        style={[
          "list",
          pmPrintingSpecialsCard.length >= 4
            ? "listCount4"
            : `listCount${pmPrintingSpecialsCard.length}`,
        ]}
      >
        {pmPrintingSpecialsCard.slice(0, 2).map((content, index) => {
          return (
            <List
              role="listitem"
              key={index}
              style={[
                "listItem",
                pmPrintingSpecialsCard.length === 1
                  ? "listItemSingleHeight"
                  : "",
                pmPrintingSpecialsCard.length === 2
                  ? "listItemDoubleHeight"
                  : "",
              ]}
            >
              <Promo
                description={content.description}
                offer={content.offer}
                supportingText={content.supportingText}
                loyaltyColorDescription={content.loyaltyColorDescription}
                loyaltyColorOffer={content.loyaltyColorOffer}
                loyaltyColorSupportingText={content.loyaltyColorSupportingText}
              />
              <Cta
                link={content.ctaLink}
                label={content.ctaLabel}
                loyaltyColorCta={content.loyaltyColorCta}
                gaHeadline={headline}
                gaTitle={content.description + " " + content.offer}
                newTab={content.newTab}
              />
            </List>
          );
        })}
        {pmPrintingSpecialsCard.length > 2 && (
          <List style={["listWrapper", isActive ? "listWrapperOpen" : ""]}>
            <List style={["listGroup"]}>
              {pmPrintingSpecialsCard.slice(2, 4).map((content, index) => {
                return (
                  <List role="listitem" key={index} style={["listItem"]}>
                    <Promo
                      description={content.description}
                      offer={content.offer}
                      supportingText={content.supportingText}
                      loyaltyColorDescription={content.loyaltyColorDescription}
                      loyaltyColorOffer={content.loyaltyColorOffer}
                      loyaltyColorSupportingText={
                        content.loyaltyColorSupportingText
                      }
                    />
                    <Cta
                      link={content.ctaLink}
                      label={content.ctaLabel}
                      loyaltyColorCta={content.loyaltyColorCta}
                      gaHeadline={headline}
                      gaTitle={content.description + " " + content.offer}
                      newTab={content.newTab}
                    />
                  </List>
                );
              })}
            </List>
          </List>
        )}
      </List>
    </Section>
  );
}
