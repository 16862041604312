import styles from "../MarketingLandingBanner/MarketingLandingBanner.module.scss";
import localStyles from "./MarketingHolidayBanner.module.scss";
import Image from "next/image";
import { useResize } from "hooks/useResize";
import { useEffect, useState } from "react";
import { HoldayBannerProps } from "./types";
import { ImageSet } from "@bloomreach/spa-sdk";
import { CtaBlockComponent, TextComponent, TitleComponent } from "./utils";

export const MarketingHolidayBanner = ({
  ctaBlock,
  ctaColor,
  description,
  image,
  textColor,
  title,
  page,
}: HoldayBannerProps) => {
  const [isTablet, setIsTablet] = useState(false);
  const windowSize = useResize();
  const ctaColorSchema = ctaColor.selectionValues[0].key;

  useEffect(() => {
    if (windowSize >= 768) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }, [windowSize]);

  let bgImage = image && page?.getContent<ImageSet>(image)?.getOriginal();

  const imageUrl = bgImage && bgImage?.getUrl();

  return (
    <section className={localStyles.container} data-testid="container">
      <picture className={localStyles.containerImage}>
        {imageUrl && (
          <Image
            src={imageUrl}
            fill
            priority
            alt=""
            className={localStyles.containerImagePic}
            unoptimized
            sizes="(max-width: 1440px) 100vw, 1440px"
          />
        )}
      </picture>

      <div className={styles.content}>
        <TitleComponent title={title} isTablet={isTablet} />
        <TextComponent
          textColor={textColor}
          isTablet={isTablet}
          page={page}
          description={description}
        />
        <CtaBlockComponent
          ctaBlock={ctaBlock}
          isTablet={isTablet}
          ctaColorSchema={ctaColorSchema}
        />
      </div>
    </section>
  );
};
