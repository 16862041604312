import { useEffect, useRef, useState } from "react";
import styles from "../Dropdowns.module.scss";
import { BackButton, SidebarHead } from "../../utils";
import { SubMenu } from "../../SubMenu";
import { MarketingSection } from "../../MarketingSection";
import { Divider } from "@/components/utils/Divider";
import { ProductContentProps } from "../types";
import clsx from "clsx";
import { tagManager } from "../../tagManager";

export const PrimaryContent = ({
  parentItem,
  productData,
  isDesktop,
  dialogOpen,
  setLevel,
  setItemId,
}: ProductContentProps) => {
  const [maxDescriptionHeight, setMaxDescriptionHeight] = useState<number>(0);
  const [isActive, setIsActive] = useState(false);

  const descriptionsRefs = useRef<HTMLInputElement[]>([]);

  const { subMenus, marketingSections, displayName, categoryLink } =
    productData;

  // Resetting content level
  const levelSetup = () => {
    setIsActive(false);
    if (setLevel && setItemId) {
      setLevel("top");
      setItemId("");
    }
  };

  useEffect(() => {
    setIsActive(true);
  }, []);

  // Adjusting columns' height
  useEffect(() => {
    if (!subMenus) return;
    const calculatedMaxHeight = descriptionsRefs.current.reduce(
      (acc: number, ref: HTMLElement | null) => {
        if (ref && ref.clientHeight > acc) {
          return ref.clientHeight;
        }
        return acc;
      },
      0
    );
    setMaxDescriptionHeight(calculatedMaxHeight);
  }, [subMenus]);

  return (
    <section
      className={clsx(styles.container, {
        [styles.containerHide]: !isActive,
      })}
    >
      {!isDesktop && dialogOpen && (
        <>
          <SidebarHead dialogOpen={dialogOpen} clickFunction={levelSetup}>
            <BackButton prompt="Shop products" />
          </SidebarHead>
          <section className={styles.containerHeader}>
            <p data-testid="name">{displayName}</p>
            {categoryLink && (
              <a
                href={categoryLink}
                onClick={() =>
                  tagManager(
                    `${parentItem} > ${productData.displayName} > 3rd Level > Shop All`
                  )
                }
              >
                Shop All
              </a>
            )}
          </section>
        </>
      )}

      <section className={styles.menusProduct}>
        {subMenus?.map((menu, index) => {
          const subMenuProps = {
            parentItem: `${parentItem} > ${productData.displayName} > 3rd Level`,
            maxHeight: maxDescriptionHeight,
            category: "product",
            isDesktop: isDesktop,
            menuData: menu,
            descRef: (el: HTMLInputElement) =>
              (descriptionsRefs.current[index] = el),
          };
          return <SubMenu key={index} {...subMenuProps} />;
        })}
      </section>
      <section className={styles.marketing}>
        {marketingSections
          ?.filter(
            (section, index) => subMenus && subMenus?.length + index + 1 <= 5
          )
          .map((section, index) => {
            const shouldRenderDivider =
              index !== marketingSections.length - 1 && !isDesktop;
            return (
              <div key={index}>
                <MarketingSection
                  {...section}
                  parentItem={`${parentItem} > ${productData.displayName} > Promo`}
                />
                {shouldRenderDivider && <Divider marginVar={0} />}
              </div>
            );
          })}
      </section>
    </section>
  );
};
