import styles from "./TopIcons.module.scss";
import Image from "next/legacy/image";
import { BrRichTextContent } from "@/components/BrRichTextContent";
import { TopIconSectionProps } from "./types";
import { ImageSet } from "@bloomreach/spa-sdk";

export const Section = ({
  data,
  iconDimensions,
  page,
}: TopIconSectionProps) => {
  const { icon, title, caption, ctaLink, ctaText } = data;

  const iconUrl =
    icon && page?.getContent<ImageSet>(icon)?.getOriginal()?.getUrl();

  return (
    <article className={styles.card} data-testid="value-step">
      <figure
        data-testid="value-props-icon"
        // using inline style for dynamic dimensions
        style={{
          width: ` ${iconDimensions}px`,
          height: `${iconDimensions}px`,
        }}
        className={styles.cardImg}
      >
        <Image
          src={iconUrl ?? ""}
          width={iconDimensions}
          height={iconDimensions}
          alt={title}
          layout="responsive"
          className={styles.icon}
        ></Image>
      </figure>
      <section className={styles.cardText}>
        <h3 className={styles.cardTextTitle} data-testid="value-step-title">
          {title}
        </h3>
        <div
          className={styles.cardTextCaption}
          data-testid="value-step-caption"
        >
          {caption && (
            <BrRichTextContent
              page={page!}
              content={{ html: caption?.value }}
            />
          )}
        </div>
        {ctaLink && (
          <a
            href={ctaLink}
            className={styles.cardTextLink}
            data-testid="value-props-cta"
          >
            {ctaText}
          </a>
        )}
      </section>
    </article>
  );
};
