import { ReactElement, useContext, MouseEvent } from "react";
import Image from "next/legacy/image";
import styles from "./HeroBanner.module.scss";
import { v4 as uuidv4 } from "uuid";
import { HeroTagManager } from "./HeroTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { imagePlaceholder } from "../../constants";
import animateScrollTo from "animated-scroll-to";
import { TileProp } from "./types";

export const HeroTile = ({
  title,
  caption,
  ctaTitle,
  ctaLink,
  imageUrl,
}: TileProp): ReactElement | null => {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const page = PageCheck(path);
  // End GA tagging

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    HeroTagManager(title + " - " + ctaTitle, page);
    const link =
      e.currentTarget.parentElement !== null
        ? e.currentTarget.parentElement.getAttribute("href")
        : "";
    if (link && link[0] === "#") {
      animateScrollTo(document.querySelector(link) as HTMLElement, {
        verticalOffset: window.innerWidth >= 768 ? -125 : -170,
      });
    }
  };
  return (
    <div className={styles.tileCard}>
      <figure>
        <Image
          className={styles.tileCardImage}
          src={imageUrl ? imageUrl : imagePlaceholder}
          alt={title}
          height={684}
          width={1600}
        ></Image>
      </figure>
      <div className={styles.tileCardContent}>
        <div className={styles.tileCardText}>
          <p data-testid="Card Title">{title ? title : "Card Title"}</p>
          <div className={styles.tileCardCaption}>
            {caption?.map((text: string) => (
              <p key={uuidv4()}>{text}</p>
            ))}
          </div>
        </div>
        {ctaLink !== "" && (
          <div className={styles.ctaButton}>
            <a href={ctaLink}>
              <button onClick={(e) => handleClick(e)}>
                {ctaTitle ? ctaTitle : "CTA"}
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
