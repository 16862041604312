import { WrapperProps } from "@/types/utils";
import styles from "./Section.module.scss";

export function Section({ children, ariaLabel }: WrapperProps) {
  return (
    <section aria-label={ariaLabel ?? undefined} className={styles.section}>
      {children}
    </section>
  );
}
