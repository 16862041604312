import { BrPageContext } from "@bloomreach/react-sdk";
import { ImageSet, Reference } from "@bloomreach/spa-sdk";
import { useContext } from "react";

export const ImageToUrl = (image: Reference) => {
  const page = useContext(BrPageContext);
  if (!image || !page) {
    return null;
  }
  const imageDestruct =
    image && page.getContent<ImageSet>(image)?.getOriginal();

  const imageUrl = imageDestruct?.getUrl();

  return imageUrl;
};
