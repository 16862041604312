import { useEffect, useState } from "react";
import styles from "./Dropdowns.module.scss";
import clsx from "clsx";
import { SecondaryDropdown } from "./SecondaryDropdown";
import { PrimaryDropdown } from "./PrimaryDropdown";
import { Chevron } from "../utils";
import { DropdownItemProps } from "./types";
import { useWindowHeight } from "hooks/useWindowHeight";

export const DropdownItem = ({
  isDesktop,
  levelSetup,
  data,
  isActive,
  onClick,
  height,
}: DropdownItemProps) => {
  const [componentHeight, setComponentHeight] = useState(0);

  const windowHeight = useWindowHeight();

  const handleItemClick = (position: string) => {
    onClick();

    if (!isDesktop && levelSetup) {
      levelSetup(position);
    }
  };

  useEffect(() => {
    if (height) {
      const saveArea = windowHeight - height;
      setComponentHeight(saveArea * 0.95);
    }
  }, [windowHeight, height]);

  return (
    <>
      <div
        className={clsx(styles.dropdown, {
          [styles.dropdownActive]: isActive,
        })}
        onClick={() => {
          handleItemClick(data.position);
        }}
      >
        <div
          data-pniautomationid={data.data?.displayName}
          className={clsx(styles.dropdownItem, {
            [styles.dropdownItemActive]: isActive,
          })}
        >
          <p>{data.data?.displayName}</p>
          <div className={styles.dropdownChevron}>
            <Chevron
              angle={isDesktop ? 0 : -90}
              size={16}
              isActive={isActive}
            />
          </div>
        </div>
        <div className={styles.dropdownBar} data-testid="dropdown-bar" />
      </div>
      {isDesktop && (
        <div
          className={clsx(styles.content, {
            [styles.contentActive]: isActive,
          })}
          style={{ top: `${height}px`, maxHeight: `${componentHeight}px` }}
        >
          {data.position === "primary" ? (
            <PrimaryDropdown data={data} isActive={isActive} />
          ) : (
            <SecondaryDropdown data={data} isDesktop={isDesktop} />
          )}
        </div>
      )}
    </>
  );
};
