import CartIcon from "@/public/static-pages/icons/navbar/utility-menu/cart.svg";
import styles from "./UtilityMenuCartBtn.module.scss";
import { utilityMenuTagManager } from "../utilityMenuHelpers/tagManager";
import axios from "axios";
import useSWR from "swr";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export function UtilityMenuCartBtn() {
  const cartUrl = "/services/printing/cart";
  const maxCountDisplay = 99;

  // Get number of items in cart
  const { data } = useSWR(
    `${process.env.NEXT_PUBLIC_SPLUS_BASE_URL}/services/printing/Cart/Api/GetCartCount`,
    fetcher
  );

  return (
    <a
      href={cartUrl}
      className={styles.cartButton}
      onClick={() => utilityMenuTagManager("Cart")}
      data-pniautomationid="HeaderMenuCartBtn"
    >
      <div className={styles.cartButtonBadge}>
        <CartIcon />
        {data > 0 && (
          <span className={styles.cartButtonCount} data-testid="cart-count">
            {data > maxCountDisplay ? `${maxCountDisplay}+` : data}
          </span>
        )}
      </div>
      <span className={styles.cartButtonLabel}>Cart</span>
    </a>
  );
}
