import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { HeroBanner } from "./HeroBanner";
import { HeroBannerCmsProps, TileProp } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, caption, bannerImageMob, bannerImageDesk, tile } =
    getContainerItemContent<HeroBannerCmsProps>(component, page) ?? {};
  const imageMob =
    bannerImageMob &&
    page?.getContent<ImageSet>(bannerImageMob)?.getOriginal()?.getUrl();
  const imageDesk =
    bannerImageDesk &&
    page?.getContent<ImageSet>(bannerImageDesk)?.getOriginal()?.getUrl();
  const tileArray: TileProp[] = [];
  tile?.forEach((card) => {
    const { title, caption, ctaTitle, ctaLink, tileImage } = card;
    const imageUrl =
      tileImage &&
      page?.getContent<ImageSet>(tileImage)?.getOriginal()?.getUrl();
    tileArray.push({
      title: title,
      caption: caption,
      ctaTitle: ctaTitle,
      ctaLink: ctaLink,
      imageUrl: imageUrl ?? "",
    });
  });

  return (
    <HeroBanner
      imgMobile={imageMob ?? ""}
      imgDesktop={imageDesk ?? ""}
      title={title}
      caption={caption}
      tileArray={tileArray}
    />
  );
};
