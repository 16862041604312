import { useContext } from "react";
import Image from "next/legacy/image";
import { v4 as uuidv4 } from "uuid";
import styles from "./MarketingBanner.module.scss";
import { BrRichTextContent } from "../BrRichTextContent";
import { BannerTagManager } from "./BannerTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { imagePlaceholder } from "../../constants";
import { MarketingBannerFCProps } from "./types";

// Functional Component
export const MarketingBanner = ({
  title,
  content,
  multiButtons,
  image,
  page,
  id,
}: MarketingBannerFCProps) => {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  // End GA tagging
  return (
    <div className={styles.container} id={id ? id : undefined}>
      <div className={styles.imgContainer}>
        <Image
          src={image ? image : imagePlaceholder}
          width={720}
          height={460}
          layout="responsive"
          objectFit="cover"
          alt={title}
          className={styles.imgContainerImg}
          data-testid="Mkt-banner-image"
        ></Image>
      </div>
      <div className={styles.contentContainer}>
        <h2
          className={styles.contentContainerTitle}
          data-testid="Mkt-banner-title"
        >
          {title}
        </h2>
        <div
          className={styles.contentContainerContent}
          data-testid="Mkt-banner-content"
        >
          {content && (
            <BrRichTextContent page={page!} content={{ html: content.value }} />
          )}
        </div>

        {multiButtons?.map((simpleCta) => {
          const { ctaText, ctaLink } = simpleCta;

          return (
            <a
              key={uuidv4()}
              href={ctaLink}
              className={styles.button}
              role="button"
              onClick={() => BannerTagManager(title + " - " + ctaText, tagPage)}
            >
              {ctaText}
            </a>
          );
        })}
      </div>
    </div>
  );
};
