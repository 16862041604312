import clsx from "clsx";
import { BlueCta } from "../utils";
import { Item } from "./Item";
import styles from "./SubMenu.module.scss";
import { SubMenuProps } from "./types";
import { linkAttributes } from "services/linkAttributes";
import { tagManager } from "../tagManager";
import { useContext } from "react";
import { RikContext } from "state_management/Contexts";

export const SubMenu = ({
  parentItem,
  maxHeight,
  category,
  isDesktop,
  menuData,
  descRef,
}: SubMenuProps) => {
  const {
    containerHeader,
    containerDescription,
    containerHeaderLink,
    headerNewTab,
    hideIcons,
    item,
    ctaLink,
    productCta,
    hideRik,
  } = menuData;
  const rik = useContext(RikContext);

  const isCta = ctaLink && ctaLink.title && ctaLink.address;
  const isProductCta = category === "product" && productCta?.ctaAddress;
  const productCtaDesktop = {
    address: productCta?.ctaAddress,
    title: productCta?.ctaDesktop,
    newTab: productCta?.newTab,
    parentItem: parentItem,
  };
  const serviceLinkData = {
    ...ctaLink,
    parentItem: parentItem,
  };

  const headerLinkProps = linkAttributes(containerHeaderLink, headerNewTab);

  return (
    <section className={styles.container}>
      <div className={styles.containerMain}>
        {containerHeader && ((rik && !hideRik) || !rik) && (
          <div
            className={clsx(styles.menus, {
              [styles.menusProduct]: category === "product",
            })}
            ref={descRef}
            style={{ minHeight: `${isDesktop ? maxHeight : 0}px` }}
          >
            {containerHeaderLink ? (
              <a
                {...headerLinkProps}
                className={clsx(styles.headerTitle, {
                  [styles.headerProduct]: category === "product",
                  [styles.headerProductDescription]: containerDescription,
                  [styles.headerProductLink]:
                    containerHeaderLink && !isDesktop && category === "product",
                })}
                onClick={() => tagManager(`${parentItem} > ${containerHeader}`)}
              >
                {containerHeader}
              </a>
            ) : (
              <p
                className={clsx(styles.headerTitle, {
                  [styles.headerProduct]: category === "product",
                  [styles.headerProductDescription]: containerDescription,
                  [styles.headerProductLink]:
                    containerHeaderLink && !isDesktop && category === "product",
                })}
              >
                {containerHeader}
              </p>
            )}
            {containerDescription && (
              <p className={styles.headerDescription}>{containerDescription}</p>
            )}
            {isCta && !isDesktop && category === "service" && (
              <BlueCta ctaLink={serviceLinkData} />
            )}
          </div>
        )}

        {isProductCta && !isDesktop && productCta.ctaMobile && (
          <div className={styles.ctaLink}>
            <a
              href={containerHeaderLink}
              target={ctaLink?.newTab ? "_blank" : "_self"}
              rel="noopener noreferrer"
              onClick={() =>
                tagManager(`${parentItem} > ${productCta.ctaMobile}`)
              }
            >
              {productCta.ctaMobile}
            </a>
          </div>
        )}

        {item.map((menuItem, index) => {
          return (
            <>
              {((rik && !menuItem.hideRik) || !rik) && (
                <Item
                  key={index}
                  parentItem={parentItem}
                  hideIcons={hideIcons}
                  data={menuItem}
                  category={category}
                />
              )}
            </>
          );
        })}
      </div>
      {isCta && isDesktop && category === "service" && (
        <BlueCta ctaLink={serviceLinkData} />
      )}
      {isProductCta && isDesktop && <BlueCta ctaLink={productCtaDesktop} />}
    </section>
  );
};
