import { BrRichTextContent } from "../BrRichTextContent";
import { CategoryItem } from "./types";
import styles from "./Accordion.module.scss";

export const ItemCategory = ({ caption, page }: CategoryItem) => {
  return (
    <div className={styles.category}>
      {caption && (
        <BrRichTextContent page={page!} content={{ html: caption.value }} />
      )}
    </div>
  );
};
