import { ReactElement } from "react";
import { MarketingHeroBanner } from "./MarketingHeroBanner";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { MarketingHeroBannerProps } from "@/types/utils";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }

  const {
    title,
    description,
    image,
    buttons,
    showMore,
    breadcrumbsDynamic,
    pmBreadCrumbs,
  } = getContainerItemContent<MarketingHeroBannerProps>(component, page) ?? {};

  let bgImage =
    image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();

  return (
    <MarketingHeroBanner
      breadcrumbs={breadcrumbsDynamic}
      title={title}
      description={description}
      buttons={buttons}
      image={bgImage ?? ""}
      page={page}
      showMore={showMore}
      manualBc={pmBreadCrumbs?.pmAddBreadcrumb}
    />
  );
};
