export const linkAttributes = (link: string, newTab?: boolean) => {
  const attributes: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    href: link,
  };

  if (newTab) {
    attributes.target = "_blank";
    attributes.rel = "noopener noreferrer";
  }

  return attributes;
};
