import { MetaDataProps, PairToLoadProps } from "./types";

export const bodyDataPair = (
  data: MetaDataProps,
  pairToLoad: PairToLoadProps,
  listData: PairToLoadProps[]
) => {
  return {
    name: "breadcrumbs",
    displayName: "Breadcrumbs",
    id: "breadcrumbs",
    path: "/content/documents/administration/print-marketing/breadcrumbs",
    keys: [
      ...data.data.keys,
      {
        name: pairToLoad.key,
        description: null,
      },
    ],
    valueSets: [
      {
        messages: [...listData, pairToLoad],
        name: "DEFAULT",
      },
    ],
    system: {
      createdBy: "azakharov@pnimedia.com",
      createdAt: "2023-01-03T21:42:47.995+01:00",
      updatedBy: "azakharov@pnimedia.com",
      updatedAt: "2023-01-03T21:44:34.485+01:00",
    },
  };
};
export const bodyDataTitle = (
  data: MetaDataProps,
  titleToLoad: PairToLoadProps[]
) => {
  return {
    name: "breadcrumbs",
    displayName: "Breadcrumbs",
    id: "breadcrumbs",
    path: "/content/documents/administration/print-marketing/breadcrumbs",
    keys: [...data.data.keys],
    valueSets: [
      {
        messages: titleToLoad,
        name: "DEFAULT",
      },
    ],
    system: {
      createdBy: "azakharov@pnimedia.com",
      createdAt: "2023-01-03T21:42:47.995+01:00",
      updatedBy: "azakharov@pnimedia.com",
      updatedAt: "2023-01-03T21:44:34.485+01:00",
    },
  };
};
