import styles from "./PickUpTiles.module.scss";
import Image from "next/legacy/image";
import clsx from "clsx";
import { useState } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { v4 as uuidv4 } from "uuid";
import { imagePlaceholder } from "../../constants";
import { PickUpTilesComponentProps } from "./types";

interface IndexInterface {
  [key: number]: boolean | undefined;
}

export const PickUpTiles = ({ tiles, page }: PickUpTilesComponentProps) => {
  const [index, setIndex] = useState({} as IndexInterface);

  const handleClick = (i: number) => {
    setIndex({
      ...index,
      [i]: !index[i],
    });
  };

  return (
    <section className={styles.containerTiles}>
      {tiles?.map((tile, i) => {
        return (
          <figure key={uuidv4()} className={styles.tiles}>
            <div className={styles.tilesImage}>
              <Image
                src={tile.icon ? tile.icon : imagePlaceholder}
                width={80}
                height={80}
                layout="responsive"
                objectFit="cover"
                alt={tile.title}
              ></Image>
            </div>
            <figcaption className={styles.tilesContent}>
              <p data-testid="Pick-Up-Tile-Title" className={styles.tilesTitle}>
                {tile.title}
              </p>
              <div
                data-testid="Pick-Up-Tile-Description"
                className={clsx(styles.tilesDescription, {
                  [styles.tilesDescriptionAppear]: index[i],
                })}
              >
                <BrRichTextContent
                  page={page}
                  content={{ html: tile.content?.value }}
                />
              </div>
              <button
                className={styles.tilesButton}
                onClick={() => handleClick(i)}
                aria-expanded={index[i]}
              >
                {index[i] ? "Show Less" : "Show More"}
              </button>
            </figcaption>
          </figure>
        );
      })}
    </section>
  );
};
