import { ReactElement } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, Document } from "@bloomreach/spa-sdk";
import { TilesContainer } from "./TilesContainer";
import { ComponentDocument, ComponentParameters } from "./types";

export function DesignCategories({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const { content: documentRef } = component.getModels<ComponentParameters>();

  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? <div>Select Document</div> : null;
  }

  const containerData = document?.getData<ComponentDocument>();

  return <TilesContainer page={page} data={containerData} />;
}
