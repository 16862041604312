import { useEffect, useState } from "react";

const focusableSelectors = [
  "a[href]",
  "area[href]",
  "input:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  '[tabindex]:not([tabindex="-1"])',
].join(", ");

export const useFocusableElements = (
  externalRef: React.RefObject<HTMLElement>
) => {
  const [firsFocusabletEl, setFirstFocusableEl] = useState<HTMLElement | null>(
    null
  );
  const [lastFocusabletEl, setLastFocusableEl] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    if (externalRef.current) {
      const focusableElements =
        externalRef.current.querySelectorAll<HTMLElement>(focusableSelectors);
      if (focusableElements.length === 1) {
        setFirstFocusableEl(focusableElements[0]);
        setLastFocusableEl(focusableElements[0]);
      } else {
        setFirstFocusableEl(focusableElements[0] || null);
        setLastFocusableEl(
          focusableElements[focusableElements.length - 1] || null
        );
      }
    }
  }, [externalRef]);

  return { firsFocusabletEl, lastFocusabletEl };
};
