import { AdobeDataProps, TileData } from "@/components/IndustryTiles/types";
import { useEffect, useState } from "react";

export const useAdobeData = (
  industryTiles: TileData[],
  categoryId: string | undefined,
  dispatch: any
) => {
  const [adobeData, setAdobeData] = useState<AdobeDataProps[] | null>(null);
  useEffect(() => {
    const newAdobeCat: AdobeDataProps[] = [];

    industryTiles?.forEach((card) => {
      const { title, id } = card;

      newAdobeCat.push({
        id: categoryId ? `${categoryId}/${id}` : id,
        value: title,
      });
    });

    if (JSON.stringify(newAdobeCat) !== JSON.stringify(adobeData)) {
      setAdobeData(newAdobeCat);

      if (newAdobeCat.length && dispatch) {
        dispatch({
          type: "ADOBE_DATA",
          payload: newAdobeCat,
        });
      }
    }
  }, [industryTiles, categoryId, dispatch, adobeData]);

  return adobeData;
};
