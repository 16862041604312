import React, { useEffect, useRef } from "react";
import { Page } from "@bloomreach/spa-sdk";
import sanitize from "sanitize-html";

export interface BrRichTextContentData {
  html: string;
}

export interface BrRichTextContentProps {
  page: Page;
  content: BrRichTextContentData;
  isNavigation?: boolean;
  parentItem?: string;
  tagName?: React.ElementType;
  className?: string;
}

function sanitizeRichContent(content: string): string {
  return sanitize(content, {
    allowedAttributes: {
      a: ["href", "name", "target", "title", "data-type", "rel"],
    },
  });
}

function processLinks(html: string): string {
  const linkRegex = /<a\s+(.*?)>/gi;
  return html.replace(linkRegex, (match, p1) => {
    if (p1.includes("data-tag-manager=")) {
      return match;
    }
    return match.replace("<a ", `<a data-tag-manager="true" `);
  });
}

const linkTagManager = (label: string, navigationLink: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "navigation_click",
    element_location: "Top Navigation",
    click_text: label,
    navigation_link: navigationLink,
  });
};

function handleLinkClick(
  event: Event,
  isNavigation?: boolean,
  parentItem?: string
) {
  event.preventDefault();
  const target = event.currentTarget as HTMLAnchorElement;
  const tagManager = target.getAttribute("data-tag-manager");
  if (isNavigation && tagManager) {
    const label = target.textContent || "link";
    const navigationLink = `${parentItem} > ${label}`;
    linkTagManager(label, navigationLink);
  }
  window.location.href = target.href;
}

export function BrRichTextContent(
  props: BrRichTextContentProps
): React.ReactElement | null {
  const { page, content, tagName, className, isNavigation, parentItem } = props;
  const Component = tagName || "div";
  const sanitizedContent = sanitizeRichContent(content.html);
  const processedContent = processLinks(sanitizedContent);

  const containerRef = useRef<HTMLDivElement>(null);

  // Activates TagManager if Rich Text link is clicked inside new navigation promo section
  useEffect(() => {
    const links = containerRef.current?.querySelectorAll("a[data-tag-manager]");

    const handleClick = (event: Event) =>
      handleLinkClick(event, isNavigation, parentItem);
    if (links && isNavigation && parentItem) {
      links.forEach((link) => {
        link.addEventListener("click", handleClick as EventListener);
      });
    }
    return () => {
      if (links) {
        links.forEach((link) => {
          link.removeEventListener("click", handleClick as EventListener);
        });
      }
    };
  }, [processedContent, isNavigation, parentItem]);

  return (
    <div ref={containerRef}>
      <Component
        className={className}
        data-testid="BrRichTextContent"
        dangerouslySetInnerHTML={{
          __html: page.rewriteLinks(processedContent),
        }}
      />
    </div>
  );
}
