import styles from "./ProductTypeTile.module.scss";
import Image from "next/legacy/image";
import { BrRichTextContent } from "../BrRichTextContent";
import { v4 as uuidv4 } from "uuid";
import { imagePlaceholder } from "../../constants";
import { ProductTypeTilesComponentProps } from "./types";

export const ProductTypeTile = ({
  title,
  tiles,
  page,
}: ProductTypeTilesComponentProps) => {
  return (
    <div className={styles.container}>
      <h3
        className={styles.title}
        data-testid="Product-Type-Tile-Container-Title"
      >
        {title}
      </h3>
      <ul className={styles.list}>
        {tiles?.map((tile) => {
          return (
            <li className={styles.listItem} key={uuidv4()}>
              <a href={tile.link} data-testid="Product-Type-Tile-Link">
                <figure className={styles.listItemFigure}>
                  <Image
                    src={tile.image ? tile.image : imagePlaceholder}
                    className={styles.tileCardImage}
                    alt=""
                    layout="fill"
                    objectFit="cover"
                  ></Image>
                  <figcaption className={styles.listItemCaption}>
                    <div className={styles.listItemCaptionContainer}>
                      <p
                        className={styles.listItemCaptionTitle}
                        data-testid="Product-Type-Tile-Title"
                      >
                        {tile.title}
                      </p>
                      <p className={styles.listItemCaptionSubTitle}>
                        Starting at{" "}
                        <span data-testid="Product-Type-Tile-Price">
                          {tile.price}
                        </span>
                      </p>
                    </div>
                    <div className={styles.listItemCaptionList}>
                      <BrRichTextContent
                        page={page}
                        content={{ html: tile.content?.value }}
                      />
                    </div>
                  </figcaption>
                </figure>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
