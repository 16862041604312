// USAGE:
// removeSpaces({
//  string: "Hello World"
// })
// returns hello_world
// WITH prefix PARAM:
// removeSpaces({
//   string: "Hello World",
//   prefix: "With Prefix"
// })
// returns with_prefix_hello_world
// WITH keepUppercase PARAM:
// removeSpaces({
//   string: "Hello World",
//  keepUppercase: true
// })
// returns HelloWorld

interface removeSpaces {
  string: string;
  prefix?: string;
  keepUppercase?: boolean;
}

export const removeSpaces = ({
  string,
  prefix,
  keepUppercase,
}: removeSpaces) => {
  const removeSpacesFromString = (string: string) => {
    if (keepUppercase) {
      return string.replace(/[^A-Z0-9]+/gi, "");
    }

    return string.toLowerCase().replace(/[^A-Z0-9]+/gi, "_");
  };

  if (prefix) {
    let returnPrefix = removeSpacesFromString(prefix);
    let returnString = removeSpacesFromString(string);

    return `${returnPrefix}_${returnString}`;
  } else {
    return removeSpacesFromString(string);
  }
};

// USAGE:
// extracts 'link' and 'label' from links in Markdown syntax
// extractLabelAndLink(
//  "[Duck Duck Go](https://duckduckgo.com)"
// )
// returns {
//  label: "Duck Duck Go",
//  link: "https://duckduckgo.com"
// }
export const extractLabelAndLink = (str: string) => {
  const label = str.substring(str.indexOf("[") + 1, str.lastIndexOf("]"));

  const link = str.substring(str.indexOf("(") + 1, str.lastIndexOf(")"));

  return { label: label, link: link };
};
