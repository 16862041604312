import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import styles from "./AgentBar.module.scss";
import { PniUsrObject } from "@/types/utils";

export const Index = () => {
  const [agentName, setAgentName] = useState("");

  useEffect(() => {
    const cookies = new Cookies();
    const user: PniUsrObject | string = cookies.get("PniUsrCtx");
    if (typeof user !== "string" && user?.an) setAgentName(user.an);
  }, []);

  return (
    <>{agentName && <div className={styles.bar}>Agent: {agentName}</div>}</>
  );
};
