import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

interface History {
  url: string;
  location: {
    pathname: string;
  };
  listen: () => void;
  state?: {
    url: string;
  };
}

const defaultBrowserHistory: History = {
  url: "/",
  location: { pathname: "" },
  listen: () => {},
};

let browserHistory = defaultBrowserHistory;
let appKey = process.env.NEXT_PUBLIC_APP_INSIGHTS_INSTRUMENTATION_KEY;

if (typeof window !== "undefined") {
  browserHistory = { ...defaultBrowserHistory, ...window.history };
  if (browserHistory.state) {
    browserHistory.location.pathname = browserHistory.state.url;
  }
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

if (typeof window !== "undefined" && process.env.NODE_ENV !== "development") {
  appInsights.loadAppInsights();
}

export { appInsights, reactPlugin };
