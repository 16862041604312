import styles from "./DocumentProductTiles.module.scss";
import Image from "next/legacy/image";
import { v4 as uuidv4 } from "uuid";
import { BrRichTextContent } from "../BrRichTextContent";
import { imagePlaceholder } from "../../constants";
import { DocumentPrintTilesComponentProps } from "./types";

export const DocumentProductTiles = ({
  title,
  tiles,
  page,
}: DocumentPrintTilesComponentProps) => {
  return (
    <section className={styles.container}>
      {title && <h2 className={styles.containerTitle}>{title}</h2>}

      <section className={styles.containerCards}>
        {tiles?.map((tile) => {
          return (
            <section key={uuidv4()} className={styles.card}>
              <figure>
                <div className={styles.imageContainer}>
                  <Image
                    src={tile.imageUrl ? tile.imageUrl : imagePlaceholder}
                    alt={tile.title}
                    layout="fill"
                    objectFit="cover"
                  ></Image>
                </div>
                <figcaption className={styles.figcaption}>
                  <h3
                    data-testid="Document-Product-Tile-Title"
                    className={styles.figcaptionTitle}
                  >
                    {tile.title}
                  </h3>
                  <p
                    data-testid="Document-Product-Tile-Subtitle"
                    className={styles.figcaptionSubHeading}
                  >
                    {tile.subtitle}
                  </p>
                  {tile.description && (
                    <div className={styles.figcaptionDescription}>
                      <BrRichTextContent
                        page={page}
                        content={{ html: tile.description?.value }}
                      />
                    </div>
                  )}
                </figcaption>
              </figure>

              <footer className={styles.cardFooter}>
                {tile.button.ctaText && tile.button.ctaLink && (
                  <a
                    href={tile.button.ctaLink}
                    className={styles.cardFooterButton}
                    data-testid="Document-Product-Tile-Button"
                  >
                    {tile.button.ctaText}
                  </a>
                )}
                {tile.link.ctaLink && tile.link.ctaText && (
                  <a
                    href={tile.link.ctaLink}
                    className={styles.cardFooterLink}
                    data-testid="Document-Product-Tile-Link"
                  >
                    {tile.link.ctaText}
                  </a>
                )}
              </footer>
            </section>
          );
        })}
      </section>
    </section>
  );
};
