import React, { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { MarketingBanner } from "./MarketingBanner";
import { AnchorId } from "@/types/utils";
import { MarketingBannerProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, content, hpBannerImg, multiButtons } =
    getContainerItemContent<MarketingBannerProps>(component, page) ?? {};

  const image =
    hpBannerImg &&
    page?.getContent<ImageSet>(hpBannerImg)?.getOriginal()?.getUrl();
  const { anchor } = component.getParameters<AnchorId>();

  return (
    <MarketingBanner
      title={title}
      content={content}
      multiButtons={multiButtons}
      image={image ?? ""}
      page={page}
      id={anchor}
    />
  );
};
