import { useRef, useState, RefObject, useContext } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { Page } from "@bloomreach/spa-sdk";
import Image from "next/image";
import { ImageToUrl } from "services/imageToUrl";
import styles from "./Utils.module.scss";
import clsx from "clsx";
import { TileProps } from "./types";
import { ScrollableTilesTagManager } from "./ScrollableTilesTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";

interface TileComponentProps {
  data: TileProps | null;
  page: Page;
  parentTitle: string;
}

export function Tile({ data, page, parentTitle }: TileComponentProps) {
  const [isActive, setIsActive] = useState(false);
  const [isActiveText, setIsActiveText] = useState(false);

  const containerRef = useRef<HTMLButtonElement>(null);
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  if (!data) {
    return null;
  }

  const {
    backgroundColor: { red, green, blue, alpha },
    content,
    image,
    label,
    textColor: { selectionValues },
    tileHeader,
  } = data;

  // GA tagging - setting the page type

  const handleActivation = () => {
    setIsActive(true);
    setTimeout(() => setIsActiveText(true), 300);
    ScrollableTilesTagManager(parentTitle, label, tagPage);
  };

  const handleDeactivation = () => {
    setIsActive(false);
    setIsActiveText(false);
  };

  const contentColor = selectionValues[0].key;
  const coverImageUrl = ImageToUrl(image);
  const labelIcon = "/services/printing/static-pages/icons/plus.svg";

  const coverStyles = {
    backgroundImage: `url(${coverImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const contentStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
  };

  return (
    <button
      className={clsx(styles.container, { [styles.containerActive]: isActive })}
      onClick={handleActivation}
      onMouseEnter={handleActivation}
      onMouseLeave={handleDeactivation}
      ref={containerRef}
      aria-label={label}
    >
      <div
        className={clsx(styles.content, {
          [styles.contentWhite]: contentColor === "White",
        })}
        style={contentStyles}
      >
        <div className={styles.contentHeader}>
          <p>{tileHeader}</p>
        </div>
        <div
          className={clsx(styles.contentText, {
            [styles.contentTextActive]: isActiveText,
          })}
        >
          {content && (
            <BrRichTextContent page={page!} content={{ html: content.value }} />
          )}
        </div>
      </div>
      <div className={styles.cover} style={coverStyles}>
        <div className={styles.label}>
          <span className={styles.labelText}>{label}</span>
          <div className={styles.labelIcon}>
            <Image src={labelIcon} alt="" fill />
          </div>
        </div>
      </div>
    </button>
  );
}
