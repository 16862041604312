import { ReactElement } from "react";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";

import { PopupLauncher } from "./PopupLauncher";
import { PopupLauncherProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { componentTitle, subTitle, footerText, pmPricingTableModal } =
    getContainerItemContent<PopupLauncherProps>(component, page) ?? {};

  return (
    <PopupLauncher
      componentTitle={componentTitle}
      subTitle={subTitle}
      footerText={footerText}
      pmPricingTableModal={pmPricingTableModal}
      page={page}
    />
  );
};
