import { useCallback, useContext, useEffect, useState } from "react";
import Script from "next/script";
import Head from "next/head";
import { PageCheck } from "../../services/pageCheck";
import { GenerateUniqueID } from "services/IdGenerator";
import { useDevice } from "hooks/useDevice";
import { AnalyticsTag } from "./analyticsTag";
import {
  CustomerEmailAddressContext,
  RewardsContext,
  RikContext,
  UserStatusContext,
  SessionIdContext,
  AdobeCategoriesContext,
  MetaFlagsContext,
} from "state_management/Contexts";
import {
  AnalyticsProps,
  PtoDataProps,
  PtoFormatProps,
  AdobeProps,
} from "./types";

export function AdobeAnalytics({ pagePath, productData }: AdobeProps) {
  // Contexts
  const email = useContext(CustomerEmailAddressContext);
  const rikMode = useContext(RikContext);
  const rewards = useContext(RewardsContext);
  const userStatus = useContext(UserStatusContext);
  const sessionId = useContext(SessionIdContext);
  const subcategories = useContext(AdobeCategoriesContext);
  const flags = useContext(MetaFlagsContext);

  // Using Product API call if page is Business Cards category page
  const isBusinessCards =
    flags?.flags && flags.flags.includes("business-cards");

  // States
  const [path, setPath] = useState("");
  const [excludedPagesCheck, setExcludedPagesCheck] = useState(false);
  const [scriptSrc, setScriptSrc] = useState<string | undefined>("");
  const [codeLoaded, setCodeLoaded] = useState(false);
  const [categoryNames, setCategoryNames] = useState([]);
  const [productType, setProductType] = useState("");
  const [analyticsObj, setAnalyticsObj] = useState("");
  const [ptoData, setPtoData] = useState<PtoFormatProps[] | null>(null);
  const [analyticsData, setAnalyticsData] = useState<AnalyticsProps | null>(
    null
  );

  // Services
  const device = useDevice();
  const page = PageCheck(pagePath);

  // Functions
  const status = (userStatus: string) =>
    userStatus === "LOGGEDOFF" || !userStatus ? "Guest" : "Registered";

  // Rewards check
  const isRewards = useCallback(() => {
    const regex = /\"([^\"]*)\"/g;
    const rewardsString = rewards?.split(",")[1];
    let match;
    let rewardsNumber = "";
    while ((match = regex.exec(rewardsString))) {
      rewardsNumber = match[1];
    }
    const rewardsBoolean = rewardsNumber !== "" ? true : false;
    return rewardsBoolean;
  }, [rewards]);

  // Parent Category check
  const parentCategory = useCallback(() => {
    const path = pagePath.path;
    const homePath = process.env.NEXT_PUBLIC_BASEPATH + "/";
    let category = "";
    if (path === homePath) category = "homepage";
    if (path.length > homePath.length) {
      category = path.substring(homePath.length, path.length - 1);
    }
    return category;
  }, [pagePath.path]);

  //Setting data
  useEffect(() => {
    const sessionIdCheck = sessionId ? sessionId : GenerateUniqueID();

    const capitalization = parentCategory().replace(/\b\w/g, (l) =>
      l.toUpperCase()
    ); // capitalize the first letter of each word

    const noDash = capitalization.replace(/-/g, " "); // replace dashes with spaces

    setAnalyticsData({
      userEmail: email,
      kioskMode: rikMode,
      userRewards: isRewards(),
      userStatus: status(userStatus),
      sessionId: sessionIdCheck,
      category: parentCategory(),
    });

    setProductType(noDash);
  }, [
    email,
    rikMode,
    rewards,
    userStatus,
    sessionId,
    pagePath,
    isRewards,
    parentCategory,
  ]);

  // Setting subcategories from the global state
  useEffect(() => {
    subcategories?.state.data.length &&
      setCategoryNames(
        isBusinessCards && ptoData ? ptoData : subcategories?.state.data
      );
  }, [subcategories, ptoData, isBusinessCards]);

  // Setting Adobe Tag
  useEffect(() => {
    if (analyticsData) {
      setAnalyticsObj(
        AnalyticsTag({
          analyticsData,
          device,
          productType,
          categoryNames,
          page,
        })
      );

      let intervalId = setInterval(() => {
        const tagComponent = document.getElementById("industry-layout");
        if (tagComponent) {
          if (categoryNames.length) {
            setCodeLoaded(true);
          }
          clearInterval(intervalId);
        } else if (!tagComponent) {
          setCodeLoaded(true);
        }
      }, 500);
    }
  }, [analyticsData, device, page, categoryNames, analyticsObj, productType]);

  // Contentloaded container for head injection
  useEffect(() => {
    if (codeLoaded) {
      const loadedDiv = document.createElement("div");
      loadedDiv.classList.add("contentloaded");
      document.body.appendChild(loadedDiv);
    }
  }, [codeLoaded]);

  // Detecting path
  useEffect(() => {
    if (window.location.hostname !== undefined)
      setPath(window.location.hostname.toLowerCase());
  }, []);

  useEffect(() => {
    setScriptSrc(process.env.NEXT_PUBLIC_SPLUS_ADOBE_ANALYTICS_URL);
  }, [path]);

  useEffect(() => {
    if (page === "marketing_landing_page") setExcludedPagesCheck(true);
  }, [pagePath, page]);

  // Product API data if page is Business Cards category page
  useEffect(() => {
    if (productData?.length && isBusinessCards) {
      let ptoItems: PtoFormatProps[] = [];
      const businessCardsData = productData.filter(
        (product) => product.key === "Business-Cards"
      );
      businessCardsData.length &&
        businessCardsData[0].children.map((item: PtoDataProps) => {
          const idInstance = item.key;
          const valueInstance = item.localization[0].name;
          const itemFormated = { id: idInstance, value: valueInstance };
          ptoItems.push(itemFormated);
        });
      setPtoData(ptoItems);
    }
  }, [productData, isBusinessCards]);

  return (
    <>
      {/* Adobe Launch Tag */}
      {path && scriptSrc && codeLoaded && (
        <Head>
          <script src={scriptSrc} async />
        </Head>
      )}
      {/* Adding analytics object */}
      {codeLoaded && !excludedPagesCheck && (
        <Script id="adobe-analytics">{analyticsObj}</Script>
      )}
      {/* Adding content loaded div after page and analytics object are finished loading */}
      {codeLoaded && !excludedPagesCheck && (
        <div className="contentloaded"></div>
      )}
    </>
  );
}
