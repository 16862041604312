export const VideoSpotlightTagManager = (
  headline: string | undefined,
  ctaText: string | undefined,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: ctaText,
    element_location: headline,
    cms_component: "video spotlight component",
  });
};

export const VideoClickTagManager = (page: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: "Play video",
    element_location: "Video",
    cms_component: "Video",
  });
};
