import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { BrRichTextContent } from "../BrRichTextContent";
import { useResize } from "hooks/useResize";
import { Table } from "../Table";
import { DropDown } from "../DropDown";
import FocusTrap from "focus-trap-react";
import styles from "./PricingGridModal.module.scss";
import clsx from "clsx";
import { useLockBodyScroll } from "hooks/useLockBodyScroll";
import { DropDownFeaturesProps } from "@/types/utils";
import { PricingGridModalFCProps } from "./types";

export const PricingGridModal = ({
  componentTitle,
  subTitle,
  footerText,
  pmPricingTableModal,
  page,
  showModal,
  setShowModal,
  btnRef,
}: PricingGridModalFCProps) => {
  // Convert to generic prop for use with global dropdown component
  const dropDownList: DropDownFeaturesProps[] | undefined = pmPricingTableModal;
  const [currentSelection, setSelection] = useState<string>("");
  const dropDownSelectBtnToggle: boolean = true; // true: BUTTON, false: AHREF

  let windowSize = useResize();
  useLockBodyScroll(showModal ?? false);

  const [windowHeight, setWindowHeight] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowHeight(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerHeight } = window;
    return { innerHeight };
  }

  const [divHeight, setDivHeight] = useState({ height: 0 });
  const refHeight = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refHeight.current) {
      setDivHeight({ height: refHeight.current.clientHeight });
    }
    divHeight.height; // Ensures the divHeight and windowHeight are fired when modal is open
  }, [divHeight.height, windowHeight.innerHeight]);

  // Set default product selection
  useEffect(() => {
    if (dropDownList?.length) {
      setSelection(dropDownList[0].title);
    }
  }, [dropDownList]);

  const [addClassName, setAddClassName] = useState<boolean | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const delay = (ms: number | undefined) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  // Add transition on modal open
  useEffect(() => {
    async function fetchData() {
      await delay(100);
      setAddClassName(true);
    }
    fetchData();

    if (modalRef.current) {
      modalRef.current.focus();
    }

    if (showModal !== undefined && showModal === true) {
      document.body.classList.add("modalOpen");
    }

    return () => {
      setAddClassName(null); // Cleanup function
    };
  }, [showModal]);

  // Add key listeners
  useEffect(() => {
    function keyListener(e: { keyCode: number }) {
      if (e.keyCode === 27) {
        closeModalHandler();
        btnRef.current?.focus();
      }
    }
    document.addEventListener("keydown", keyListener);

    return () => {
      document.addEventListener("keydown", keyListener);
    };
  });

  // Close modal
  const closeModalHandler = async () => {
    document.body.classList.remove("modalOpen");
    setAddClassName(false);
    await delay(100);
    setShowModal(false);
    btnRef.current?.focus();
  };

  return createPortal(
    <FocusTrap>
      <div
        className={clsx(styles.container, styles.modalEnter, {
          [styles.modalEnterActive]: addClassName === true,
          [styles.modalExitActive]: addClassName === false,
        })}
      >
        <div className="visuallyhidden" id="modalDescription">
          Beginning of Pricing Details dialog window. It begins with a heading
          called
          {componentTitle} {subTitle}. Escape key or close button will cancel
          and close the window.
        </div>
        <div className={styles.modalWrapper}>
          <div className={styles.modalWrapperCell}>
            <div
              className={clsx(styles.modal, {
                [styles.singleTable]: dropDownList?.length === 1,
                [styles.shortModal]:
                  divHeight.height > windowHeight.innerHeight,
              })}
              ref={refHeight}
              role="dialog"
              aria-labelledby="modalTitle"
              aria-describedby="modalDescription"
              aria-modal="true"
            >
              <h2
                className={styles.modalTitle}
                data-testid="PricingGrid-comp-title"
                ref={modalRef}
                tabIndex={0}
                id="modalTitle"
              >
                {componentTitle}
                <span className={styles.productName}>
                  <span className="visuallyhidden">:</span>
                  {subTitle}
                </span>
              </h2>
              <div className={styles.content}>
                {dropDownList?.length !== 1 ? (
                  <div className={styles.dropdownContainer}>
                    <DropDown
                      componentTitle={componentTitle}
                      dropDownList={dropDownList}
                      currentSelection={currentSelection}
                      setSelection={setSelection}
                      dropDownSelectBtnToggle={dropDownSelectBtnToggle}
                      windowSize={windowSize}
                    />

                    <p>Select a product to view pricing details</p>
                  </div>
                ) : null}
                {dropDownList
                  ?.filter(
                    (tab: { title: string }) => tab.title === currentSelection
                  )
                  .map(
                    (tab: { pmtableQtyPrice?: any; pmcolumnheader?: any }) => {
                      const { pmtableQtyPrice, pmcolumnheader } = tab;

                      return (
                        <div
                          key={uuidv4()}
                          className={clsx(styles.table, {
                            [styles.singleTable]: dropDownList?.length === 1,
                          })}
                        >
                          <Table
                            pmcolumnheader={pmcolumnheader}
                            pmtableQtyPrice={pmtableQtyPrice}
                            currentSelection={currentSelection}
                            windowSize={windowSize}
                            className={styles.popupTable}
                            className2={styles.popupRow}
                          />
                          <div className={styles.footer}>
                            <div className={styles.footerText}>
                              {footerText && (
                                <BrRichTextContent
                                  page={page!}
                                  content={{ html: footerText?.value }}
                                  data-testid="BrRichTextContent"
                                />
                              )}
                            </div>
                            <div className={styles.footerCTA}>
                              <button
                                className={styles.footerButton}
                                onClick={closeModalHandler}
                              >
                                Close
                                <span className="visuallyhidden">
                                  Pricing Details Popup
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>,
    document.body
  );
};
