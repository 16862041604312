import React, { ReactElement } from "react";
import { Disclaimer } from "./Disclaimer";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { DisclaimersContainer } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }

  const { title } =
    getContainerItemContent<DisclaimersContainer>(component, page) ?? {};

  return <Disclaimer title={title} page={page} />;
};
