export const CarouselTagManager = (
  heading: string | undefined,
  title: string | undefined,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    element_location: heading,
    click_text: title,
    cms_component: "Featured Products Carousel",
  });
};
