export function removeQueryParam(path: string, param: string): string {
  const [base, queryString] = path.split("?");
  const searchParams = new URLSearchParams(queryString);

  // Create an array of all parameter keys that start with the specified prefix
  const paramsToDelete: string[] = [];
  searchParams.forEach((value, key) => {
    if (key.startsWith(param)) {
      paramsToDelete.push(key);
    }
  });

  // Delete all parameters in the array from the searchParams object
  paramsToDelete.forEach((key) => {
    searchParams.delete(key);
  });

  // rebuild the path without the specified parameters and return it
  const updatedQueryString = searchParams.toString();
  const newPath = updatedQueryString ? `${base}?${updatedQueryString}` : base;
  return newPath;
}
