import { ReactElement } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { MarketingHolidayBanner } from "./MarketingHolidayBanner";

export const Index = ({ component, page }: BrProps): ReactElement | null => {
  if (!component || !page) {
    return null;
  }

  const { content: documentRef } = component?.getModels();
  const document = documentRef && page?.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? (
      <div>Marketing Holiday Banner Document</div>
    ) : null;
  }

  const { ctaBlock, ctaColor, description, image, textColor, title } =
    document?.getData();

  const bannerProps = {
    ctaBlock,
    ctaColor,
    description,
    image,
    textColor,
    title,
    page,
  };

  return <MarketingHolidayBanner {...bannerProps} />;
};
