import styles from "./Headline.module.scss";
import { getClassNames } from "services/getClassNames";
import clsx from "clsx";
import { HeadlineProps } from "./types";

export function Headline({ level, children, style }: HeadlineProps) {
  const Tag = level;
  const classNames = style ? getClassNames(style, styles) : undefined;

  return <Tag className={clsx(styles.headline, classNames)}>{children}</Tag>;
}
