import Image from "next/legacy/image";
import { useEffect, useState, useRef, useContext } from "react";
import { useResize } from "hooks/useResize";
import { imagePlaceholder, videoPlayIcon } from "../../constants";
import { VideoContainer } from "../utils/Video/VideoContainer";
import styles from "./VideoSpotlight.module.scss";
import clsx from "clsx";
import { VideoClickTagManager } from "./VideoSpotlightTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { MediaSectionProps } from "./types";

export const MediaSection = ({
  videoLink,
  image,
  wideImage,
  tilesRef,
}: MediaSectionProps) => {
  const [videoWidth, setVideoWidth] = useState<number | null>(null);
  const [videoHeight, setVideoHeight] = useState<number | null>(null);
  const [isValidVideo, setIsValidVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  // Hooks
  const size = useResize();

  // Refs
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const playRef = useRef<HTMLDivElement | null>(null);
  const imageOriginalHeightRef = useRef<string | null>(null);
  const containerOriginalHeightRef = useRef<string | null>(null);

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  // Store original height of "image" and "container"
  useEffect(() => {
    if (imageRef.current && !imageOriginalHeightRef.current) {
      const originalHeight = window
        .getComputedStyle(imageRef.current, null)
        .getPropertyValue("height");
      imageOriginalHeightRef.current = originalHeight;
    }

    if (
      containerRef.current &&
      !containerOriginalHeightRef.current &&
      size >= 1440
    ) {
      const originalContainerHeight =
        containerRef.current && containerRef.current.clientHeight;
      containerOriginalHeightRef.current = `${originalContainerHeight}px`;
    }
  }, [size]);

  // Resize "image" and adjust "play" dimensions on screen size change
  useEffect(() => {
    const containerWidth =
      containerRef.current && containerRef.current.clientWidth;

    if (imageRef.current && containerWidth) {
      if (size < 1440) {
        imageRef.current.style.height = `${containerWidth * 0.565}px`;
        setVideoHeight(imageRef.current?.clientHeight);
      } else if (size >= 1440 && isValidVideo) {
        imageRef.current.style.height = `${containerWidth * 0.66}px`;
        setVideoHeight(imageRef.current?.clientHeight);
      } else if (size >= 1440 && !isValidVideo) {
        imageRef.current.style.height = `${
          tilesRef.current && tilesRef.current.clientHeight
        }px`;
      } else if (imageOriginalHeightRef.current) {
        imageRef.current.style.height = imageOriginalHeightRef.current;
      }
    }

    if (playRef.current && imageRef.current) {
      playRef.current.style.width = `${Math.round(
        imageRef.current.clientWidth * 0.122
      )}px`;
      playRef.current.style.height = `${Math.round(
        imageRef.current.clientWidth * 0.122
      )}px`;
    }

    setVideoWidth(containerWidth);
  }, [size, isValidVideo, tilesRef]);

  // Check if YouTube Id has a correct format
  useEffect(() => {
    const regex = /^[a-zA-Z0-9_-]{11}$/;
    videoLink && setIsValidVideo(regex.test(videoLink));
  }, [videoLink]);

  // Hides Image component when "play" clicked
  const handleImageClick = () => {
    if (isValidVideo) {
      VideoClickTagManager(tagPage);
      setIsPlaying(true);
      setTimeout(() => {
        setIsHidden(true);
      }, 1500);
    }
  };
  const handlePlayButton = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      handleImageClick();
    }
  };

  return (
    <div
      className={styles.mediaWrapper}
      ref={containerRef}
      onClick={isValidVideo ? handleImageClick : undefined}
      data-testid="MediaThumbnail"
    >
      <div
        className={clsx(styles.mediaThumbnail, {
          [styles.mediaPointer]: isValidVideo,
        })}
        ref={imageRef}
      >
        <div
          className={clsx(styles.mediaVideo, {
            [styles.mediaVideoHide]: !isPlaying && isValidVideo && image,
          })}
        >
          {isValidVideo && (
            <VideoContainer
              videoId={videoLink}
              width={videoWidth}
              height={videoHeight}
              autoplay={isPlaying}
            />
          )}
        </div>
        {isValidVideo && !image && !isPlaying && (
          // GA - container for tracking clicks on video when there is no image:
          <div className={styles.mediaVideoClickContainer}></div>
        )}
        {isValidVideo && image && !isPlaying && (
          <figure
            className={styles.play}
            ref={playRef}
            tabIndex={0}
            onKeyDown={(e) => {
              handlePlayButton(e);
            }}
            aria-label="Video"
          >
            <Image src={videoPlayIcon} alt="" layout="fill" objectFit="cover" />
          </figure>
        )}
        {!isHidden && !(isValidVideo && !image) && (
          <figure className={styles.mediaImage}>
            <Image
              src={
                size >= 1440 && !isValidVideo
                  ? wideImage
                    ? wideImage
                    : imagePlaceholder
                  : image
                  ? image
                  : imagePlaceholder
              }
              alt=""
              layout="fill"
              objectFit="cover"
              className={`${styles.mediaPic} ${isPlaying && styles.hidden}`}
            />
          </figure>
        )}
      </div>
    </div>
  );
};
