import { useContext, useEffect } from "react";
import { PathContext } from "state_management/Contexts";
import { Breadcrumbs } from "./Breadcrumbs";
import { useBreadcrumbExternal } from "hooks/useBreadcrumbExternal";
import { BrComponentContext, BrPageContext } from "@bloomreach/react-sdk";

export const Index = ({ banner, manualBreadcrumbs }: any) => {
  const path = useContext(PathContext);
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  const breadcrumbs = useBreadcrumbExternal(
    banner,
    component,
    manualBreadcrumbs,
    page
  );

  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbs}
      search={path.search}
      isBanner={banner}
    />
  );
};
