import { useEffect, useRef } from "react";

type Callback = () => void;

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  callback: Callback
) => {
  const containerRef = useRef<T | null>(null);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        callback();
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [callback]);

  return containerRef;
};
