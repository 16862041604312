import { useState } from "react";
import { AnnouncementsProps } from "../Navbar/types";
import {
  Aside,
  List,
  ListItem,
  Expandable,
  Switch,
} from "./Announcements.helpers";

export function Announcements({ section }: AnnouncementsProps) {
  const [isActive, setIsActive] = useState(false);

  if (!section) return null;

  return (
    <Aside ariaLabel="Announcements">
      {section.length > 1 && (
        <Switch
          isActive={isActive}
          setIsActive={setIsActive}
          count={section.length}
        />
      )}
      <List>
        {section.slice(0, 1).map((data) => (
          <ListItem key={data.key} section={data} />
        ))}
        {section.length > 1 && (
          <Expandable isExpanded={isActive} flexGrow={section.length - 1}>
            {section.slice(1, 3).map((data) => (
              <ListItem key={data.key} section={data} />
            ))}
          </Expandable>
        )}
      </List>
    </Aside>
  );
}
