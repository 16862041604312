import { MenuItem } from "@bloomreach/spa-sdk";

// Function checks RikMode
export const rikSelect = (data: MenuItem[], rikMode: boolean) => {
  let rikItemList = data.filter((item: MenuItem) => {
    const params = item.getParameters();
    const itemRikMode: string = params.isRikMode as string;
    return itemRikMode?.toLowerCase() === "yes";
  });
  let itemList = rikMode ? rikItemList : data;
  return itemList;
};
