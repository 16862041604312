export const DesignCategoriesTagManager = (
  categoryTitle: string,
  label: string,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: label,
    element_location: categoryTitle,
    cms_component: "Design Categories Component",
  });
};
