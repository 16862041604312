import { useResize } from "hooks/useResize";
import { useCallback, useEffect, useState } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { Tile } from "./Tile";
import styles from "./DesignCategories.module.scss";
import { TileDocumentProps, TilesContainerProps } from "./types";
import { Document } from "@bloomreach/spa-sdk";

export const TilesContainer = ({ page, data }: TilesContainerProps) => {
  const { categoryTitle, categoryCaption, tiles, expandText, showRows } = data;
  const [visibleTiles, setVisibleTiles] = useState(0);
  const size = useResize();

  const largeDesktopTileCount = () => {
    if (showRows === 0) return 6;
    const showTiles = showRows * 6;
    if (showTiles <= tiles.length) {
      return showTiles;
    } else {
      return tiles.length;
    }
  };

  // Add tiles on button press
  const updateVisibleTiles = useCallback(
    (reset?: boolean) => {
      const incrementMobile = reset ? 4 : 2;
      const incrementTablet = 3;
      const incrementDesktopSmall = 4;
      const incrementDesktopLarge = largeDesktopTileCount;

      if (reset) setVisibleTiles(0);

      size < 601
        ? setVisibleTiles((prev) => prev + incrementMobile)
        : size >= 601 && size < 821
        ? setVisibleTiles((prev) => prev + incrementTablet)
        : size >= 821 && size < 1025
        ? setVisibleTiles((prev) => prev + incrementDesktopSmall)
        : setVisibleTiles((prev) => prev + incrementDesktopLarge());
    },
    [size]
  );

  useEffect(() => {
    updateVisibleTiles(true);
  }, [size, updateVisibleTiles]);

  const visibleTilesHandle = () => {
    updateVisibleTiles();
  };

  return (
    <article className={styles.container} data-testid="container">
      <header className={styles.header}>
        {categoryTitle && <h2>{categoryTitle}</h2>}
        <div>
          {categoryCaption && (
            <BrRichTextContent
              page={page!}
              content={{ html: categoryCaption.value }}
            />
          )}
        </div>
      </header>
      <section className={styles.tiles}>
        <ul className={styles.tilesContainer}>
          {tiles
            .filter((_, index) => index + 1 <= visibleTiles)
            .map((tile, index) => {
              const document = tile && page.getContent<Document>(tile);
              if (!document) {
                return null;
              }
              const data = document?.getData<TileDocumentProps>();

              return (
                <li key={index} className={styles.tile}>
                  <Tile data={data} categoryTitle={categoryTitle ?? ""} />
                </li>
              );
            })}
        </ul>

        {tiles.length > visibleTiles && (
          <button onClick={visibleTilesHandle} className={styles.button}>
            {expandText ? expandText : "Show more"}
          </button>
        )}
      </section>
    </article>
  );
};
