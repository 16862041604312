import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from "./Dropdowns.module.scss";
import { Divider } from "@/components/utils/Divider";
import { SubMenu } from "../SubMenu";
import { MarketingSection } from "../MarketingSection";
import { BackButton, SidebarHead } from "../utils";
import { SecondaryDropdownProps } from "./types";

export const SecondaryDropdown = ({
  dialogOpen,
  data,
  setLevel,
  isDesktop,
}: SecondaryDropdownProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [maxDescriptionHeight, setMaxDescriptionHeight] = useState<number>(0);

  const descriptionsRefs = useRef<HTMLInputElement[]>([]);

  const subMenus = data.data.subMenus;
  const marketingSections = data.data.marketingSections;

  const levelSetup = () => {
    setIsMounted(false);
    setLevel && setLevel("initial");
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const calculatedMaxHeight = descriptionsRefs.current.reduce(
      (acc: number, ref: HTMLElement | null) => {
        if (ref && ref.clientHeight > acc) {
          return ref.clientHeight;
        }
        return acc;
      },
      0
    );
    setMaxDescriptionHeight(calculatedMaxHeight);
  }, [subMenus]);

  return (
    <section
      className={clsx(styles.container, {
        [styles.containerActive]: isMounted,
      })}
      data-testid="service-dropdown"
    >
      {!isDesktop && dialogOpen && (
        <SidebarHead dialogOpen={dialogOpen} clickFunction={levelSetup}>
          <BackButton />
        </SidebarHead>
      )}

      <div className={styles.menus}>
        {subMenus?.map((menu, index) => {
          return (
            <SubMenu
              parentItem={`${data.data.displayName} > 2nd Level`}
              menuData={menu}
              key={index}
              descRef={(el: HTMLInputElement) =>
                (descriptionsRefs.current[index] = el)
              }
              maxHeight={maxDescriptionHeight}
              category="service"
              isDesktop={isDesktop}
            />
          );
        })}
      </div>
      <div className={styles.marketing}>
        {marketingSections
          ?.filter(
            (section, index) => subMenus && subMenus?.length + index + 1 <= 6
          )
          .map((section, index) => {
            const shouldRenderDivider =
              index !== marketingSections.length - 1 && !isDesktop;
            return (
              <div key={index}>
                <MarketingSection
                  {...section}
                  parentItem={`${data.data.displayName} > Promo`}
                />
                {shouldRenderDivider && <Divider marginVar={0} />}
              </div>
            );
          })}
      </div>
    </section>
  );
};
