import { useState, useEffect, useRef } from "react";

interface BoundingRect {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
}

export const useBoundingClientRect = (
  domElement: HTMLElement | null
): BoundingRect | null => {
  const [boundingRect, setBoundingRect] = useState<BoundingRect | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    if (domElement) {
      setBoundingRect(domElement.getBoundingClientRect());

      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }

      resizeObserver.current = new ResizeObserver(() => {
        setBoundingRect(domElement.getBoundingClientRect());
      });

      resizeObserver.current.observe(domElement);
    }

    const handleResize = () => {
      if (domElement) {
        setBoundingRect(domElement.getBoundingClientRect());
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [domElement]);

  return boundingRect;
};
