import { Guid } from "guid-typescript";
import { CookieSetOptions } from "universal-cookie";

export interface IMemberId {
  cookieName: string;
  cookieValue: string;
  options: CookieSetOptions;
}

export interface ICookieValue {
  anonymousUserId: string;
  guestUserId: string;
  registeredUserId: string;
  isLoggedIn: number;
  dateToday: string;
}

export interface ISessionTracker {
  Create: () => IMemberId;
  IsValid: (a: string) => boolean;
  UserStatusUpdate: (memberId: string, isUserLogin: boolean) => IMemberId;
}

type CookieValueDefaults = Pick<
  ICookieValue,
  "guestUserId" | "registeredUserId" | "isLoggedIn"
>;

const defaultCookieValue: CookieValueDefaults = {
  guestUserId: "",
  registeredUserId: "",
  isLoggedIn: 0,
};

type cookiesSetOptionsDefaults = Pick<CookieSetOptions, "path" | "secure">;

const defaultSetOptions: cookiesSetOptionsDefaults = {
  path: "/services/printing/",
  secure: true,
};

export const sessionTracker: ISessionTracker = {
  Create: () => {
    let cValue: ICookieValue = {
      ...defaultCookieValue,
      anonymousUserId: Guid.create().toString(),
      dateToday: getDateToday(),
    };
    let memberid = generateMemberId(cValue);
    return memberid;
  },

  IsValid: (a: string) => {
    if (!a) return false;
    if (a.length === 0) return false;
    let memberIdArray = a.split("|");
    if (memberIdArray.length !== 5) return false;
    if (memberIdArray[0] === "") return false;
    return true;
  },

  UserStatusUpdate: (memberId: string, isUserLogin: boolean) => {
    let cValue = {} as ICookieValue;
    let memberIdArray = memberId.split("|");
    cValue.anonymousUserId = memberIdArray[0];
    cValue.guestUserId = memberIdArray[1];
    cValue.registeredUserId = memberIdArray[2];
    cValue.isLoggedIn = isUserLogin ? 1 : 0;
    cValue.dateToday = getDateToday();
    let memberid = generateMemberId(cValue);
    return memberid;
  },
};

function getDateToday(): string {
  const now = new Date();
  const year = now.toLocaleString("default", { year: "numeric" });
  const month = now.toLocaleString("default", { month: "2-digit" });
  const day = now.toLocaleString("default", { day: "2-digit" });
  const dateString = year.concat("-", month, "-", day);
  return dateString;
}

function getDateToExpire(): Date {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  return new Date(year + 1, month, day);
}

function generateMemberId(cValue: ICookieValue): IMemberId {
  const memberIdValue = cValue.anonymousUserId
    .concat("|")
    .concat(cValue.guestUserId)
    .concat("|")
    .concat(cValue.registeredUserId)
    .concat("|")
    .concat(cValue.isLoggedIn.toString())
    .concat("|")
    .concat(cValue.dateToday);

  const cookieOptons: CookieSetOptions = {
    ...defaultSetOptions,
    expires: getDateToExpire(),
    domain: window.location.hostname,
  };

  const memberid: IMemberId = {
    cookieName: "member-id",
    cookieValue: memberIdValue,
    options: cookieOptons,
  };
  return memberid;
}
