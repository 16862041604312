import React from "react";

export let AnalyticsTag = ({
  analyticsData,
  device,
  productType,
  categoryNames,
  page,
}: any) => {
  const { userEmail, kioskMode, userRewards, userStatus, sessionId, category } =
    analyticsData;
  return `var Analytics = {
        "global": {
            "version": {
                "spec": "1.0.0",
                "dlospec": "1.0.0"        
                },
            "visitor": {
                "language": "en-US",
                "vis_status": "${userStatus}",           
                "email":"${
                  userEmail === undefined ? "" : userEmail
                }" ,           
                "platformtype": "Old",
                "rewards": "${userRewards}",
                "rewards_tier": ""      
                },
            "visit": {
                "server": "",
                "domain": "${window?.location.hostname.toLowerCase()}",           
                "appserver": "",
                "appserverid": "",
                "appversion": "",
                "interfaceType": "${device}",          
                "device": "${device}",          
                "kioskmode": "${kioskMode ? "Yes" : "No"}",           
                "jsessionid": "${sessionId}",          
                "zipcode": "",
                "AppID": "No App Server ID in Cookie",
                "cs": {}
            },
            "cart": {}
        },
        ${
          category !== "homepage"
            ? `    
        "tracking":{
          "browse": {
              "version": "1.0",
              "tier": "sc",
              "pagehierarchy": {
                  "sc": "${category}",
                  "cg": []
              }
          },
          "productTypeCategory": "${productType}",
          "categories":${JSON.stringify(categoryNames)},
      },`
            : ""
        }
        "traffic": {
            "pagename": "${
              page === "homepage"
                ? "Home"
                : page === "product_category_page"
                ? "Super Category"
                : ""
            }",       
            "pagetype": "${
              page === "homepage"
                ? "Home"
                : page === "product_category_page"
                ? "Super Category"
                : ""
            }",
            "thirdparty": false    
            }
      }`;
};
