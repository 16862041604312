import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import {
  CTALink,
  CTADescription,
  CTAPricingLink,
} from "./WaysToDesignCTAUtils";
import { ReactElement } from "react";
import { v4 as uuidv4 } from "uuid";
import browseIcon from "../../public/static-pages/icons/design-cta/browse-designs@3x.png";
import clsx from "clsx";
import createIcon from "../../public/static-pages/icons/design-cta/create-your-own@3x.png";
import styles from "./WaysToDesignCTA.module.scss";
import uploadIcon from "../../public/static-pages/icons/design-cta/upload-design@3x.png";
import { WaysToDesignCTAProps } from "./types";

export function WaysToDesignCTA({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const {
    cta1Title,
    cta1Link,
    cta1Description,
    cta1HideOnMobile,
    cta2Title,
    cta2Link,
    cta2Description,
    cta2HideOnMobile,
    cta3Title,
    cta3Link,
    cta3Description,
    cta3HideOnMobile,
    linkToPricingTable,
  } = getContainerItemContent<WaysToDesignCTAProps>(component, page) ?? {};

  const ctaData = [
    {
      title: cta1Title,
      link: cta1Link,
      description: cta1Description,
      hideOnMobile: cta1HideOnMobile,
      image: uploadIcon,
    },
    {
      title: cta2Title,
      link: cta2Link,
      description: cta2Description,
      hideOnMobile: cta2HideOnMobile,
      image: browseIcon,
    },
    {
      title: cta3Title,
      link: cta3Link,
      description: cta3Description,
      hideOnMobile: cta3HideOnMobile,
      image: createIcon,
    },
  ];

  return (
    <article className={styles.article}>
      {ctaData.map((cta) => {
        return (
          <div
            className={clsx(styles.articleContent, {
              [styles.articleContentHide]: cta.hideOnMobile,
            })}
            key={uuidv4()}
          >
            {cta.link && cta.title && (
              <CTALink link={cta.link} image={cta.image} title={cta.title} />
            )}
            {cta.description && (
              <CTADescription
                description={page.rewriteLinks(cta.description.value)}
              />
            )}
          </div>
        );
      })}
      {linkToPricingTable && <CTAPricingLink />}
    </article>
  );
}
