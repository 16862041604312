import { ReactElement } from "react";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { Masonry } from "./Masonry";
import { AnchorId } from "@/types/utils";
import { MasonryProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { title, tile } =
    getContainerItemContent<MasonryProps>(component, page) ?? {};

  const { anchor } = component.getParameters<AnchorId>();

  let cardsArray: {
    title: string;
    link: string;
    imageLink: string | undefined;
  }[] = [];

  {
    tile?.forEach((card, index) => {
      const { title, image, link } = card;
      const cardImage =
        image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();
      cardsArray.push({
        title: title,
        link: link,
        imageLink: cardImage,
      });
    });
  }

  return <Masonry title={title} tile={cardsArray} id={anchor} />;
};
