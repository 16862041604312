import {
  BrComponentContext,
  BrManageMenuButton,
  BrPageContext,
} from "@bloomreach/react-sdk";
import styles from "./Footer.module.scss";
import { Menu as BrMenu, isMenu } from "@bloomreach/spa-sdk";
import { useContext } from "react";
import { Footer } from "./Footer";
import { rikSelect } from "services/rikSelect";
import { RikContext } from "state_management/Contexts";
import { MenuModels } from "./types";

export function Index() {
  // Bloomreach Menu Reference
  const component = useContext(BrComponentContext);
  const page = useContext(BrPageContext);
  const rik = useContext(RikContext);
  if (!component || !page) {
    return null;
  }
  const { menu: menuRef } = component.getModels<MenuModels>();
  const menu = menuRef && page.getContent<BrMenu>(menuRef);

  if (!isMenu(menu)) {
    return null;
  }

  // Items Lists
  const items = menu?.getItems();
  const itemList = rikSelect(items, rik);

  return (
    menu?.getItems() && (
      <div className={styles.footerWrapper}>
        <Footer data={itemList} />
        <div className={styles.footerButton}>
          <BrManageMenuButton menu={menu} />
        </div>
      </div>
    )
  );
}
