export const ValuePropsTagManager = (
  title: string | undefined,
  clickText: string | undefined,
  page: string | undefined
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    element_location: title,
    click_text: clickText,
    cms_component: "More To Explore",
  });
};
