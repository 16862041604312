import { useEffect, useState, useRef, useContext } from "react";
import { useResize } from "hooks/useResize";
import Image from "next/legacy/image";
import styles from "./SameDayBanner.module.scss";
import clsx from "clsx";
import { BrRichTextContent } from "../BrRichTextContent";
import { ImageSet } from "@bloomreach/spa-sdk";
import { iconPlaceholder } from "../../constants";
import { CtaTile } from "../utils/CtaTile";
import { Headline } from "../utils/Headline";
import {
  SameDayTagManager,
  SameDayBrowseTagManager,
} from "./SameDayTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { useBackgroundColor, useBackgroundImage } from "hooks/useBackground";
import { ColorProps } from "../Accordion/types";
import { linkAttributes } from "services/linkAttributes";
import { SameDayBannerFcProps } from "./types";

export const SameDayBanner = ({ data, page }: SameDayBannerFcProps) => {
  // States
  const [textColorValue, setTextColorValue] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);

  // Refs
  const containerRef = useRef<HTMLDivElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const child1Ref = useRef<HTMLUListElement | null>(null);
  const child2Ref = useRef<HTMLAnchorElement | null>(null);
  const child2OriginalHeightRef = useRef<string | null>(null);

  // Destructured data
  const {
    headline,
    headerIcon,
    headerCaption,
    tiles,
    browseMore,
    background,
    textColor,
    image,
  } = data || {};

  // Hooks
  const size = useResize();
  const bgColor = useBackgroundColor(background as ColorProps);

  const bgImage = useBackgroundImage(image);

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  // Setting "Browse more" button active state
  const handleClick = () => {
    setIsActive(!isActive);
  };

  // Setting text color
  useEffect(() => {
    textColor?.selectionValues[0].key === "Black"
      ? setTextColorValue("rgba(0,0,0,1)")
      : setTextColorValue("rgba(255,255,255,1)");
  }, [textColor]);

  // Set original height of "Browse more" button on mount
  useEffect(() => {
    if (child2Ref.current && !child2OriginalHeightRef.current) {
      child2OriginalHeightRef.current = window
        .getComputedStyle(child2Ref.current, null)
        .getPropertyValue("height");
    }
  }, []);

  // Set proper height of "Browse more" on screen size change
  useEffect(() => {
    if (child1Ref.current && child2Ref.current) {
      if (size >= 1440) {
        child2Ref.current.style.height = window
          .getComputedStyle(child1Ref.current, null)
          .getPropertyValue("height");
      } else {
        // Reset to original height when size < 1440
        if (child2OriginalHeightRef.current) {
          child2Ref.current.style.height = child2OriginalHeightRef.current;
        }
      }
    }
  }, [size]);

  const browseMoreLinkProps = linkAttributes(
    browseMore?.ctaLink ?? "/services/printing/",
    browseMore?.newTab
  );

  return (
    <section
      className={styles.fullBleed}
      aria-label={headline + " " + headerCaption?.value.replace(/<\/?p>/g, "")}
    >
      <div
        className={styles.wrapper}
        ref={containerRef}
        style={{
          backgroundImage: `url(${bgImage})`,
          background: `${bgImage ? "" : bgColor}`,
        }}
      >
        <div className={styles.container} ref={componentRef}>
          <section className={styles.headingWrapper}>
            <div className={styles.heading}>
              <figure className={styles.headingIcon}>
                <Image
                  src={headerIcon ? headerIcon : iconPlaceholder}
                  alt=""
                  layout="fill"
                  objectFit="cover"
                />
              </figure>
              <div
                className={styles.headingText}
                style={{ color: textColorValue && textColorValue }}
              >
                <Headline level="h2" style={["h2"]}>
                  {headline ? headline : "Component's headline"}
                </Headline>

                {headerCaption?.value ? (
                  <BrRichTextContent
                    page={page!}
                    content={{ html: headerCaption?.value }}
                    className={styles.headingTextCapture}
                    data-testid="BrRichTextContent"
                  />
                ) : (
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                )}
              </div>
            </div>
          </section>
          <section className={styles.tilesWrapper}>
            <ul className={styles.tilesSection} ref={child1Ref}>
              {tiles?.map((tile, index) => {
                const props = {
                  ...tile,
                  tileImage:
                    page
                      .getContent<ImageSet>(tile.tileImage)
                      ?.getOriginal()
                      ?.getUrl() ?? "",
                };

                return (
                  <li key={index} className={styles.tile}>
                    <CtaTile
                      data={props}
                      handleClick={() =>
                        SameDayTagManager(
                          props.tileTitle,
                          props.ctaButton?.ctaText,
                          tagPage
                        )
                      }
                    />
                  </li>
                );
              })}
              <li>
                <a
                  {...browseMoreLinkProps}
                  className={clsx(styles.browseMore, {
                    [styles.active]: isActive,
                  })}
                  onClick={() => {
                    handleClick();
                    SameDayBrowseTagManager(
                      headline,
                      browseMore?.ctaText,
                      tagPage
                    );
                  }}
                  ref={child2Ref}
                  data-testid="BrowseMore"
                >
                  <p>
                    {browseMore?.ctaText ? browseMore?.ctaText : "CTA Text"}
                  </p>
                  <span className={styles.browseMoreArrow}></span>
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </section>
  );
};
