import React, { Fragment, useContext, useEffect } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import Head from "next/head";
import { Document, Page } from "@bloomreach/spa-sdk";
import { MetaFlagsContext, PathContext } from "state_management/Contexts";
import { ImageSet } from "@bloomreach/spa-sdk";
import { MetaProps } from "./types";

export const Index = () => {
  const page: Page | undefined = useContext(BrPageContext);
  const { path } = useContext(PathContext);
  const br_document = page?.getDocument<Document>();
  const {
    pmMeta,
    pmOgMeta,
    pmSeoProductData,
    pmMetaFlag,
    langMeta,
    pmRobotsMetadata,
  } = br_document?.getData<MetaProps>() ?? {};
  const today = new Date(Date.now() + 12096e5); // Price Valid date is current date + 14 days
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const cardImage =
    pmSeoProductData?.image &&
    page?.getContent<ImageSet>(pmSeoProductData?.image)?.getOriginal();

  const robotsMetaTags = pmRobotsMetadata?.filter(
    (obj, index, array) =>
      index === array.findIndex((key) => key["metaname"] === obj["metaname"])
  );

  if (!robotsMetaTags?.some((item) => item.metaname === "robots")) {
    robotsMetaTags?.push({ metaname: "robots", content: "index,follow" });
  }

  function getTitle() {
    return pmMeta?.title === "Home Page"
      ? "Print & Marketing Services"
      : `${pmMeta?.title} | Staples®`;
  }

  const metaFlagsContext = useContext(MetaFlagsContext);

  if (!metaFlagsContext) {
    throw new Error("MetaFlagsContext is not provided");
  }

  const { setFlags } = metaFlagsContext;

  useEffect(() => {
    const flagsList: string[] = [];
    if (pmMetaFlag?.flags) {
      pmMetaFlag.flags.map((item: string) => {
        flagsList.push(item);
      });
    }

    setFlags(flagsList);
  }, [pmMetaFlag, setFlags]);

  useEffect(() => {
    const existingScript = document.getElementById("product-data");
    if (pmSeoProductData?.sku && !existingScript) {
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.id = "product-data";
      script.innerHTML = JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Product",
        description: pmMeta?.description,
        brand: { "@type": "Brand", name: "Staples" },
        offers: {
          "@type": "AggregateOffer",
          priceCurrency: "USD",
          availability: "https://schema.org/InStock",
          url: `https://www.staples.com${path}`,
          lowPrice:
            pmSeoProductData?.priceLow > 0 ? pmSeoProductData.priceLow : "",
          highPrice:
            pmSeoProductData?.priceHigh > 0 ? pmSeoProductData.priceHigh : "",
          priceValidUntil: date,
        },
        name: pmMeta?.title,
        sku: pmSeoProductData?.sku,
        image: pmSeoProductData?.image ? cardImage?.getUrl() : "",
      });
      document.head.appendChild(script);
    }
  }, [
    pmSeoProductData,
    path,
    pmMeta?.description,
    pmMeta?.title,
    cardImage,
    date,
  ]);

  return (
    <>
      {pmMeta !== undefined && (
        <Head>
          <title>{getTitle()}</title>
          <link
            rel="icon"
            type="image/png"
            id="favicon"
            href="/services/printing/static-pages/images/CP_favicon.png"
          ></link>
          {langMeta &&
            langMeta.length &&
            langMeta.map((link, index: number) => {
              const { href, hrefLang } = link;
              const language = hrefLang.selectionValues[0].key;
              return (
                <Fragment key={index}>
                  {href && (
                    <link rel="alternate" href={href} hrefLang={language} />
                  )}
                </Fragment>
              );
            })}
          <meta name="description" content={pmMeta?.description} />
          <meta property="og:url" content={`https://www.staples.com${path}`} />
          <meta
            property="og:title"
            content={pmOgMeta?.title ? pmOgMeta?.title : pmMeta?.title}
          />
          <meta property="og:description" content={pmMeta?.description} />
          <link
            rel="canonical"
            key="canonical"
            href={
              pmOgMeta?.canonical
                ? pmOgMeta?.canonical
                : `https://www.staples.com${path}`
            }
          />

          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Staples.com" />
          {pmRobotsMetadata?.length ? (
            robotsMetaTags?.map((item, index) => (
              <meta key={index} name={item?.metaname} content={item?.content} />
            ))
          ) : (
            <meta name="robots" content="index,follow" />
          )}

          <meta
            name="google-site-verification"
            content="h7ff-hPQtMe7qOxMpqSon7mVjwbrjyo3fYk8e3Yuc80"
          />
        </Head>
      )}
    </>
  );
};
