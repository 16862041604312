import styles from "./Breadcrumbs.module.scss";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { useResize } from "hooks/useResize";
import { BreadcrumbsProps } from "@/types/utils";

export const Breadcrumbs = ({
  breadcrumbs,
  search,
  isBanner,
}: BreadcrumbsProps) => {
  const windowSize = useResize();
  const isProd = process.env.NODE_ENV === "production";

  return (
    <>
      {isBanner && breadcrumbs.length > 1 && windowSize < 768 && (
        <ul className={styles.bcContainer}>
          <li className={styles.viewAll}>
            <a href={breadcrumbs[0]?.key + search}>
              View All {breadcrumbs[0]?.value}
            </a>
          </li>
        </ul>
      )}
      {windowSize >= 768 && (
        <ul
          className={clsx(styles.bcContainer, {
            [styles.bcContainerHide]:
              (isBanner && breadcrumbs.length > 1) || !isBanner,
          })}
        >
          <li
            className={clsx(styles.first, {
              [styles.firstBanner]: isBanner,
              [styles.firstHide]: isBanner && breadcrumbs.length > 1,
            })}
            data-testid="home_bc"
          >
            <a
              href={
                isProd
                  ? `${process.env.NEXT_PUBLIC_BASEPATH}`
                  : `${process.env.NEXT_PUBLIC_BASEPATH}${search}`
              }
            >
              {" "}
              Print & Marketing Services{" "}
            </a>
          </li>
          {breadcrumbs.map((bc) => (
            <li
              key={uuidv4()}
              className={clsx(styles.item, {
                [styles.itemBanner]: isBanner,
                [styles.itemHide]: isBanner && breadcrumbs.length > 1,
              })}
              data-testid="added_bc"
            >
              <a href={bc.key + search}> {bc.value} </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
