export const MainCtaTagManager = (
  label: string,
  page: string,
  title: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: label,
    element_location: "Component's Header",
    cms_component: `${title} Related Products Component`,
  });
};
export const TileCtaTagManager = (
  label: string,
  page: string,
  title: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: label,
    element_location: `${title} Tile`,
    cms_component: `Related Products Component`,
  });
};
