import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./TableMobileColumnHeader.module.scss";
import clsx from "clsx";

export const ArrowNext = (props: { className: string; onClick: any }) => {
  const { className, onClick } = props;
  return (
    <button className={clsx(className, styles.arrowNext)} onClick={onClick}>
      <span className="visuallyhidden">Next Size</span>
    </button>
  );
};
export const ArrowPrev = (props: { className: string; onClick: any }) => {
  const { className, onClick } = props;
  return (
    <button className={clsx(className, styles.arrowPrev)} onClick={onClick}>
      <span className="visuallyhidden">Previous Size</span>
    </button>
  );
};
