import React, { ReactElement } from "react";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import Head from "next/head";
import { MetaTagsProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { description, url, title } =
    getContainerItemContent<MetaTagsProps>(component, page) ?? {};

  return (
    <>
      <Head>
        <meta name="description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" key="canonical" href={url} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Staples.com" />
        <meta name="robots" content="index,follow" />
      </Head>
      <p className="editMeta">
        Click here to edit Meta Tags. This text is not visible outside of
        editor.
      </p>
    </>
  );
};
