import styles from "./MarketingHeroBanner.module.scss";
import { useState, useEffect, useRef, useContext } from "react";
import Image from "next/image";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { BrRichTextContent } from "../BrRichTextContent";
import { Index as Breadcrumbs } from "../Breadcrumbs";
import { HeroTagManager } from "./HeroBannerTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";
import { imagePlaceholder } from "../../constants";
import { MarketingHeroBannerFCProps } from "./types";

export const MarketingHeroBanner = ({
  title,
  description,
  buttons,
  image,
  page,
  showMore,
  breadcrumbs,
  manualBc,
}: MarketingHeroBannerFCProps) => {
  const [hasShortDescription, setHasShortDescription] = useState(false);
  const [hasMoreButton, setMoreButton] = useState(false);
  const [hasAppearClass, setAppearClass] = useState(false);
  const [bcActive, setBcActive] = useState(false);

  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  // End GA tagging

  useEffect(() => {
    breadcrumbs ? setBcActive(true) : setBcActive(false);
  }, [breadcrumbs]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    showMore?.map((i) => {
      if (i.shortDescription?.value) {
        setHasShortDescription(true);
        setMoreButton(true);
      }
    });
  }, [showMore]);

  useEffect(() => {
    const containerContent = ref.current as HTMLElement;

    containerContent
      .querySelectorAll('a[href^="#disclaimers"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", (e) => {
          e.preventDefault();

          const container = document.getElementById("disclaimers")
            ?.offsetTop as number;

          setTimeout(() => {
            window.scrollTo({
              top: container - 200,
              left: 0,
              behavior: "smooth",
            });
          }, 100);
        });
      });
  }, []);

  const handleShowMoreButton = () => {
    setHasShortDescription(!hasShortDescription);
    setAppearClass(true);

    if (window.scrollY > 200) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.marketingHeroContainer}>
      <picture className={styles.marketingHeroContainerImage}>
        <Image
          src={image ? image : imagePlaceholder}
          style={{ objectFit: "cover", objectPosition: "right" }}
          priority
          fill
          alt={title ?? ""}
          sizes="(max-width: 1440px) 100vw, 1440px"
        ></Image>
      </picture>
      <div
        className={styles.marketingHeroContentBreadcrumb}
        data-testid="Mkt-hero-banner-breadcrumbs"
      >
        {bcActive && <Breadcrumbs banner={true} manualBreadcrumbs={manualBc} />}
      </div>

      <div className={styles.marketingHeroContent}>
        <h1
          className={styles.marketingHeroContentTitle}
          data-testid="Mkt-hero-banner-title"
        >
          {title}
        </h1>
        <div
          ref={ref}
          className={clsx(styles.marketingHeroContentText, {
            [styles.shortDescriptionAppear]: hasAppearClass,
          })}
        >
          {hasShortDescription && (
            <div
              className={(styles.shortDescription, styles.formating)}
              data-testid="Mkt-hero-short-description"
            >
              {showMore?.map((i) => {
                const { shortDescription } = i;
                const text = shortDescription?.value as string;

                return (
                  <BrRichTextContent
                    page={page!}
                    key={uuidv4()}
                    content={{ html: text }}
                  />
                );
              })}
            </div>
          )}

          {description && !hasShortDescription && (
            <div
              className={styles.longDescription}
              data-testid="Mkt-hero-description"
            >
              <BrRichTextContent
                page={page!}
                content={{ html: description.value }}
              />
            </div>
          )}

          {hasMoreButton && (
            <button
              data-testid="Mkt-hero-show-more-button"
              className={styles.showMoreButton}
              onClick={handleShowMoreButton}
            >
              {hasShortDescription ? "..more" : "less"}
            </button>
          )}
        </div>

        <div className={styles.marketingHeroContentCta}>
          {buttons?.map((simpleCta) => {
            const { ctaText, ctaLink } = simpleCta;

            return (
              <a
                key={uuidv4()}
                href={ctaLink}
                className={styles.marketingHeroContentButton}
                data-testid="Mkt-hero-cta-link"
                onClick={() => HeroTagManager(ctaText, tagPage)}
              >
                {ctaText}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};
