import { useContext, useEffect, useMemo, useState } from "react";
import styles from "../Dropdowns.module.scss";
import { PrimaryDropdownProps } from "../types";
import { PrimaryTopLevel } from "./PrimaryTopLevel";
import { PrimaryContent } from "./PrimaryContent";
import { BrPageContext } from "@bloomreach/react-sdk";
import { Document } from "@bloomreach/spa-sdk";
import { useResize } from "hooks/useResize";
import { FormatedPrimaryDataProps } from "../types";

export const PrimaryDropdown = ({
  dialogOpen,
  data,
  setLevel,
  isActive,
}: PrimaryDropdownProps) => {
  const [productLevel, setProductLevel] = useState("top");
  const [itemId, setItemId] = useState("");
  const [contentData, setContentData] =
    useState<FormatedPrimaryDataProps | null>(null);
  const [isDesktop, setIsDesktop] = useState(false);

  const windowSize = useResize();
  const staplesText = data.data.staplesCaption;
  const hideStaplesRik = data.data.hideStaplesRik;

  const page = useContext(BrPageContext);

  useEffect(() => {
    setIsDesktop(windowSize >= 821);
  }, [windowSize]);

  // Format bloomreach ref data to the array of objects (items). For a desktop use.
  const itemsArrayFormated = useMemo(() => {
    const itemsArrayRaw = data.data.dropdownItem;
    if (itemsArrayRaw) {
      return itemsArrayRaw
        .map((item) => {
          const itemDoc = item && page?.getContent<Document>(item);
          return itemDoc?.getData<FormatedPrimaryDataProps>();
        })
        .filter((item): item is FormatedPrimaryDataProps => item !== undefined);
    }
  }, [data, page]);

  // Filter data by chosen item id.
  useEffect(() => {
    if (itemId && itemsArrayFormated) {
      const contentDataFiltered = itemsArrayFormated.filter(
        (content) => content?.id === itemId
      );
      setContentData(contentDataFiltered[0]);
    }
  }, [itemId, itemsArrayFormated, setContentData]);

  const topLevelProps = {
    parentItem: data.data.displayName,
    topLevelData: itemsArrayFormated,
    isDesktop: isDesktop,
    dialogOpen: dialogOpen,
    setParentLevel: setLevel,
    setLevel: setProductLevel,
    setItemId: setItemId,
    itemId: itemId,
    staplesCaption: staplesText,
    hideStaplesRik: hideStaplesRik,
    isActive: isActive,
  };

  return (
    <section className={styles.container}>
      {(productLevel === "top" || isDesktop) && (
        <PrimaryTopLevel {...topLevelProps} />
      )}
      {productLevel === "content" && contentData && (
        <PrimaryContent
          parentItem={data.data.displayName}
          productData={contentData}
          isDesktop={isDesktop}
          dialogOpen={dialogOpen}
          setLevel={setProductLevel}
          setItemId={setItemId}
        />
      )}
    </section>
  );
};
