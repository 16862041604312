import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
  Document,
} from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement } from "react";
import { Tile } from "./Tile";
import { v4 as uuidv4 } from "uuid";
import styles from "./PrintSolutionsLayout.module.scss";
import { PrintSolutionsProps, PrintSolutionTileProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { tiles } =
    getContainerItemContent<PrintSolutionsProps>(component, page) ?? {};

  let tilesArray: PrintSolutionTileProps[] = [];

  tiles?.forEach((tile) => {
    const { title, pageLink, caption, image } = tile;
    const imageUrl =
      image && page?.getContent<ImageSet>(image)?.getOriginal()?.getUrl();
    const pageUrl = page.getContent<Document>(pageLink)?.getUrl();

    tilesArray.push({
      title: title,
      pagePath: pageUrl,
      imagePath: imageUrl,
      caption: caption,
    });
  });
  return (
    <>
      <ul className={styles.container}>
        {tilesArray?.map((tile) => {
          return (
            <li key={uuidv4()} className={styles.wrapper}>
              <Tile data={tile} page={page} />
            </li>
          );
        })}
      </ul>
    </>
  );
};
