import { useRef, useState } from "react";
import { PricingGridModal } from "../PricingGridModal";
import styles from "./PopupLauncher.module.scss";
import { PopupLauncherFCProps } from "./types";

export const PopupLauncher = ({
  componentTitle,
  subTitle,
  footerText,
  pmPricingTableModal,
  page,
}: PopupLauncherFCProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  // Open modal
  const openModalHandler = () => {
    setShowModal(true);
  };

  return (
    <article className={styles.container}>
      <h2 data-testid="PricingGridmodal-comp-title">
        {componentTitle}
        <span data-testid="PricingGridmodal-sub-title"> {subTitle} Modal</span>
      </h2>
      <button
        className={styles.popupLink}
        onClick={openModalHandler}
        ref={btnRef}
      >
        <span className="visuallyhidden">Open</span> Pricing Details
        <span className="visuallyhidden"> popup</span>
      </button>
      {showModal === true ? (
        <PricingGridModal
          componentTitle={componentTitle}
          subTitle={subTitle}
          footerText={footerText}
          pmPricingTableModal={pmPricingTableModal}
          page={page}
          showModal={showModal}
          setShowModal={setShowModal}
          btnRef={btnRef}
        />
      ) : (
        <></>
      )}
    </article>
  );
};
