import { PniUsrObject } from "@/types/utils";
import Cookies from "universal-cookie";

export function getUserNameFromCookie(): string | null {
  const cookies = new Cookies();
  const pniUser: PniUsrObject | string = cookies.get("PniUsrCtx");
  if (pniUser instanceof Object && pniUser.wm) {
    return pniUser.wm;
  }
  return null;
}

export function getRewardNumberFromCookie(): string | null {
  const cookies = new Cookies();
  const pniUser: PniUsrObject | string = cookies.get("PniUsrCtx");
  if (pniUser instanceof Object && pniUser.rw) {
    return pniUser.rw;
  }
  return null;
}

export function getIsSignedInFromCookie(): boolean {
  const cookies = new Cookies();
  const pniUser = cookies.get("PniUsrCtx");
  return pniUser instanceof Object ? true : false;
}
