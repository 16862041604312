import { useCallback, useEffect, useMemo, useState } from "react";
import { useResize } from "hooks/useResize";
import { CarouselContainer } from "../utils/NewCarouselContaner";
import { Tile } from "./Utils";
import { CarouselProps, NewHeightsProps } from "./types";

export const Carousel = ({
  data,
  page,
  openModal,
  setItemData,
  setCurrentItem,
}: CarouselProps) => {
  const [differentiatorLimit, setDifferentiatorLimit] = useState(Infinity);
  const [contentHeights, setContentHeights] = useState<number[]>([]);
  const [maxContentHeight, setMaxContentHeight] = useState(0);
  const [diffContentHeights, setDiffContentHeights] = useState<
    NewHeightsProps | {}
  >({});
  const [diffMaxHeight, setDiffMaxHeight] = useState({});
  const size = useResize();

  // Function: Updates content containers heights array
  const updateContentHeight = useCallback((height: number) => {
    setContentHeights((currentHeights) => [...currentHeights, height]);
  }, []);

  // Function: Updates differentiators containers heights array (for each level)
  const updateDiffHeight = useCallback((index: number, height: number) => {
    setDiffContentHeights((currentHeights) => {
      const newHeights = { ...currentHeights };
      if (newHeights.hasOwnProperty(index)) {
        newHeights[index].push(height);
      } else {
        newHeights[index] = [height];
      }
      return newHeights;
    });
  }, []);

  // Resets content heights array on resize
  useEffect(() => {
    contentHeights.length && setContentHeights([]);
  }, [size, contentHeights.length]);

  // Sets maximum height for the contents container
  useEffect(() => {
    if (contentHeights.length) {
      const maxContentHeight = Math.max(...contentHeights);
      setMaxContentHeight(maxContentHeight);
    }
  }, [contentHeights]);

  // Sets maximum height for the differentiators containers
  useEffect(() => {
    const maxHeightsByIndex = Object.entries(diffContentHeights).reduce<{
      [key: string]: number;
    }>((acc, [index, heights]) => {
      if (Array.isArray(heights)) {
        const maxHeight = Math.max(...heights);
        acc[index] = maxHeight;
      }

      return acc;
    }, {});
    setDiffMaxHeight(maxHeightsByIndex);
  }, [diffContentHeights]);

  const tileProps = useMemo(
    () => ({
      page,
      setDifferentiatorLimit,
      differentiatorLimit,
      updateContentHeight,
      maxContentHeight,
      updateDiffHeight,
      diffMaxHeight,
      listLength: data.length,
      openModal,
      setItemData,
      setCurrentItem,
    }),
    [
      page,
      setDifferentiatorLimit,
      differentiatorLimit,
      updateContentHeight,
      maxContentHeight,
      updateDiffHeight,
      diffMaxHeight,
      data.length,
      openModal,
      setItemData,
      setCurrentItem,
    ]
  );

  return (
    <CarouselContainer list={data}>
      <Tile data={null} {...tileProps} />
    </CarouselContainer>
  );
};
