import { ReactElement } from "react";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { PromoSection } from "./PromoSection";
import { PromoProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }
  const { topSection, tile } =
    getContainerItemContent<PromoProps>(component, page) ?? {};

  return <PromoSection topSection={topSection} tile={tile} page={page} />;
};
