import Image, { StaticImageData } from "next/legacy/image";
import styles from "./WaysToDesignCTA.module.scss";
import animateScrollTo from "animated-scroll-to";
import { WaysToDesignTagManager } from "./WaysToDesignTagManager";
import { useContext } from "react";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "../../services/pageCheck";

export function CTALink({
  link,
  image,
  title,
}: {
  link: string;
  image: StaticImageData;
  title: string;
}) {
  // GA tagging - setting the page type
  const path = useContext(PathContext);
  const page = PageCheck(path);

  return (
    <a
      href={link}
      className={styles.articleContentLink}
      onClick={() => WaysToDesignTagManager(title, page)}
    >
      <div className={styles.articleContentImageWrapper}>
        <Image src={image} className={styles.articleContentImage} alt="" />
      </div>
      <h2 className={styles.articleContentTitle}>{title}</h2>
    </a>
  );
}

export function CTADescription({ description }: { description: string }) {
  return (
    <div
      className={styles.articleContentDescription}
      data-testid="article-content-description"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
}

export function CTAPricingLink() {
  function handleScroll() {
    animateScrollTo(document.querySelector("#pricing-grid") as HTMLElement, {
      verticalOffset: -125,
    });
  }
  return (
    <button className={styles.articleContentPricingLink} onClick={handleScroll}>
      View Pricing Details
    </button>
  );
}
