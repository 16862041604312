import { useState } from "react";
import { HamburgerDialog } from "../../HamburgerDialog";
import { PrimaryDropdown } from "../Dropdowns/PrimaryDropdown";
import { SecondaryDropdown } from "../Dropdowns/SecondaryDropdown";
import { LevelOne } from "./LevelOne";
import { MobileProps } from "./types";

export const MobileMenu = ({
  primaryData,
  secondaryData,
  dialogOpen,
  navOpen,
  level,
  setLevel,
}: MobileProps) => {
  return (
    <HamburgerDialog dialogOpen={dialogOpen} open={navOpen}>
      {level === "initial" && (
        <LevelOne
          dialogOpen={dialogOpen}
          primaryData={primaryData}
          secondaryData={secondaryData}
          setLevel={setLevel}
        />
      )}
      {level === "primary" && (
        <PrimaryDropdown
          dialogOpen={dialogOpen}
          data={primaryData}
          setLevel={setLevel}
        />
      )}
      {level === "secondary" && (
        <SecondaryDropdown
          dialogOpen={dialogOpen}
          data={secondaryData}
          setLevel={setLevel}
        />
      )}
    </HamburgerDialog>
  );
};
