import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Mobile.module.scss";
import { Divider } from "@/components/utils/Divider";
import { Dropdowns } from "../Dropdowns";
import { UtilityMenuMobile } from "../../UtilityMenuMobile";
import { SidebarHead } from "../utils";
import { Logo } from "../../Logo";
import { LevelOneProps } from "./types";

export const LevelOne = ({
  dialogOpen,
  primaryData,
  secondaryData,
  setLevel,
}: LevelOneProps) => {
  const [isActive, setIsActive] = useState(false);

  // Triggers slide-in animation
  useEffect(() => {
    setIsActive(true);
  }, []);

  // Sets up sidebar menu level and triggers slide-out animation
  const levelSetup = (level: string) => {
    setIsActive(false);
    setTimeout(() => {
      setLevel(level);
    }, 50);
  };

  return (
    <article
      className={clsx(styles.container, {
        [styles.containerHide]: !isActive,
      })}
    >
      <SidebarHead dialogOpen={dialogOpen}>
        <Logo />
      </SidebarHead>
      <Dropdowns
        levelSetup={levelSetup}
        primaryData={primaryData}
        secondaryData={secondaryData}
      />
      <div className={styles.divider}>
        <Divider marginVar={16} />
      </div>
      <UtilityMenuMobile />
    </article>
  );
};
