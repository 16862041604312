import { BrProps } from "@bloomreach/react-sdk";
import { ReactElement } from "react";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { VideoSpotlight } from "./VideoSpotlight";
import { VideoSpotlightProps } from "./types";

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return <div>No component or page available</div>;
  }

  const data = getContainerItemContent<VideoSpotlightProps>(component, page);

  if (!data) {
    return null;
  }

  return <VideoSpotlight data={data} page={page} />;
};
