import { MasonryTile } from "./MasonryTile";
import { useResize } from "hooks/useResize";
import { v4 as uuidv4 } from "uuid";
import styles from "./Masonry.module.scss";
import { MasonryFCProps } from "./types";

export const Masonry = ({ title, tile, id }: MasonryFCProps) => {
  let windowSize = useResize();
  const sectionTitle = title;

  return (
    <article
      className={styles.container}
      data-testid="masonry-container"
      id={id ? id : undefined}
    >
      <h2 className={styles.containerTitle}>
        {title ? title : "Section Title"}
      </h2>
      <section className={styles.cardContainer}>
        {tile?.map((card, index) => {
          const { title, imageLink, link } = card;
          return (
            <MasonryTile
              key={uuidv4()}
              link={link}
              image={imageLink}
              title={title}
              index={index}
              windowSize={windowSize}
              sectionTitle={sectionTitle}
            />
          );
        })}
      </section>
    </article>
  );
};
