export const SameDayTagManager = (
  headline: string | undefined,
  ctaText: string | undefined,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: ctaText,
    element_location: headline,
    cms_component: "product promotion tiles",
  });
};

export const SameDayBrowseTagManager = (
  headline: string | undefined,
  ctaText: string | undefined,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: ctaText,
    element_location: headline,
    cms_component: "product promotion tiles",
  });
};
