export const mediaData = [
  {
    link: "https://twitter.com/staples",
    className: "socialTwitter",
    component: "twitter",
    id: 1,
  },
  {
    link: "https://www.facebook.com/staples",
    className: "socialFacebook",
    component: "facebook",
    id: 2,
  },
  {
    link: "https://www.youtube.com/stapleschannel",
    className: "socialYouTube",
    component: "youtube",
    id: 3,
  },
  {
    link: "https://www.linkedin.com/company/staples",
    className: "socialLinkedin",
    component: "linkedin",
    id: 4,
  },
];
