import Image from "next/image";
import { useContext, useMemo, useRef } from "react";
import { BrRichTextContent } from "../BrRichTextContent";
import { ModalContainer } from "../utils/ModalContainer";
import { linkAttributes } from "services/linkAttributes";
import { ImageToUrl } from "services/imageToUrl";
import { PageCheck } from "services/pageCheck";
import { useModal } from "hooks/useModal";
import { useClickOutside } from "hooks/useClickOutside";
import {
  HeaderCtaTagManager,
  ModalCtaTagManager,
} from "./ProductInspirationTagManager";
import clsx from "clsx";
import styles from "./ProductInpiration.module.scss";
import {
  ConditionalLinkProps,
  ModalProps,
  OpenButtonProps,
  TileContentProps,
  TileHeaderProps,
  TileProps,
} from "./types";
import { PathContext } from "state_management/Contexts";

// Bottom button
export const OpenButton = ({
  buttonHandler,
  isActiveButton,
  isMobile,
  link,
}: OpenButtonProps) => {
  const linkProps = link ? linkAttributes(link.address, link.newTab) : null;

  return isMobile || !link ? (
    <button
      onClick={buttonHandler}
      className={clsx(styles.open, {
        [styles.openActive]: isActiveButton && !link,
        [styles.openHidden]: isActiveButton && link,
      })}
    >
      <div
        className={clsx(styles.openWhite, {
          [styles.openWhiteActive]: isActiveButton,
        })}
      >
        <Image
          src="/services/printing/static-pages/icons/modal-plus.svg"
          alt=""
          width={18}
          height={18}
          className={styles.openWhiteImg}
        />
      </div>
    </button>
  ) : (
    <a className={styles.link} {...linkProps}>
      {link.title}
    </a>
  );
};

export const HeaderSection = ({
  title,
  description,
  linkProps,
  ctaButton,
  tilesQty,
}: TileHeaderProps) => {
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  const tagManagerHandler = (label: string) => {
    HeaderCtaTagManager(label, tagPage);
  };
  return (
    <section
      className={clsx(styles.header, {
        [styles.headerGridTwo]: tilesQty === 2,
        [styles.headerGridThree]: tilesQty >= 3,
      })}
      data-testid="header"
    >
      <h2>{title}</h2>
      <p>{description}</p>
      {ctaButton.title && (
        <a
          {...linkProps}
          data-testid="link"
          onClick={() => tagManagerHandler(title)}
        >
          {ctaButton.title}
          <div className={styles.arrow}>
            <Image
              src="/services/printing/static-pages/icons/arrow-link.svg"
              alt=""
              width={19}
              height={14}
              sizes="100%"
            />
          </div>
        </a>
      )}
    </section>
  );
};

export const Modal = ({
  closeModal,
  modalIsOpen,
  wrapperIsOpen,
  modalImageMobile,
  modalImageDesktop,
  modalTitleDescription,
  modalContentBlock,
  modalCta,
  page,
}: ModalProps) => {
  const { address, newTab, title } = modalCta;

  const path = useContext(PathContext);
  const tagPage = PageCheck(path);
  const tagManagerHandler = (label: string) => {
    ModalCtaTagManager(label, tagPage);
  };

  const mobileModalImage = ImageToUrl(modalImageMobile);
  const desktopModalImage = ImageToUrl(modalImageDesktop);

  const wrapperRef = useRef(null);

  const linkProps = useMemo(
    () => linkAttributes(address, newTab),
    [address, newTab]
  );

  const modalContentBlocksElement = modalContentBlock.map(
    (block, index: number) => {
      const { header, description, image, startingAtLabel } = block;

      const blockImage = ImageToUrl(image);
      return (
        <div
          className={clsx(styles.block, {
            [styles.blockSpaced]: startingAtLabel?.showLabel,
          })}
          key={index}
        >
          <h2>{header}</h2>
          <div
            className={clsx(styles.blockImage, {
              [styles.blockSpacedImage]: startingAtLabel?.showLabel,
            })}
          >
            <Image
              src={
                blockImage ??
                "/services/printing/static-pages/placeholders/img-placeholder.png"
              }
              alt=""
              fill
              sizes="100%"
              className={styles.blockImagePic}
            />
          </div>
          <div
            className={clsx(styles.blockContent, {
              [styles.blockContentSpaced]: startingAtLabel?.showLabel,
            })}
          >
            <div className={styles.blockContentMain}>
              {description && (
                <BrRichTextContent
                  page={page!}
                  content={{ html: description.value }}
                />
              )}
            </div>
            {startingAtLabel && startingAtLabel?.showLabel && (
              <div className={styles.blockContentLabel}>
                <p>
                  {startingAtLabel.label
                    ? startingAtLabel.label
                    : "Starting at"}
                </p>
                <span>{startingAtLabel.labelText}</span>
              </div>
            )}
          </div>
        </div>
      );
    }
  );

  const componentProps = {
    closeModal,
    wrapperIsOpen,
    modalIsOpen,
    wrapperRef,
  };

  return (
    <ModalContainer {...componentProps}>
      <div className={styles.modalWrapper} ref={wrapperRef}>
        <div className={styles.modalImage}>
          <Image
            src={
              mobileModalImage
                ? mobileModalImage
                : "/services/printing/static-pages/placeholders/img-placeholder.png"
            }
            alt=""
            fill
            sizes="100%"
            className={styles.modalImagePicMobile}
          />
          <Image
            src={
              desktopModalImage
                ? desktopModalImage
                : "/services/printing/static-pages/placeholders/img-placeholder.png"
            }
            alt=""
            fill
            sizes="100%"
            className={styles.modalImagePicDesktop}
          />
        </div>

        <section className={styles.modalContent} data-testid="dialog-content">
          <div className={styles.modalHeader}>
            {modalTitleDescription && (
              <BrRichTextContent
                page={page!}
                content={{ html: modalTitleDescription.value }}
              />
            )}
          </div>

          <div className={styles.modalBlocks}>
            {modalContentBlock.length && modalContentBlocksElement}
          </div>
          <a
            className={styles.modalCta}
            {...linkProps}
            onClick={() => tagManagerHandler(title)}
          >
            {title}
          </a>
        </section>
      </div>
    </ModalContainer>
  );
};

export const TileContent = ({
  title,
  isActive,
  background,
  specialAlignment,
  textColor,
}: TileContentProps) => {
  const { alpha, red, green, blue } = background;
  const backgroundStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
  };
  const linkSrc =
    textColor?.selectionValues[0].key === "Black"
      ? "/services/printing/static-pages/icons/arrow-link.svg"
      : "/services/printing/static-pages/icons/arrow-link-white.svg";
  return (
    <section
      className={clsx(styles.tileContent, {
        [styles.tileContentSpecial]: specialAlignment,
        [styles.tileContentActive]: isActive,
      })}
      style={backgroundStyles}
    >
      {title}
      <div className={styles.tileContentArrow}>
        <Image src={linkSrc} alt="" width={19} height={14} sizes="100%" />
      </div>
    </section>
  );
};
export const ConditionalLink = ({
  tilesQty,
  index,
  headerPos,
  isActiveButton,
  inspirationLink,
  onButtonClick,
  isMobile,
  flipSolid,
  flipTextColor,
  children,
}: ConditionalLinkProps) => {
  const linkProps =
    inspirationLink && isActiveButton
      ? linkAttributes(inspirationLink.address, inspirationLink.newTab)
      : null;

  const specialAlignment = tilesQty >= 3 && index !== 2 ? true : false;

  // Select className for tile depending on header position
  const getTileClassName = useMemo(() => {
    if (tilesQty === 1) return styles.tileGridOne;
    if (tilesQty === 2) return styles.tileGridTwo;
    if (tilesQty >= 3) {
      if (index === 0) {
        return headerPos === "Center"
          ? styles.tileGridThreeFirstCenter
          : styles.tileGridThreeFirstLeftRight;
      }
      if (index === 1) {
        return headerPos === "Center"
          ? styles.tileGridThreeSecondCenter
          : styles.tileGridThreeSecondLeftRight;
      }
      if (index === 2) {
        return headerPos === "Right"
          ? styles.tileLargeRight
          : styles.tileLargeLeftCenter;
      }
    }

    return "";
  }, [tilesQty, headerPos, index]);

  // Click outside closes active tiles
  const simulateMouseEvent = (): React.MouseEvent<HTMLButtonElement> => {
    const event = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
    }) as unknown as React.MouseEvent<HTMLButtonElement>;
    return event;
  };

  const handleOutsideClick = () => {
    if (isActiveButton) {
      const simulatedEvent = simulateMouseEvent();
      onButtonClick(simulatedEvent);
    }
  };

  const tileRef = useClickOutside<HTMLAnchorElement>(handleOutsideClick);

  const textColor =
    flipTextColor?.selectionValues[0].key === "White"
      ? "rgba(255, 255, 255, 1)"
      : "rgba(0, 0, 0, 1)";

  const tileContentProps = {
    title: inspirationLink?.title,
    isActive: isActiveButton,
    background: flipSolid,
    specialAlignment: specialAlignment,
    textColor: flipTextColor,
  };

  return inspirationLink && isMobile ? (
    <a
      className={`${styles.tile} ${getTileClassName}`}
      data-testid="tileLink"
      {...linkProps}
      ref={tileRef}
      style={{ color: textColor }}
    >
      {children}
      <TileContent {...tileContentProps} />
    </a>
  ) : (
    <div className={`${styles.tile} ${getTileClassName}`} data-testid="tile">
      {children}
    </div>
  );
};

export const Tile = (props: TileProps) => {
  const {
    tileData,
    tilesQty,
    headerPos,
    index,
    page,
    isMobile,
    isActiveButton,
    onButtonClick,
  } = props;
  const { modalIsOpen, wrapperIsOpen, openModal, closeModal } = useModal();
  const {
    faceImage,
    modalImageMobile,
    modalImageDesktop,
    modalTitleDescription,
    modalContentBlock,
    modalCta,
    flipSolid,
    flipTextColor,
    inspirationLink,
  } = tileData;

  const containerProps = {
    tilesQty,
    index,
    headerPos,
    isActiveButton,
    inspirationLink,
    onButtonClick,
    isMobile,
    flipSolid,
    flipTextColor,
  };

  const modalProps = useMemo(
    () => ({
      closeModal,
      modalIsOpen,
      wrapperIsOpen,
      modalImageMobile,
      modalImageDesktop,
      modalTitleDescription,
      modalContentBlock,
      modalCta,
      page,
    }),
    [
      closeModal,
      modalIsOpen,
      wrapperIsOpen,
      modalImageMobile,
      modalImageDesktop,
      modalTitleDescription,
      modalContentBlock,
      modalCta,
      page,
    ]
  );

  const mainImage = ImageToUrl(faceImage);

  // Selecting className for the tile image
  const getImageClassName = useMemo(() => {
    if (tilesQty === 2) return styles.tileImageGridTwo;
    if (tilesQty === 3 && index !== 2) return styles.tileImageGridThree;
    if (tilesQty === 3 && index === 2) return styles.tileImageGridLarge;
    return "";
  }, [tilesQty, index]);

  return (
    <ConditionalLink {...containerProps}>
      <section
        className={clsx(styles.tileImage, {
          [getImageClassName]: true,
        })}
      >
        <Image
          src={
            mainImage ??
            "/services/printing/static-pages/placeholders/img-placeholder.png"
          }
          alt=""
          fill
          sizes="100%"
          className={styles.tileImagePic}
        />
      </section>
      <OpenButton
        buttonHandler={flipSolid ? onButtonClick : openModal}
        isActiveButton={isActiveButton}
        isMobile={isMobile}
        link={inspirationLink}
      />
      {!inspirationLink ? <Modal {...modalProps} /> : null}
    </ConditionalLink>
  );
};
