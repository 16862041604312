export const MasonryTagManager = (
  title: string | undefined,
  clickText: string | undefined,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    element_location: title,
    click_text: clickText,
    cms_component: "Explore by Category Tiles",
  });
};
