export const PrintingSpecialsTagManager = (
  title: string,
  headline: string,
  page: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: page,
    click_text: title.trim(),
    element_location: headline,
    cms_component: "coupon list",
  });
};
