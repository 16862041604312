import {
  getUserNameFromCookie,
  getRewardNumberFromCookie,
} from "../utilityMenuHelpers/cookies";
import { useEffect, useState } from "react";
import styles from "./UtilityMenuGreeting.module.scss";

export function UtilityMenuGreeting() {
  const [firstName, setFirstName] = useState("");
  const [rewardNumber, setRewardNumber] = useState("");

  useEffect(() => {
    const userName = getUserNameFromCookie();
    if (userName?.trim()) setFirstName(userName);

    const rewardNum = getRewardNumberFromCookie();
    if (rewardNum) setRewardNumber(rewardNum);
  }, []);

  return (
    <>
      <span className={styles.greeting}>
        {firstName ? `Hi, ${firstName}!` : "Hi!"}
      </span>
      {rewardNumber && (
        <span className={styles.rewardNumber}>Member #: {rewardNumber}</span>
      )}
    </>
  );
}
