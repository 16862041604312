import { ReactElement, useRef, useState } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import {
  ContainerItem,
  getContainerItemContent,
  ImageSet,
} from "@bloomreach/spa-sdk";
import { ScrollableTilesProps } from "./types";
import { useResize } from "hooks/useResize";
import { useDraggable } from "hooks/useDraggable";
import clsx from "clsx";
import styles from "./ScrollableTiles.module.scss";
import { Tile } from "./ScrollableTilesUtils";

export function ScrollableTiles({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null {
  // Gradient overlays control
  const [leftOverlayVisible, setLeftOverlayVisible] = useState(false);
  const [rightOverlayVisible, setRightOverlayVisible] = useState(true);
  // Misc
  const containerRef = useRef<HTMLUListElement>(null);
  const size = useResize();
  //  Make container draggable
  const handleMouseDown = useDraggable(containerRef);

  if (!component || !page) {
    return <div>No component or page available</div>;
  }

  //Receiving data from Bloomreach
  const { headlineTitle, pmaccessoriestile } =
    getContainerItemContent<ScrollableTilesProps>(component, page) ?? {};

  //Sets visibility of overlays
  function handleScroll(e: React.UIEvent) {
    const { scrollWidth, clientWidth, scrollLeft } = e.target as HTMLDivElement;
    const maxScrollLeft = scrollWidth - clientWidth;
    setLeftOverlayVisible(scrollLeft > 0);
    setRightOverlayVisible(Math.ceil(scrollLeft) < maxScrollLeft);
  }

  return (
    <article className={styles.component}>
      <h2
        className={clsx(styles.componentTitle, {
          [styles.componentTitlePlaceholder]: !headlineTitle,
        })}
      >
        {headlineTitle ? headlineTitle : "Add Component Title"}
      </h2>

      <div className={styles.componentScrollAreaWrapper}>
        {size >= 768 && (
          <>
            <div
              className={clsx(styles.componentLeftOverlay, {
                [styles.componentLeftOverlayVisible]: leftOverlayVisible,
              })}
            />
            <div
              className={clsx(styles.componentRightOverlay, {
                [styles.componentRightOverlayVisible]: rightOverlayVisible,
              })}
            />
          </>
        )}
        <ul
          className={styles.componentScrollArea}
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onScroll={handleScroll}
        >
          {pmaccessoriestile?.map((tile, index) => {
            const tileData = {
              ...tile,
              tileImage:
                page
                  .getContent<ImageSet>(tile?.tileImage)
                  ?.getOriginal()
                  ?.getUrl() ?? "",
            };
            return <Tile {...tileData} key={index} />;
          })}
        </ul>
      </div>
    </article>
  );
}
