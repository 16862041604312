import { useRef } from "react";

export function useDraggable(containerRef: React.RefObject<HTMLUListElement>) {
  const posRef = useRef({ top: 0, left: 0, x: 0, y: 0 });

  function handleMouseDown(event: React.MouseEvent<HTMLElement>) {
    if (containerRef.current) {
      containerRef.current.style.userSelect = "none";

      posRef.current = {
        left: containerRef.current.scrollLeft,
        top: containerRef.current.scrollTop,
        x: event.clientX,
        y: event.clientY,
      };

      containerRef.current.addEventListener("mousemove", mouseMoveHandler);
      containerRef.current.addEventListener("mouseup", mouseUpHandler);
    }
  }

  function mouseMoveHandler(e: MouseEvent) {
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    if (containerRef.current) {
      containerRef.current.scrollTop = posRef.current.top - dy;
      containerRef.current.scrollLeft = posRef.current.left - dx;
    }
  }

  const mouseUpHandler = function () {
    if (containerRef.current) {
      containerRef.current.style.removeProperty("user-select");
    }

    containerRef.current?.removeEventListener("mousemove", mouseMoveHandler);
    containerRef.current?.removeEventListener("mouseup", mouseUpHandler);
  };

  return handleMouseDown;
}
