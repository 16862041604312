import { removeQueryParam } from "services/removeQueryParam";
export function getReturnUrl(pathOverride?: string): string {
  if (pathOverride) {
    return encodeURIComponent(pathOverride);
  }
  const pathname = window.location.pathname;
  const path = pathname + window.location.search;
  if (
    pathname === "/services/printing/account/register/" ||
    pathname === "/services/printing/account/login/"
  ) {
    return encodeURIComponent("/services/printing/");
  } else {
    // Remove any tracking parameters
    const sanatizedUrl = removeQueryParam(path, "utm_");
    return encodeURIComponent(sanatizedUrl);
  }
}
