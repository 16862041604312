import { Section } from "./Section";
import styles from "./TopIcons.module.scss";
import { TopIconsProps } from "./types";

export const TopIcons = ({ data, page }: TopIconsProps) => {
  const { title, card, iconDimensions } = data;

  return (
    <article className={styles.container}>
      <h2 className={styles.title} data-testid="value-title">
        {title}
      </h2>
      <section data-testid="value-props-content" className={styles.content}>
        {card?.map((tile, index) => {
          return (
            <Section
              key={index}
              data={tile}
              page={page}
              iconDimensions={iconDimensions}
            />
          );
        })}
      </section>
    </article>
  );
};
