import Image from "next/image";
import { BrRichTextContent } from "../BrRichTextContent";
import { linkAttributes } from "services/linkAttributes";
import clsx from "clsx";
import styles from "./TextBlog.module.scss";
import { ContainerProps, dropdownProps } from "./types";
import { getClassNames } from "services/getClassNames";
import { TextBlogTagManager } from "./TextBlogTagManager";
import { useContext } from "react";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";
import { ClipboardButton } from "../utils/ClipboardButton";
import { useResize } from "hooks/useResize";

export const Container = ({ page, data }: ContainerProps) => {
  // Data
  const {
    header,
    paragraph,
    quote,
    cta,
    background,
    textColor,
    textSize,
    layout,
    code,
  } = data;
  const { red, green, blue, alpha } = background;

  const choiceTemplate = (instance: dropdownProps) => {
    return instance.selectionValues[0].key;
  };
  const textColorChoice = choiceTemplate(textColor);
  const textSizeChoice = choiceTemplate(textSize);
  const layoutChoice = choiceTemplate(layout);

  const ctaTitle = cta?.ctaContent.title;
  const ctaStyle = cta?.ctaStyle.selectionValues[0].key;
  const windowSize = useResize();

  const colorStyles = {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    color: textColorChoice === "true" ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)",
  };
  const quoteStyles = {
    color:
      textColorChoice === "true"
        ? "rgba(125,125,125,1)"
        : "rgba(255,255,255,1)",
  };

  const arrowColor =
    textColorChoice === "true"
      ? "/services/printing/static-pages/icons/arrow-link.svg"
      : "/services/printing/static-pages/icons/arrow-link-white.svg";

  const copyColor =
    textColorChoice === "true"
      ? "/services/printing/static-pages/icons/copy-black.svg"
      : "/services/printing/static-pages/icons/copy-white.svg";

  const tooltipPosition =
    textSizeChoice === "Small"
      ? { x: 10, y: 8 }
      : textSizeChoice === "Medium"
      ? { x: 12, y: 10 }
      : textSizeChoice === "Large"
      ? windowSize < 400
        ? { x: -30, y: 10 }
        : { x: 12, y: 10 }
      : null;

  const linkProps = cta
    ? linkAttributes(cta?.ctaContent.address, cta?.ctaContent.newTab)
    : null;

  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const TagManagerProps = {
    tagPage: tagPage,
    taggedComponent: "Text Blog Promo Code",
    taggedElement: "Text Blog Description Text",
  };

  return (
    <article className={styles.wrapper}>
      <div
        data-testid="container"
        style={colorStyles}
        className={clsx(
          getClassNames(
            ["container", `containerStyle${layoutChoice.slice(-1)}`],
            styles
          )
        )}
      >
        {header && (
          <h3
            className={clsx(
              getClassNames(
                [
                  "header",
                  `headerStyle${layoutChoice.slice(-1)}`,
                  `header${textSizeChoice}`,
                ],
                styles
              )
            )}
          >
            {header}
          </h3>
        )}

        <section
          className={clsx(
            getClassNames(
              ["content", `contentStyle${layoutChoice.slice(-1)}`],
              styles
            )
          )}
        >
          {paragraph && (
            <div
              className={clsx(
                getClassNames(
                  ["paragraph", `paragraph${textSizeChoice}`],
                  styles
                )
              )}
            >
              <BrRichTextContent
                page={page!}
                content={{ html: paragraph.value }}
              />
            </div>
          )}
          {code && (
            <div
              className={clsx(
                styles.clipboardButtonWrapper,
                getClassNames(
                  ["paragraph", `paragraph${textSizeChoice}`], // Merged classes
                  styles
                )
              )}
            >
              <p>Coupon code: </p>
              <ClipboardButton
                code={code}
                {...TagManagerProps}
                textSize={tooltipPosition}
              >
                <div className={styles.codeContainer}>
                  <span
                    data-testid="code"
                    className={clsx(
                      styles.copyCode,
                      {
                        [styles.copyCodeWhite]: textColorChoice !== "true",
                      },

                      getClassNames(
                        ["paragraph", `paragraph${textSizeChoice}`],
                        styles
                      )
                    )}
                  >
                    {code}
                  </span>
                  <div
                    className={clsx(
                      getClassNames(
                        ["copyIcon", `copyIcon${textSizeChoice}`],
                        styles
                      )
                    )}
                  >
                    <Image src={copyColor} alt="" fill />
                  </div>
                </div>
              </ClipboardButton>
            </div>
          )}

          {quote && (
            <p
              className={clsx(
                getClassNames(
                  ["quote", `quoteStyle${layoutChoice.slice(-1)}`],
                  styles
                )
              )}
              style={quoteStyles}
            >
              - {quote}
            </p>
          )}

          {ctaTitle && (
            <a
              {...linkProps}
              data-testid="link"
              onClick={() => TextBlogTagManager(ctaTitle, tagPage)}
              className={clsx(styles.cta, {
                [styles.ctaLink]: ctaStyle === "Link",
                [styles.ctaPrimary]: ctaStyle === "Primary Button",
                [styles.ctaSecondary]: ctaStyle === "Secondary Button",
              })}
            >
              {ctaTitle}
              {ctaStyle === "Link" && (
                <div className={styles.ctaLinkArrow}>
                  <Image
                    src={arrowColor}
                    alt=""
                    width={19}
                    height={14}
                    sizes="100%"
                  />
                </div>
              )}
            </a>
          )}
        </section>
      </div>
    </article>
  );
};
