import Image from "next/image";
import clsx from "clsx";
import styles from "./Utils.module.scss";
import { Divider } from "@/components/utils/Divider";
import {
  ChevronProps,
  CtaProps,
  SidebarHeadProps,
  RotateProps,
  StaplesProps,
  BackButtonProps,
} from "./types";
import { closeMenuButton } from "@/constants/index";
import { tagManager } from "../tagManager";

interface DialogProps {
  dialogOpen: () => void;
}

export const ArrowIcon = ({ size, rotateAngle, strokeWidth }: RotateProps) => {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`,
        transform: `rotate(${rotateAngle}deg)`,
      }}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="arrow-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0156 6.86719L8.01563 10.8672L4.01562 6.86719"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={strokeWidth || 1}
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export const Chevron = ({
  angle,
  isActive,
  size,
  strokeWidth,
}: ChevronProps) => {
  function ChevronSvg({ rotateAngle, size }: RotateProps) {
    return (
      <div
        className={clsx(styles.chevronSvgContainer, {
          [styles.chevronSvgContainerActive]: isActive,
        })}
        data-testid="chevron"
      >
        <ArrowIcon
          size={size}
          rotateAngle={rotateAngle}
          strokeWidth={strokeWidth}
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.chevron, {
        [styles.chevronActive]: isActive,
      })}
    >
      <ChevronSvg rotateAngle={angle} size={size} />
    </div>
  );
};

export const SidebarHead = ({
  children,
  dialogOpen,
  clickFunction,
}: SidebarHeadProps) => {
  return (
    <>
      <section className={styles.headContainer}>
        <div className={styles.headContent} onClick={clickFunction}>
          {children}
        </div>
        <CloseButton dialogOpen={dialogOpen} />
      </section>
      <div className={styles.headDivider}>
        <Divider marginVar={0} />
      </div>
    </>
  );
};

export const CloseButton = ({ dialogOpen }: DialogProps) => {
  return (
    <button className={styles.closeButton} onClick={dialogOpen}>
      <div className={styles.closeButtonIcon}>
        <Image
          src={closeMenuButton}
          alt=""
          fill
          sizes="(min-width: 375px) 100vw"
        />
      </div>
    </button>
  );
};

export const BackButton = ({ prompt }: BackButtonProps) => {
  return (
    <button className={styles.backButton}>
      <Chevron angle={90} size={30} strokeWidth={2} />

      <span>{prompt ? prompt : "Back to main"} </span>
    </button>
  );
};

export const BlueCta = ({ ctaLink }: CtaProps) => {
  return (
    <div className={styles.cta}>
      <a
        href={ctaLink.address}
        target={ctaLink.newTab ? "_blank" : "_self"}
        rel="noopener noreferrer"
        className={styles.link}
        onClick={() => tagManager(`${ctaLink.parentItem} > ${ctaLink.title}`)}
      >
        {ctaLink.title}
      </a>
    </div>
  );
};

export const StaplesLinkSection = ({ caption, parentItem }: StaplesProps) => {
  return (
    <div className={styles.staples}>
      <a
        className={styles.staplesLink}
        href="https://www.staples.com/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => tagManager(`${parentItem} > Staples.com`)}
      >
        Staples.com
        <span className="visuallyhidden">opens in a new tab</span>
      </a>
      {caption && <p className={styles.staplesCaption}>{caption}</p>}
    </div>
  );
};
