export const CUSTOM_SEARCH_ROUTES: { [key: string]: string } = {
  "business cards": "business-cards/",
  "business card": "business-cards/",
  "creative business cards": "business-cards/",
  "professional business cards": "business-cards/",
  businesscards: "business-cards/",
  "Custom Business Cards": "business-cards/",
  "business card templates": "business-cards/",
  "signs banners posters": "signs-banners-posters/",
  "sign banners": "signs-banners-posters/",
  "sign posters": "signs-banners-posters/",
  "sign poster": "signs-banners-posters/",
  "signs banners": "signs-banners-posters/",
  "banners posters": "signs-banners-posters/",
  bannersign: "signs-banners-posters/",
  postersign: "signs-banners-posters/",
  signs: "signs/",
  sign: "signs/",
  sings: "signs/",
  cards: "cards-invitations-announcements/",
  card: "cards-invitations-announcements/",
  crads: "cards-invitations-announcements/",
  invitations: "custom-invitations/",
  invites: "custom-invitations/",
  invitation: "custom-invitations/",
  "custom invitation": "custom-invitations/",
  "custo invites": "custom-invitations/",
  stationery: "custom-stationery/",
  "custom stationery": "custom-stationery/",
  "custom notepads": "notepads",
  "personalized notepads": "notepads",
  Notepads: "notepads",
  envelopes: "custom-envelopes/",
  envelope: "custom-envelopes/",
  enclosure: "custom-envelopes/",
  "mailing labels": "same-day-services/return-address-labels/",
  "mail labels": "same-day-services/return-address-labels/",
  "mail label": "same-day-services/return-address-labels/",
  "mailing lables": "same-day-services/return-address-labels/",
  "mailing lable": "same-day-services/return-address-labels/",
  "envelope labels": "same-day-services/return-address-labels/",
  "mailing label": "same-day-services/return-address-labels/",
  "industrial labels": "custom-labels-stickers/industrial-labels/",
  "industrial label": "custom-labels-stickers/industrial-labels/",
  "outdoor labels": "custom-labels-stickers/industrial-labels/",
  "outdoor label": "custom-labels-stickers/industrial-labels/",
  "foil label": "custom-labels-stickers/foil-embossed-labels/",
  "foil labels": "custom-labels-stickers/foil-embossed-labels/",
  "embossed label": "custom-labels-stickers/foil-embossed-labels/",
  "embossed labels": "custom-labels-stickers/computer-labels/",
  "computer label": "custom-labels-stickers/computer-labels/",
  "computer labels": "custom-labels-stickers/computer-labels/",
  "pin-fed label": "custom-labels-stickers/computer-labels/",
  "pin-fed labels": "custom-labels-stickers/computer-labels/",
  "pinfed label": "custom-labels-stickers/computer-labels/",
  "pinfed labels": "custom-labels-stickers/computer-labels/",
  "advertising labels": "custom-labels-stickers/advertising-product-labels/",
  "advertising label": "custom-labels-stickers/advertising-product-labels/",
  "product label": "custom-labels-stickers/advertising-product-labels/",
  "product labels": "custom-labels-stickers/advertising-product-labels/",
  "bottle labels": "custom-labels-stickers/bottle-labels/",
  "bottle label": "custom-labels-stickers/bottle-labels/",
  "water bottle label": "custom-labels-stickers/bottle-labels/",
  "water bottle labels": "custom-labels-stickers/bottle-labels/",
  "cling sticker": "custom-labels-stickers/cling-stickers-decals/",
  "cling stickers": "custom-labels-stickers/cling-stickers-decals/",
  "product decal": "custom-labels-stickers/cling-stickers-decals/",
  "product decals": "custom-labels-stickers/cling-stickers-decals/",
  labels: "custom-labels-stickers/",
  label: "custom-labels-stickers/",
  lables: "custom-labels-stickers/",
  lable: "custom-labels-stickers/",
  "return label": "same-day-services/return-address-labels/",
  "return labels": "same-day-services/return-address-labels/",
  "address labels": "same-day-services/return-address-labels/",
  "address label": "same-day-services/return-address-labels/",
  "return address labels": "same-day-services/return-address-labels/",
  "return address label": "same-day-services/return-address-labels/",
  sales: "sales-marketing/",
  sale: "sales-marketing/",
  marketting: "sales-marketing/",
  marketing: "sales-marketing/",
  "sales marketing": "sales-marketing/",
  magnets: "sales-marketing/marketing-magnets/",
  magnet: "sales-marketing/marketing-magnets/",
  maganets: "sales-marketing/marketing-magnets/",
  maganet: "sales-marketing/marketing-magnets/",
  "photo gifts": "photo-gifts/",
  "phot gifts": "photo-gifts/",
  "phoot gifts": "photo-gifts/",
  "photo gift": "photo-gifts/",
  photogifts: "photo-gifts/",
  "custom photo gifts": "photo-gifts/",
  "Personalized photo gifts": "photo-gifts/",
  "wall art": "photo-gifts/custom-wall-art/",
  wallart: "photo-gifts/custom-wall-art/",
  "wall arte": "photo-gifts/custom-wall-art/",
  art: "photo-gifts/custom-wall-art/",
  "home decor": "photo-gifts/",
  "home decore": "photo-gifts/",
  homedecor: "photo-gifts/",
  "home design": "photo-gifts/",
  homedecore: "photo-gifts/",
  "holiday cards": "custom-holiday-cards/",
  "holiday card": "custom-holiday-cards/",
  "same day": "same-day-services/",
  sameday: "same-day-services/",
  "same days": "same-day-services/",
  "same day delivery": "same-day-services/",
  "one day delivery": "same-day-services/",
  calender: "photo-gifts/personalized-calendars/",
  calenders: "photo-gifts/personalized-calendars/",
  callendars: "photo-gifts/personalized-calendars/",
  callendar: "photo-gifts/personalized-calendars/",
  calendar: "photo-gifts/personalized-calendars/",
  stamps: "custom-stamps/",
  stamp: "custom-stamps/",
  "custom stamp": "custom-stamps/",
  "custom stamps": "custom-stamps/",
  "self inking stamp": "custom-stamps/",
  "self inking stamps": "custom-stamps/",
  "self-inking stamp": "custom-stamps/",
  "self-inking stamps": "custom-stamps/",
  "pre inked stamp": "custom-stamps/",
  "pre inked stamps": "custom-stamps/",
  "pre-inked stamp": "custom-stamps/",
  "pre-inked stamps": "custom-stamps/",
  "rubber stamp": "custom-stamps/rubber-stamps/",
  "rubber stamps": "custom-stamps/rubber-stamps/",
  "date stamp": "custom-stamps/date-stamps/",
  "date stamps": "custom-stamps/date-stamps/",
  "same day stamp": "same-day-services/stamps/",
  "same day stamps": "same-day-services/stamps/",
  embosser: "custom-stamps/embossers/",
  embossers: "custom-stamps/embossers/",
  "yard signs": "yard-lawn-signs/",
  yardsigns: "yard-lawn-signs/",
  "yard sign": "yard-lawn-signs/",
  yardsign: "yard-lawn-signs/",
  "yard sings": "yard-lawn-signs/",
  yardsings: "yard-lawn-signs/",
  "custom yard signs": "yard-lawn-signs/",
  "personalized yard signs": "yard-lawn-signs/",
  birthday: "custom-invitations/birthday-party-invitations/",
  "thank you": "custom-thank-you-cards/",
  "thank you cards": "custom-thank-you-cards/",
  wedding: "custom-invitations/wedding-invitations/",
  weddings: "custom-invitations/wedding-invitations/",
  "wedding invitations": "custom-invitations/wedding-invitations/",
  "notary stamp": "custom-stamps/notary-stamps/",
  notary: "custom-stamps/notary-stamps/",
  ornaments: "photo-gifts/",
  ornament: "photo-gifts/",
  blankets: "photo-gifts/",
  banners: "custom-banners/",
  banner: "custom-banners/",
  baners: "custom-banners/",
  baner: "custom-banners/",
  "trade show banner": "custom-banners/",
  "tradeshow banner": "custom-banners/",
  "trade show banners": "custom-banners/",
  "tradeshow banners": "custom-banners/",
  posters: "posters/",
  poster: "posters/",
  "adhesive posters": "adhesive-posters/",
  "adhesive poster": "adhesive-posters/",
  "sticky posters": "adhesive-posters/",
  "sticky poster": "adhesive-posters/",
  "foam board posters": "foam-boards/",
  "foam board poster": "foam-boards/",
  "foamboard posters": "foam-boards/",
  "foamboard poster": "foam-boards/",
  "foam board": "foam-boards/",
  "foam boards": "foam-boards/",
  foamboard: "foam-boards/",
  foamboards: "foam-boards/",
  "same day poster": "same-day-services/posters/",
  "same day posters": "same-day-services/posters/",
  "sameday poster": "same-day-services/posters/",
  "sameday posters": "same-day-services/posters/",
  "professional posters": "posters/",
  "custom posters": "posters/",
  "professional poster": "posters/",
  "custom poster": "posters/",
  customposter: "posters/",
  "notary stamps": "custom-stamps/notary-stamps/",
  "printed shirt": "promotional-products/apparel/custom-t-shirts/",
  "retractable badge holder": "promotional-products/tradeshow/",
  "retractable badge reel": "promotional-products/tradeshow/",
  "logo shirt": "promotional-products/apparel/custom-t-shirts/",
  "promotional products": "promotional-products/",
  "embroidered hats": "promotional-products/apparel/custom-caps-and-hats/",
  "printed tshirt": "promotional-products/apparel/custom-t-shirts/",
  "personalized mug": "promotional-products/drinkware/",
  "printed t-shirt": "promotional-products/apparel/custom-t-shirts/",
  "customized hats": "promotional-products/apparel/custom-caps-and-hats/",
  "printed t shirt": "promotional-products/apparel/custom-t-shirts/",
  "custom mouse pads": "promotional-products/technology/",
  "personalized blanket": "promotional-products/outdoor-sports/",
  "custom mug": "promotional-products/drinkware/",
  "personalized coffee mug": "promotional-products/drinkware/",
  "personalized wine glass": "promotional-products/drinkware/",
  "engraved pen": "promotional-products/writing-instruments/",
  "customized mug": "promotional-products/drinkware/",
  "customized pen": "promotional-products/writing-instruments/",
  "customized glassware": "promotional-products/drinkware/",
  "personalized keychain": "promotional-products/tradeshow/",
  "printed balloon": "promotional-products/outdoor-sports/",
  "customized blankets": "promotional-products/outdoor-sports/",
  "personalized pencil": "promotional-products/writing-instruments/",
  "custom planners": "promotional-products/custom-calendars-planners/",
  "customized keychain": "promotional-products/tradeshow/",
  "customized coffee mug": "promotional-products/drinkware/",
  "personalized pen": "promotional-products/writing-instruments/",
  "personalized cup": "promotional-products/drinkware/",
  "customized notebooks": "promotional-products/office-accessories/",
  "custom hats": "promotional-products/apparel/custom-caps-and-hats/",
  "customized sunglasses": "promotional-products/outdoor-sports/",
  "personalized glassware": "promotional-products/drinkware/",
  "personalized tote bag": "promotional-products/bags-totes/",
  "customized cup": "promotional-products/drinkware/",
  "staples promotional products": "promotional-products/",
  "personalized key chains": "promotional-products/tradeshow/",
  "custom pen": "promotional-products/writing-instruments/",
  "custom clothes": "promotional-products/apparel/",
  "customized coasters": "promotional-products/drinkware/",
  "custom hoodie": "promotional-products/apparel/custom-sweatshirts/",
  "personalized travel mug": "promotional-products/drinkware/",
  "customized clothes": "promotional-products/apparel/",
  "personalized sunglasses": "promotional-products/outdoor-sports/",
  "custom notebooks": "promotional-products/office-accessories/",
  "customized beanies": "promotional-products/apparel/custom-caps-and-hats/",
  "custom jacket": "promotional-products/apparel/custom-outerwear/",
  "custom journals": "promotional-products/office-accessories/",
  "personalized hats": "promotional-products/apparel/custom-caps-and-hats/",
  "Promo Items": "promotional-products/",
  "custom hard hat": "promotional-products/apparel/custom-caps-and-hats/",
  "custom glassware": "promotional-products/drinkware/",
  "custom cup": "promotional-products/drinkware/",
  "custom embroidered hats":
    "promotional-products/apparel/custom-caps-and-hats/",
  "customized clothing": "promotional-products/apparel/",
  "customized jacket": "promotional-products/apparel/custom-outerwear/",
  "customized cooler": "promotional-products/bags-totes/",
  "customized pencil": "promotional-products/writing-instruments/",
  "custom sweatshirt": "promotional-products/apparel/custom-sweatshirts/",
  "custom flash drives": "promotional-products/technology/",
  "personalized balloon": "promotional-products/outdoor-sports/",
  "printed paper bag": "promotional-products/bags-totes/",
  "customized sweatshirt": "promotional-products/apparel/custom-sweatshirts/",
  "customized wine glass": "promotional-products/drinkware/",
  "customized apparel": "promotional-products/apparel/",
  "promotional staples": "promotional-products/",
  "personalized gift bag": "promotional-products/bags-totes/",
  "custom clothing": "promotional-products/apparel/",
  "customized notepads": "promotional-products/office-accessories/",
  "custom sweater": "promotional-products/apparel/custom-sweatshirts/",
  "tradeshow giveaways": "promotional-products/tradeshow/",
  "personalized plastic cup": "promotional-products/drinkware/",
  "customized visors": "promotional-products/apparel/custom-caps-and-hats/",
  "customized chargers": "promotional-products/technology/",
  "customized baseball caps":
    "promotional-products/apparel/custom-caps-and-hats/",
  "custom baseball hats": "promotional-products/apparel/custom-caps-and-hats/",
  "custom coasters": "promotional-products/drinkware/",
  "staples promotional": "promotional-products/",
  "custom uniforms": "promotional-products/apparel/",
  "logo balloon": "promotional-products/outdoor-sports/",
  staplespromotionalproducts: "promotional-products/",
  "staples promotional items": "promotional-products/",
  "staples custom pens": "promotional-products/writing-instruments/",
  apparel: "promotional-products/apparel/",
  "t-shirt": "promotional-products/apparel/custom-t-shirts/",
  jacket: "promotional-products/apparel/custom-outerwear/",
  vest: "promotional-products/apparel/custom-outerwear/",
  outerwear: "promotional-products/apparel/custom-outerwear/",
  giveaways: "promotional-products/tradeshow/",
  keychains: "promotional-products/tradeshow/",
  lanyards: "promotional-products/tradeshow/",
  badges: "promotional-products/tradeshow/",
  "tradeshow ": "promotional-products/tradeshow/",
  "promo products": "promotional-products/",
  "branded products": "promotional-products/",
  hats: "promotional-products/apparel/custom-caps-and-hats/",
  caps: "promotional-products/apparel/custom-caps-and-hats/",
  mugs: "promotional-products/drinkware/",
  cups: "promotional-products/drinkware/",
  glasses: "promotional-products/drinkware/",
  drinkware: "promotional-products/drinkware/",
  "coffee mug": "promotional-products/drinkware/",
  flashdrive: "promotional-products/technology/",
  "usb accessories": "promotional-products/technology/",
  headphones: "promotional-products/technology/",
  speakers: "promotional-products/technology/",
  "phone accessories": "promotional-products/technology/",
  chargers: "promotional-products/technology/",
  pens: "promotional-products/writing-instruments/",
  penciles: "promotional-products/writing-instruments/",
  "writing instruments": "promotional-products/writing-instruments/",
  calendars: "promotional-products/custom-calendars-planners/",
  planners: "promotional-products/custom-calendars-planners/",
  notebooks: "promotional-products/office-accessories/",
  padfolio: "promotional-products/office-accessories/",
  "desk accessories": "promotional-products/office-accessories/",
  journals: "promotional-products/office-accessories/",
  notepads: "promotional-products/office-accessories/",
  sweatshirt: "promotional-products/apparel/custom-sweatshirts/",
  backpacks: "promotional-products/bags-totes/",
  briefcases: "promotional-products/bags-totes/",
  "messenger bags": "promotional-products/bags-totes/",
  bags: "promotional-products/bags-totes/",
  luggage: "promotional-products/bags-totes/",
  totes: "promotional-products/bags-totes/",
  "cooler bags": "promotional-products/bags-totes/",
  "duffle bags": "promotional-products/bags-totes/",
  "gym bags": "promotional-products/bags-totes/",
  blanket: "promotional-products/outdoor-sports/",
  balloon: "promotional-products/outdoor-sports/",
  "folding chair": "promotional-products/outdoor-sports/",
  sunglasses: "promotional-products/outdoor-sports/",
  umbrella: "promotional-products/outdoor-sports/",
  "gift set": "promotional-products/gift-sets/",
  coasters: "promotional-products/drinkware/",
  koozies: "promotional-products/drinkware/",
  "engineer prints": "engineering-blueprints/",
  "engineer print": "engineering-blueprints/",
  "engineering prints": "engineering-blueprints/",
  "engineering print": "engineering-blueprints/",
  blueprints: "engineering-blueprints/",
  blueprint: "engineering-blueprints/",
  "blue prints": "engineering-blueprints/",
  "blue print": "engineering-blueprints/",
  printing: "copies-documents-printing/",
  copies: "copies-documents-printing/",
  print: "copies-documents-printing/",
  prints: "copies-documents-printing/",
  "print service": "copies-documents-printing/",
  "printing service": "copies-documents-printing/",
  booklet: "copies-documents-printing/booklets/",
  "color copies": "copies-documents-printing/",
  "card stock": "copies-documents-printing/",
  copy: "copies-documents-printing/",
  cardstock: "copies-documents-printing/",
  "cardstock printing": "copies-documents-printing/",
  booklets: "copies-documents-printing/booklets/",
  resume: "copies-documents-printing/",
  resumes: "copies-documents-printing/",
  "color printing": "copies-documents-printing/",
  "11x17": "copies-documents-printing/",
  "11 x 17": "copies-documents-printing/",
  "printing services": "copies-documents-printing/",
  "document printing": "copies-documents-printing/",
  documents: "copies-documents-printing/",
  book: "copies-documents-printing/",
  books: "copies-documents-printing/",
  copying: "copies-documents-printing/",
  "copy and print": "copies-documents-printing/",
  document: "copies-documents-printing/",
  "resume printing": "copies-documents-printing/",
  "copy center": "copies-documents-printing/",
  "copy centers": "copies-documents-printing/",
  "color print": "copies-documents-printing/",
  "color prints": "copies-documents-printing/",
  "color copy": "copies-documents-printing/",
  "print document": "copies-documents-printing/",
  "print documents": "copies-documents-printing/",
  "print services": "copies-documents-printing/",
  "booklet printing": "copies-documents-printing/booklets/",
  "booklets printing": "copies-documents-printing/booklets/",
  "print from email": "copies-documents-printing/",
  "book binding": "copies-documents-printing/",
  "8.5x11": "copies-documents-printing/",
  "8.5 x 11": "copies-documents-printing/",
  "black and white": "copies-documents-printing/",
  "print copies": "copies-documents-printing/",
  "cardtock printing": "copies-documents-printing/",
  "resume paper": "copies-documents-printing/",
  "self printing": "copies-documents-printing/",
  "8.5x14": "copies-documents-printing/",
  "8.5 x 14": "copies-documents-printing/",
  ledger: "copies-documents-printing/",
  stapling: "copies-documents-printing/",
  "simple print": "copies-documents-printing/simple-prints/",
  "simple prints": "copies-documents-printing/simple-prints/",
  laminate: "copies-documents-printing/professional-print/",
  laminating: "copies-documents-printing/professional-print/",
  "laminating service": "copies-documents-printing/professional-print/",
  binding: "copies-documents-printing/professional-print/",
  bindings: "copies-documents-printing/professional-print/",
  lamination: "copies-documents-printing/professional-print/",
  binders: "copies-documents-printing/professional-print/",
  binder: "copies-documents-printing/professional-print/",
  presentation: "copies-documents-printing/professional-print/",
  presentations: "copies-documents-printing/professional-print/",
  "spiral binding": "copies-documents-printing/professional-print/",
  "spiral bindings": "copies-documents-printing/professional-print/",
  laminated: "copies-documents-printing/professional-print/",
  transparency: "copies-documents-printing/professional-print/",
  spiral: "copies-documents-printing/professional-print/",
  "spiral bound": "copies-documents-printing/professional-print/",
  "laminating services": "copies-documents-printing/professional-print/",
  "professional print": "copies-documents-printing/professional-print/",
  "professional prints": "copies-documents-printing/professional-print/",
  "comb bind": "copies-documents-printing/professional-print/",
  "comb binds": "copies-documents-printing/professional-print/",
  "hole punch": "copies-documents-printing/professional-print/",
  displays: "promotional-products/displays/",
  display: "promotional-products/displays/",
  "feather flags": "promotional-products/displays/",
  "feather flag": "promotional-products/displays/",
  featherflags: "promotional-products/displays/",
  featherflag: "promotional-products/displays/",

  canvas: "photo-gifts/custom-wall-art/canvas-prints/",
  "canvas prints": "photo-gifts/custom-wall-art/canvas-prints/",
  "canvas print": "photo-gifts/custom-wall-art/canvas-prints/",
  "christmas cards": "custom-holiday-cards/merry-christmas/",
  "christmas card": "custom-holiday-cards/merry-christmas/",
  "appointment cards": "/business-cards/appointment-cards/",
  "appointment card": "/business-cards/appointment-cards/",
  "magnetic signs": "magnetic-signs/",
  "magnetic sign": "magnetic-signs/",
  magneticsigns: "magnetic-signs/",
  magneticsign: "magnetic-signs/",
  "magnetic sings": "magnetic-signs/",
  "magnetic sing": "magnetic-signs/",
  "business card magnets":
    "sales-marketing/marketing-magnets/business-card-magnets/",
  "business card magnet":
    "sales-marketing/marketing-magnets/business-card-magnets/",
  "window decals": "window-decals/",
  decals: "window-decals/",
  "window decal": "window-decals/",
  windowdecal: "window-decals/",
  "window deckal": "window-decals/",
  "window deckals": "window-decals/",
  "window decls": "window-decals/",
  letterhead: "letterhead/",
  "letter head": "letterhead/",
  "custom letterhead": "letterhead/",
  "personalized letterhead": "letterhead/",
  "personalized letter head": "letterhead/",
  "custom letter head": "letterhead/",
  postcards: "sales-marketing/custom-postcards/",
  "post cards": "sales-marketing/custom-postcards/",
  brochure: "sales-marketing/brochures/",
  postcard: "sales-marketing/custom-postcards/",
  "post card": "sales-marketing/custom-postcards/",
  "custom postcards": "sales-marketing/custom-postcards/",
  "custom post cards": "sales-marketing/custom-postcards/",
  "custom postcard": "sales-marketing/custom-postcards/",
  "car magnets": "sales-marketing/marketing-magnets/car-door-magnets/",
  "car magnet": "sales-marketing/marketing-magnets/car-door-magnets/",
  brochures: "sales-marketing/brochures/",
  flyers: "sales-marketing/flyers/",
  flyer: "sales-marketing/flyers/",
  fliers: "sales-marketing/flyers/",
  flier: "sales-marketing/flyers/",
  "custom flyers": "sales-marketing/flyers/",
  customflyers: "sales-marketing/flyers/",
  "custom flyer": "sales-marketing/flyers/",
  customflyer: "sales-marketing/flyers/",
  "presentation folders": "sales-marketing/custom-folders/",
  presentationfolders: "sales-marketing/custom-folders/",
  "presentation folder": "sales-marketing/custom-folders/",
  folder: "sales-marketing/custom-folders/",
  folders: "sales-marketing/custom-folders/",
  "custom presentation folders": "sales-marketing/custom-folders/",
  "custom presentation folder": "sales-marketing/custom-folders/",
  "business folders": "sales-marketing/custom-folders/",
  "corporate presentation folders": "sales-marketing/custom-folders/",
  "corporate presentation folder": "sales-marketing/custom-folders/",
  "name tags": "sales-marketing/name-tags-badges/",
  nametags: "sales-marketing/name-tags-badges/",
  "name tag": "sales-marketing/name-tags-badges/",
  nametag: "sales-marketing/name-tags-badges/",
  "name badge": "sales-marketing/name-tags-badges/",
  "name badges": "sales-marketing/name-tags-badges/",
  "custom name tags": "sales-marketing/name-tags-badges/",
  "cuatom nametags": "sales-marketing/name-tags-badges/",
  "cuatom nametag": "sales-marketing/name-tags-badges/",
  "bumper stickers": "sales-marketing/bumper-stickers/",
  stickers: "custom-labels-stickers/",
  sticker: "custom-labels-stickers/",
  bumperstickers: "sales-marketing/bumper-stickers/",
  "bumper sticker": "sales-marketing/bumper-stickers/",
  bumpersticker: "sales-marketing/bumper-stickers/",
  "personal bumper stickers": "sales-marketing/bumper-stickers/",
  "personalized bumper stickers": "sales-marketing/bumper-stickers/",
  "custom bumper sticker": "sales-marketing/bumper-stickers/",
  "custom bumper stickers": "sales-marketing/bumper-stickers/",
  "rack cards": "sales-marketing/rack-cards/",
  "rack card": "sales-marketing/rack-cards/",
  rackcards: "sales-marketing/rack-cards/",
  "rcak cards": "sales-marketing/rack-cards/",
  "rcak card": "sales-marketing/rack-cards/",
  mug: "photo-gifts/photo-mugs/",
  "custom mugs": "photo-gifts/photo-mugs/",
  "personalized mugs": "photo-gifts/photo-mugs/",
  "coffee mugs": "photo-gifts/photo-mugs/",
  "coffee cup": "photo-gifts/photo-mugs/",
  "coffee cups": "photo-gifts/photo-mugs/",
  "personalize mugs": "photo-gifts/photo-mugs/",
  "mouse pads": "photo-gifts/mouse-pads/",
  mousepads: "photo-gifts/mouse-pads/",
  "mouse pad": "photo-gifts/mouse-pads/",
  "custom mouse pad": "photo-gifts/mouse-pads/",
  "customized mouse pads": "photo-gifts/mouse-pads/",
  "personalized mouse pads": "photo-gifts/mouse-pads/",
  "phone cases": "photo-gifts/cell-phone-cases/",
  "phone case": "photo-gifts/cell-phone-cases/",
  "personal phone cases": "photo-gifts/cell-phone-cases/",
  "personalized phone case": "photo-gifts/cell-phone-cases/",
  "personalized phone cases": "photo-gifts/cell-phone-cases/",
  "iphone case": "photo-gifts/cell-phone-cases/",
  "custom iphone case": "photo-gifts/cell-phone-cases/",
  "custom samsung case": "photo-gifts/cell-phone-cases/",
  "custom iphone cases": "photo-gifts/cell-phone-cases/",
  "custom samsung cases": "photo-gifts/cell-phone-cases/",
  phonecases: "photo-gifts/cell-phone-cases/",
  "executive business card": "business-cards/executive-business-cards/",
  "executive business cards": "business-cards/executive-business-cards/",
  "engraved wall sign": "engraved-wall-or-door-signs/",
  "engraved wall signs": "engraved-wall-or-door-signs/",
  "engraved desk sign": "custom-engraved-desk-name-plates/",
  "desk sign": "custom-engraved-desk-name-plates/",
  "engraved desk signs": "custom-engraved-desk-name-plates/",
  "engraved name badge":
    "sales-marketing/name-tags-badges/engraved-name-badges/",
  "engraved name badges":
    "sales-marketing/name-tags-badges/engraved-name-badges/",
  "sweet 16": "custom-invitations/birthday-party-invitations/sweet-16/",
  "baby shower": "custom-invitations/baby-shower-invitations/",
  "business card holder": "business-cards/business-card-holders/",
  "birthday banner": "custom-banners/birthday/",
  "birthday banners": "custom-banners/birthday/",
  "ink refills": "custom-stamps/stamp-accessories/",
};
