import { MENU_DATA } from "../utilityMenuHelpers/links";
import { useState, useEffect } from "react";
import { getIsSignedInFromCookie } from "../utilityMenuHelpers/cookies";
import { getReturnUrl } from "../utilityMenuHelpers/getReturnUrl";
import styles from "./UtilityMenuMobile.module.scss";
import { UtilityMenuGreeting } from "../UtilityMenuGreeting";
import { UtilityMenuVerticalList } from "../UtilityMenuVerticalList";
import listStyles from "../UtilityMenuVerticalList/UtilityMenuVerticalList.module.scss";
import { utilityMenuTagManager } from "../utilityMenuHelpers/tagManager";

export function UtilityMenuMobile() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [signInWithReturnUrl, setSignInWithReturnUrl] = useState("");

  useEffect(() => {
    const signedIn = getIsSignedInFromCookie();
    if (signedIn) setIsSignedIn(true);

    const returnUrl = getReturnUrl();
    setSignInWithReturnUrl(`${MENU_DATA.signIn.link}${returnUrl}`);
  }, []);

  const AccountIcon = MENU_DATA.account.icon;
  const ProjectIcon = MENU_DATA.projects.icon;
  const FileIcon = MENU_DATA.files.icon;
  const SignOutIcon = MENU_DATA.signOut.icon;
  const OrdersIcon = MENU_DATA.orders.icon;
  const SignInIcon = MENU_DATA.signIn.icon;
  const HelpIcon = MENU_DATA.helpCenter.icon;

  return (
    <div className={styles.utilityMenuMobile}>
      {isSignedIn && (
        <div className={styles.utilityMenuMobileHeader}>
          <UtilityMenuGreeting />
        </div>
      )}
      <UtilityMenuVerticalList>
        {!isSignedIn && (
          <li>
            <a
              href={signInWithReturnUrl}
              className={listStyles.utilityMenuVerticalListItem}
              onClick={() => utilityMenuTagManager(MENU_DATA.signIn.label)}
            >
              <SignInIcon />
              {MENU_DATA.signIn.label}
            </a>
          </li>
        )}
        {isSignedIn && (
          <>
            <li>
              <a
                href={MENU_DATA.account.link}
                className={listStyles.utilityMenuVerticalListItem}
                onClick={() => utilityMenuTagManager(MENU_DATA.account.label)}
              >
                <AccountIcon />
                {MENU_DATA.account.label}
              </a>
            </li>
            <li>
              <a
                href={MENU_DATA.projects.link}
                className={listStyles.utilityMenuVerticalListItem}
                onClick={() => utilityMenuTagManager(MENU_DATA.projects.label)}
              >
                <ProjectIcon />
                {MENU_DATA.projects.label}
              </a>
            </li>

            {/*My Files Link placeholder. Ready to go. Place to the right position of the list and change icon. */}

            {/* <li>
              <a
                href={MENU_DATA.files.link}
                className={listStyles.utilityMenuVerticalListItem}
                onClick={() => utilityMenuTagManager(MENU_DATA.files.label)}
              >
                <FileIcon />
                {MENU_DATA.files.label}
              </a>
            </li> */}
          </>
        )}
        <li>
          <a
            href={MENU_DATA.orders.link}
            className={listStyles.utilityMenuVerticalListItem}
            onClick={() => utilityMenuTagManager(MENU_DATA.orders.label)}
          >
            <OrdersIcon />
            {MENU_DATA.orders.label}
          </a>
        </li>
        {isSignedIn && (
          <li>
            <a
              href={MENU_DATA.signOut.link}
              className={listStyles.utilityMenuVerticalListItem}
              onClick={() => utilityMenuTagManager(MENU_DATA.signOut.label)}
            >
              <SignOutIcon />
              {MENU_DATA.signOut.label}
            </a>
          </li>
        )}
        <li>
          <a
            href={MENU_DATA.helpCenter.link}
            className={listStyles.utilityMenuVerticalListItem}
            onClick={() =>
              utilityMenuTagManager(MENU_DATA.helpCenter.labelShort)
            }
          >
            <HelpIcon />
            {MENU_DATA.helpCenter.labelShort}
          </a>
        </li>
      </UtilityMenuVerticalList>
      <div className={styles.utilityMenuMobileFooter}>
        <a
          href={`tel:+${MENU_DATA.customerService.link}`}
          className={styles.utilityMenuMobileCall}
          onClick={() => utilityMenuTagManager(MENU_DATA.customerService.label)}
        >
          {MENU_DATA.customerService.label}
        </a>
      </div>
    </div>
  );
}
