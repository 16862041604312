import { ReactElement, useContext, useState } from "react";
import Image from "next/image";
import { BrProps } from "@bloomreach/react-sdk";
import { Document, Page, Reference } from "@bloomreach/spa-sdk";
import { BrRichTextContent } from "../BrRichTextContent";
import { ImageToUrl } from "services/imageToUrl";
import { useBackgroundColor, useBackgroundImage } from "hooks/useBackground";
import { ItemContainer } from "./ItemContainer";
import {
  AccordionDocument,
  AccordionItemDocument,
  AccordionParameters,
  ColorProps,
} from "./types";
import styles from "./Accordion.module.scss";
import clsx from "clsx";
import { AccordionTagManager } from "./AccordionTagManager";
import { PathContext } from "state_management/Contexts";
import { PageCheck } from "services/pageCheck";
import { AccordionScript } from "./AccordionScript";

// Converting component's background with user hooks
const AccordionBackgroundImage = (backgroundImage: Reference) => {
  return useBackgroundImage(backgroundImage);
};
const AccordionBackgroundColor = (backgroundColor: ColorProps) => {
  return useBackgroundColor(backgroundColor);
};

export const Accordion = ({
  component,
  page,
}: BrProps): ReactElement | null => {
  const [openItemId, setOpenItemId] = useState<null | number>(null);

  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  if (!component || !page) {
    return null;
  }

  const { documentContent: documentRef } =
    component.getModels<AccordionParameters>();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return page.isPreview() ? <div>Accordion Document</div> : null;
  }

  const {
    icon,
    content,
    list: dropList,
    backgroundColor,
    backgroundImage,
    textColor,
  } = document.getData<AccordionDocument>();

  const bgColor = AccordionBackgroundColor(backgroundColor);
  const bgImage = AccordionBackgroundImage(backgroundImage);
  const colorText = textColor?.selectionValues[0]?.key;

  const iconUrl = ImageToUrl(icon);

  // Defining Active Section
  const openHandler = (itemId: number, header: string) => {
    if (openItemId === itemId) {
      setOpenItemId(null);
    } else {
      setOpenItemId(itemId);
      AccordionTagManager(header, tagPage);
    }
  };

  const backgroundStyle = bgImage
    ? {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
      }
    : {
        background: bgColor,
      };

  const itemProcessed = (item: Reference) => {
    const document = item && page.getContent<Document>(item);
    if (!document) {
      return null;
    }
    const { id, header, capture, image, list, contentType } =
      document?.getData<AccordionItemDocument>();

    return { id, header, capture, image, list, contentType };
  };

  const scriptData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: dropList.map((item) => {
      const itemSanitized = itemProcessed(item);
      if (!itemSanitized) return {};

      const simpleCapture = itemSanitized.capture?.value;

      const listCapture = () => {
        if (!itemSanitized.list || itemSanitized.list.length === 0) return null;

        const listOfItems = itemSanitized.list.reduce((accumulator, item) => {
          const itemText = `<li><p>${item.header}</p>${item.caption.value}</li>`;
          return accumulator + itemText;
        }, "");

        return listOfItems;
      };

      return {
        "@type": "Question",
        name: itemSanitized.header,
        acceptedAnswer: {
          "@type": "Answer",
          text: simpleCapture ? simpleCapture : `<ul>${listCapture()}</ul>`,
        },
      };
    }),
  };

  return (
    <>
      <AccordionScript data={scriptData} />
      <article className={styles.wrapper}>
        <div className={styles.container} style={backgroundStyle}>
          <header
            className={clsx(styles.header, {
              [styles.headerWhite]: colorText === "White",
            })}
          >
            <div className={styles.headerIcon}>
              {iconUrl && <Image src={iconUrl} alt="" fill />}
            </div>
            <div className={styles.headerCaption}>
              {content && (
                <BrRichTextContent
                  page={page}
                  content={{
                    html: content.value,
                  }}
                />
              )}
            </div>
          </header>
          <ul className={styles.accordion}>
            {dropList.map((item, index) => {
              const itemSanitized = itemProcessed(item);
              if (!itemSanitized) return null;

              const data = {
                id: itemSanitized.id,
                header: itemSanitized.header,
                capture: itemSanitized.capture,
                image: itemSanitized.image,
                list: itemSanitized.list,
                contentType: itemSanitized.contentType,
              };

              return (
                <ItemContainer
                  data={data}
                  index={index}
                  page={page}
                  listLength={dropList ? dropList?.length - 1 : 0}
                  openHandler={() => openHandler(index, itemSanitized.header)}
                  open={openItemId === index}
                  key={index}
                />
              );
            })}
          </ul>
        </div>
      </article>
    </>
  );
};
