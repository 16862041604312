import signInAccountIcon from "@/public/static-pages/icons/navbar/utility-menu/user.svg";
import signInAccountActiveIcon from "@/public/static-pages/icons/navbar/utility-menu/user-active.svg";
import ordersIcon from "@/public/static-pages/icons/navbar/utility-menu/box-taped.svg";
import helpIcon from "@/public/static-pages/icons/navbar/utility-menu/circle-question.svg";
import helpActiveIcon from "@/public/static-pages/icons/navbar/utility-menu/circel-question-active.svg";
import projectIcon from "@/public/static-pages/icons/navbar/utility-menu/my-project.svg";
import fileIcon from "@/public/static-pages/icons/navbar/utility-menu/my-file.svg";
import signOutIcon from "@/public/static-pages/icons/navbar/utility-menu/arrow-right-from-bracket.svg";

const signOutParam = encodeURIComponent(
  `${process.env.NEXT_PUBLIC_SPLUS_BASE_URL}/services/printing/dock2/Account/ExternalPniLogOff`
);

export const MENU_DATA = {
  signIn: {
    label: "Sign In",
    icon: signInAccountIcon,
    iconActive: signInAccountActiveIcon,
    link: "/services/printing/account/login?returnUrl=",
  },
  account: {
    label: "My Account",
    labelShort: "Account",
    icon: signInAccountIcon,
    iconActive: signInAccountActiveIcon,
    link: "/services/printing/MyAccount",
  },
  register: {
    label: "Create Account",
    link: "/services/printing/account/register?returnUrl=",
  },
  projects: {
    label: "My Projects",
    icon: projectIcon,
    link: "/services/printing/MyProjects",
  },
  files: {
    label: "My Files",
    icon: fileIcon,
    link: "/services/printing/myfiles",
  },
  orders: {
    label: "Order History & Tracking",
    icon: ordersIcon,
    link: "/services/printing/OrderStatus",
  },
  helpCenter: {
    label: "Help Center",
    labelShort: "Help",
    icon: helpIcon,
    iconActive: helpActiveIcon,
    link: "/services/printing/knowledgebase",
  },
  signOut: {
    label: "Sign Out",
    icon: signOutIcon,
    link: `${process.env.NEXT_PUBLIC_SPLUS_BASE_URL}/idm/api/identityProxy/logout?fromWhere=PNI&jumpUrl=${signOutParam}`,
  },
  customerService: {
    label: "Call us",
    labelShort: "Call",
    icon: "/services/printing/static-pages/icons/circle-phone.svg",
    link: "1-888-333-3199",
  },
} as const;
